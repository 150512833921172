







import { Component, Prop, Vue } from 'vue-property-decorator';
import InvoiceList from '@c/invoice/invoice-list.vue';
import InvoiceInformation from '@c/invoice/invoice-information.vue';
import { IInvoice } from '@/entity/invoice/invoice';

@Component({
    components: {
        InvoiceList,
        InvoiceInformation
    }
})
export default class InvoiceBase extends Vue {
    @Prop({ required: true }) projectId!: number;
    @Prop({ required: true }) invoiceType!: string;

    selectedInvoiceId: number = 0;

    public setInvoice(item: IInvoice): void {
        if (item) this.selectedInvoiceId = item.id;
        else this.selectedInvoiceId = 0;
    }
}
