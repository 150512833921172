
















































import { Component, Prop, Vue } from 'vue-property-decorator';

import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';

import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { IFee } from '@/entity/fee/fee';
import { IUser } from '@/entity/shared/user';
import { IEmployee } from '@/entity/shared/employee';
import { agencyApi } from '@/wapi/agency-api';
import { IAgency } from '@/entity/project/agency';
import { NU } from '@t/type';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';

@Component({})
export default class FeePdfPage extends Vue {
    @Prop({ required: true }) private fees!: IFee[] | undefined;
    @Prop({ required: true }) private userInfo!: IUser;
    @Prop({ required: true }) private employee!: IEmployee;

    private agencies: NU<IAgency[]> = [];

    private currentDate(): string {
        return format(new Date(), 'd MMMM Y', { locale: frenchLocale });
    }

    get feeDetails(): IFee[] {
        if (this.fees) {
            return this.fees;
        }
        return [];
    }

    get userFullname(): string {
        if (this.userInfo && this.userInfo?.fullName) {
            return (this.userInfo.lastName + ' ' + this.userInfo.firstName) as string;
        } else {
            return '';
        }
    }

    get userAgency(): string {
        let agency: IAgency[];
        if (this.employee && this.employee?.agencyId) {
            agency = this.agencies?.filter(x => x.id === this.employee?.agencyId) as IAgency[];
            return agency[0]?.label as string;
        } else {
            return '';
        }
    }

    private defaultTableNumberFormatterWithoutCurrency(value: number) {
        return new Intl.NumberFormat('fr-FR', { maximumSignificantDigits: 3 }).format(value);
    }

    private defaultTableNumberFormatter(value: number, key: string | undefined, element: IFee) {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
            element.fees * (1 - (element.tax as number) / 100)
        );
    }

    private defaultTableTotalNumberFormatter(value: number, key: string | undefined, element: IFee) {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(element.fees);
    }

    private defaultTableNumberPercentFormatter(value: number) {
        return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(value / 100);
    }

    get fieldHeaders(): IBootstrapTableColumn[] {
        return [
            { key: 'label', label: 'Libellé', tdClass: 'text-center' },
            {
                key: 'creationDate',
                label: 'Date de saisie',
                tdClass: 'text-center',
                formatter: (val: Date, key?: string, item?) => new Date(val).toLocaleDateString('fr-FR')
            },
            { key: 'projectDesignation', label: 'Projet', tdClass: 'text-center' },
            { key: 'contractLineDesignation', label: 'Phase', tdClass: 'text-center' },
            {
                key: 'distance',
                label: 'Distance',
                tdClass: 'text-center',
                formatter: this.defaultTableNumberFormatterWithoutCurrency
            },
            // { key: 'fees', label: 'Frais (HT)', tdClass: 'text-right', formatter: this.defaultTableNumberFormatter },
            {
                key: 'tax',
                label: 'Taxe',
                tdClass: 'text-center',
                formatter: this.defaultTableNumberPercentFormatter
            },
            {
                key: 'total',
                label: 'Total TTC',
                tdClass: 'text-center',
                formatter: this.defaultTableTotalNumberFormatter
            }
        ];
    }

    get feeAmount(): number {
        let totalFee: number = 0;
        if (this.fees) {
            this.fees.forEach(line => {
                if (line.fees) {
                    totalFee += line.fees * (1 - (line.tax as number) / 100);
                }
            });
        }
        return totalFee;
    }

    get feeAmountDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.feeAmount);
    }

    get taxAmount(): number {
        let totalTax: number = 0;
        if (this.fees) {
            this.fees.forEach(line => {
                if (line.tax) {
                    totalTax += line.fees * (line.tax / 100);
                }
            });
        }
        return totalTax;
    }

    get taxAmountDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.taxAmount);
    }

    get totalAmount(): number {
        let total: number = 0;
        if (this.fees) {
            this.fees.forEach(line => {
                if (this.feeAmount && this.taxAmount) {
                    total += line.fees;
                }
            });
        }
        return total;
    }

    get totalAmountDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.totalAmount);
    }

    async mounted(): Promise<void> {
        const agencyCallData = await agencyApi.getAllBase();

        if (isCallValidAndNotCancelled<IAgency[]>(agencyCallData)) {
            this.agencies = agencyCallData?.datas;
        }
    }
}
