









































































import { IManagementPhase, IProjectSave, IProjectSaveContractData } from '@/entity/management/management-phase';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { vxm } from '@/store';
import { managementApi } from '@/wapi/management-api';
import { projectApi } from '@/wapi/project-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { NU } from '@t/type';
import moment from 'moment';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
    }
})
export default class ProjectManagementModal extends Vue {
    @Prop({ required: true }) selectedProjectId: NU<number>;
    @Prop({ required: true }) projectDesignation: string = '';
    private selectedProjectIdLocal: NU<number>;
    private selectedProjectSaveIdLocal: NU<number> = 0;
    private fields: Array<IBootstrapTableColumn> = [
        {
            label: 'Contrat',
            key: 'contrat',           
            tdClass: 'td-class',
            thStyle: { width: '6%' }  
        },
        {
            label: 'Mission',
            key: 'mission',
            tdClass: 'td-class',
            thStyle: { width: '20%' }  
        },
        {
            label: 'Date de Début',
            key: 'startDate',
            formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR') : '-'),
            tdClass: 'td-class',
            thStyle: { width: '9%' }  
        },
        {
            label: 'Date de fin',
            key: 'endDate',
            formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR') : '-'),
            tdClass: 'td-class',
            thStyle: { width: '7%' }  
        },
        {
            label: 'Honoraires',
            key: 'rate',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class',
            thStyle: { width: '7%' }  
        },
        {
            label: 'Honos regroupés',
            key: 'regroupingRate',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class',
            thStyle: { width: '7%' }  
        },
        {
            label: 'Frais',
            key: 'provisionalFees',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class',
            thStyle: { width: '5%' }  
        },
        {
            label: 'STT',
            key: 'subContracts',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class',
            thStyle: { width: '5%' }  
        },
        {
            label: 'Marge',
            key: 'margePercentage',
            thStyle: { width: '4%' },
            tdClass: 'td-class'
        },
        {
            label: 'Budget',
            key: 'budget',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class',
            thStyle: { width: '6%' }  
        },
        {
            label: 'Prévisionnel',
            key: 'costRates',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class',
            thStyle: { width: '8%' }  
        },
        {
            label: 'Consommé',
            key: 'consumedRates',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class',
            thStyle: { width: '7%' }  
        },
        {
            label: 'Avcmt',
            key: 'avcmt',
            thStyle: { width: '4%' },
            tdClass: 'td-class'
        },
        {
            label: 'Situ selon avcmt',
            key: 'progressAmount',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class',
            thStyle: { width: '10%' }
        }
    ];
    
    private modalDatas: IProjectSaveContractData[] = [];        
    private optionsSave: {id: number, label: string }[] = [];
    private showModal: boolean = false;
    managementPhases: NU<IManagementPhase[]> = [];

    @Watch('selectedProjectId')
    async loadData(value: number): Promise<void> {
        this.selectedProjectIdLocal = value;
        await this.loadOptionsSave().then(() => {
            // this.startLoading = false;
            this.selectedProjectSaveIdLocal = null;
        });
    }
    
    async mounted(): Promise<void> {
        this.selectedProjectIdLocal = this.selectedProjectId;
        if (this.selectedProjectId && this.selectedProjectId > 0) {
            await this.getManagementPhases(this.selectedProjectId);
            await this.loadOptionsSave();          
        }
    }

    async getManagementPhases(projectId: number): Promise<void> {
        const phaseCallData = await managementApi.getAllPhasesByProjectId(projectId);

        if (isCallValidAndNotCancelled<IManagementPhase[]>(phaseCallData)) {
            this.managementPhases = phaseCallData?.datas;
        }
    }

    get totalRate(): string {
        let val = 0;
        this.modalDatas.forEach((element) => {
            val += element.rate;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalRegroupingRate(): string {
        let val = 0;
        this.modalDatas.forEach((element) => {
            val += element.regroupingRate;
        });
        val = Math.round(val);
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalProvisionalFees(): string {
        let val = 0;
        this.modalDatas?.forEach((element) => {
            val += element.provisionalFees;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalSubContracts(): string {
        let val = 0;
        this.modalDatas?.forEach((element) => {
            val += element.subContracts;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalMargePercentage(): string {
        let val = 0;
        let totalHonors = 0;
        let totalBudget = 0;
        this.modalDatas?.forEach((element) => {
            totalHonors += element.regroupingRate - element.provisionalFees - element.subContracts;
            totalBudget += element.budget;
        });
        val = 100 - (totalBudget / totalHonors) * 100;
        return `${new Intl.NumberFormat('fr-FR', { style: 'percent', maximumFractionDigits: 0 }).format(
            (isNaN(val) ? 0 : val) / 100
        )}`;
    }

    get totalMargeAmount(): string {
        let totalHonors = 0;
        let totalBudget = 0;
        this.modalDatas?.forEach((element) => {
            totalHonors += element.regroupingRate - element.provisionalFees - element.subContracts;
            totalBudget += element.budget;
        });
        return `${new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0
        }).format(totalHonors - totalBudget)}`;
    }

    get totalBudget(): string {
        let val = 0;
        this.modalDatas?.forEach((element) => {
            val += element.budget;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totaConsumedRates(): string {
        let val = 0;
        this.modalDatas?.forEach((element) => {
            val += element.consumedRates;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalCostRates(): string {
        let val = 0;
        this.modalDatas?.forEach((element) => {
            val += element.costRates;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalProgressAmountsValue(): number {
        let val = 0;
        this.modalDatas?.forEach((element) => {
            val += element.progressAmount;
        });
        return val;
    }

    get totalProgressAmounts(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            this.totalProgressAmountsValue
        );
    }

    private getBudget(management: IManagementPhase): number {
        if (management && management.contractLine) {
            return Number(
                (
                    (management.regroupingTotal - management.provisionalFeesValue - management.subContractsValue) *
                    ((100 - management.contractLine.marginPercentage) / 100)
                ).toFixed(0)
            );
        }
        return 0;
    }

    private progressAmount(management: IManagementPhase): number {
        if (management && management.contractLine) {
            if (management.contractLine.advancement === 0) return 0;
            if (management.contractLine.advancement === 100) {
                return Number((this.getBudget(management) - management.consumedRatesValue).toFixed(0));
            }
            return Number(
                (
                    this.getBudget(management) * (management.contractLine.advancement / 100) -
                    management.consumedRatesValue
                ).toFixed(0)
            );
        }
        return 0;
    }

    private async showScreenshot() {
        if (this.selectedProjectId && this.selectedProjectSaveIdLocal) {
            await projectApi.GetSaveDetail(this.selectedProjectId, this.selectedProjectSaveIdLocal).then(value => {
                this.modalDatas = value.datas as IProjectSaveContractData[];
            });
            this.showModal = true;
        }
    }

    private async takeScreenshot() {
        const data: IProjectSaveContractData[] = [];
        await this.getManagementPhases(this.selectedProjectIdLocal as number);
        if (this.managementPhases) {
            this.managementPhases.forEach((element, index) => {
                var tmp: IProjectSaveContractData = {} as IProjectSaveContractData;
                tmp.contrat = element.contractReference;
                tmp.mission = element.contractLine.designation as string;
                tmp.startDate = element.planificationPhase.openingDate as Date;
                tmp.endDate = element.planificationPhase.closingDate as Date;
                tmp.rate = element.contractLine.total;
                tmp.regroupingRate = element.regroupingTotal;
                tmp.provisionalFees = element.provisionalFeesValue;
                tmp.subContracts = element.subContractsValue;
                tmp.margePercentage = element.contractLine.marginPercentage;
                tmp.budget = this.getBudget(element);
                tmp.costRates = element.planificationPhaseCostValue;
                tmp.consumedRates = element.consumedRatesValue;
                tmp.avcmt = element.contractLine.advancement;
                tmp.progressAmount = this.progressAmount(element);
                data.push(tmp);
            });
        }
        if (vxm.project.dropdownProject) {
            await projectApi.SaveScreenshot(vxm.project.dropdownProject?.id, vxm.project.dropdownProject?.designation
                , vxm.project.dropdownProject?.openingDate as Date
                , vxm.project.dropdownProject?.closingDate as Date, data);
        }

        this.loadOptionsSave();
    }

    private async loadOptionsSave() {
        this.optionsSave = [];
        if (this.selectedProjectIdLocal) {
            await projectApi.GetListSave(this.selectedProjectIdLocal).then(data => {
                if (isCallValidAndNotCancelled<IProjectSave[]>(data)) {
                    data.datas?.forEach((d, index) => {
                        const option = { id: d.id, label: index + 1 + ' - ' + moment(d.creationDate).format('DD/MM/YYYY') };
                        this.optionsSave?.push(option);
                    });
                }
            });   
        }
    }
}
