




















































































import { Component, Vue } from 'vue-property-decorator';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { NU } from '@t/type';
import { vxm } from '@/store';
import { referentialApi } from '@/wapi/referential-api';
import CurrencyInput from '@c/shared/currency-input.vue';
import { IUserListData } from '@/entity/rh/user-list-data';
import { IStudio } from '@/entity/project/studio';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';

@Component({
    components: {
        CurrencyInput
    }
})
export default class ReferentialStudio extends Vue {
    studios: NU<IStudio[]> = [];
    private promiseExport: boolean = false;
    private isBusy: boolean = true;

    get isValidForm(): boolean {
        let res = true;
        this.studios?.forEach((element) => {
            res = res && element.code !== undefined && element.code.length >= 3;
            res = res && element.label !== undefined && element.label !== '';
        });
        return res;
    }

    async getStudios(): Promise<void> {
        const studiosCallData = await referentialApi.getAllStudios();
        if (isCallValidAndNotCancelled<IStudio[]>(studiosCallData)) {
            if (studiosCallData?.datas) {
                this.studios = studiosCallData.datas;
            }
        }
    }

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: 'Code', key: 'code', thStyle: 'width:200px' },
        { label: 'Description', key: 'label' },
        { label: '', key: 'delete', thStyle: 'width:80px' }
    ];

    async saveStudios(): Promise<void> {
        const callData = await referentialApi.patchStudios(this.studios ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.getStudios();
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Liste des studios',
                variant: 'success',
                solid: true
            });
        }
    }

    addStudio(): void {
        const studio = {} as IStudio;
        this.studios?.push(studio);
    }

    deleteStudioLine(item: IStudio): void {
        const index = this.studios?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.studios?.splice(index, 1);
        }
    }

    formatterCode(val: string): string {
        if (!val) {
            return val;
        }
        return val.toUpperCase().trim().substring(0, 4);
    }

    async exportStudios(): Promise<void> {
        await this.generateReport(
            referentialApi.exportStudiosData(),
            `Liste_des_studios_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private onRowClick(item: IUserListData, index: number, event: any): void {
        if (event.srcElement.localName !== 'mgt-person') {
            this.$router.push({ name: 'user-information', params: { id: item.user.id as string } });
        }
    }

    private async mounted() {
        vxm.app.changeTitleMain('Liste des studios');
        this.getStudios();
        this.isBusy = false;
    }
}
