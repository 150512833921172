








import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class ExternalValidation extends Vue {
    msg: string = 'Votre action a été déjà prise en compte !';

    get marginData(): string {
        const mid = window.innerHeight / 2 - 110 / 2;

        return '' + mid + 'px 0';
    }

    mounted(): void {
        const state = this.$route?.query?.status;
        switch (state) {
            case 'approve':
                this.msg = 'Votre validation a été prise en compte !';
                break;
            case 'decline':
                this.msg = 'Votre refus a été pris en compte !';
                break;
            default:
                break;
        }
    }
}
