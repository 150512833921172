
















































































































import { Component, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { appTokenMgr, IEmployeeAppRoleManager } from '@t/employee-app-role';
import { IAgency } from '@/entity/project/agency';
import { IStudio } from '@/entity/project/studio';
import { NU } from '@t/type';
import { agencyApi } from '@/wapi/agency-api';
import { studioApi } from '@/wapi/studio-api';
import { reportingApi } from '@/wapi/reporting-api';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { vxm } from '@/store';
import { IReferential } from '@/entity/shared/referential';

export interface IParametersList {
    projectsIds: NU<number[]>,
    agenciesIds: NU<number[]>
}
@Component({
    components: {}
})

export default class ReportingInformation extends Vue {
    private reportsType = [
        { id: 1, code: 'INV', label: 'Liste des factures', action: this.generateInvoiceListReport },
        { id: 2, code: 'DEV', label: 'Contrôle des écarts', action: this.generateDeviationReport },
        { id: 3, code: 'INS', label: 'Assurances', action: this.generateInsuranceReport },
        { id: 4, code: 'CUT', label: 'Cut off', action: this.generateCutOff },
        {
            id: 5,
            code: 'CHG',
            label: 'Charge valorisée par profil',
            action: this.generateValuedLoadPerProfileReport,
            actionPdf: this.generateValuedLoadPerProfileReportPdf
        },
        {
            id: 6,
            code: 'ADV',
            label: 'Avancement',
            action: this.generateAdvancement,
            actionPdf: this.generateAdvancementPdf
        },
        {
            id: 7,
            code: 'DSA',
            label: 'CA par société et par agence',
            action: this.generateDataSurferAccounting,
            actionPdf: this.generateDataSurferAccountingPdf
        },
        {
            id: 8,
            code: 'DAI',
            label: 'CA par studio',
            action: this.generateDataSurferAccountingForInvoices,
            actionPdf: this.generateDataSurferAccountingForInvoicesPdf
        },
        {
            id: 9,
            code: 'DSM',
            label: 'Mouvements',
            action: this.generateDataSurferMouvements,
            actionPdf: this.generateDataSurferMouvementsPdf
        },
        {
            id: 10,
            code: 'DSC',
            label: 'Carnet de Commandes',
            action: this.generateDataSurferCommandes,
            actionPdf: this.generateDataSurferCommandesPdf
        },
        {
            id: 11,
            code: 'DCC',
            label: 'Carnet de Commandes Détail',
            action: this.generateDataSurferCommandesDetails,
            actionPdf: this.generateDataSurferCommandesDetailsPdf
        },
        { id: 12, code: 'RVL', label: 'Congés validés', action: this.generateValidatedLeave }
    ];

    private selectedReport: NU<{
        id: number;
        code: string;
        label: string;
        action: () => Promise<void>;
    }> = {} as any;

    private agencies: NU<IAgency[]> = [];
    private studios: NU<IStudio[]> = [];
    private agency: NU<IAgency> = {} as IAgency;
    private studio: NU<IStudio> = {} as IStudio;
    private openingDate: Date = new Date();
    private closingDate: Date = new Date();
    private reportsTypePlaceholder: string = 'Type de rapport';
    private agencyPlaceholder: string = 'Société';
    private agencyPlaceholderCA: string = 'Société %';
    private trigramPlaceholder: string = 'Trigram %';
    private studioPlaceholder: string = 'Studio %';
    private promiseSave: boolean = false;
    private isPdf: boolean = false;
    private projectsIds: NU<number[]> = [];
    private agenciesIds: NU<number[]> = [];
    private studioIds: NU<number[]> = [];
    
    get reportlabelOrdered() {
        return this.reportsType.sort((x, y) => (x.label > y.label ? 1 : -1));
    }

    get agencyOptions(): IAgency[] {
        const res: IAgency[] = Array.from(this.agencies ?? []);
        this.agency = null;
        if (this.selectedReport?.code === 'DSA' || this.selectedReport?.code === 'DAI') {
            res.unshift({ id: 0, label: 'Tous' } as IAgency);
        }
        return res;
    }

    get agencyOptionsCA(): IAgency[] {
        const res: IAgency[] = Array.from(this.agencies ?? []);
        this.agency = null;
        return res;
    }

    get studioOptions(): IStudio[] {
        return this.studios?.map(x => {
            return {
                id: x.id,
                code: x.code,
                label: x.code
            };
        }) ?? [];
    }

    get displayAgencySelect(): boolean {
        return (
            this.selectedReport?.code !== 'CHG' &&
            this.selectedReport?.code !== 'ADV' &&
            this.selectedReport?.code !== 'DSC' &&
            this.selectedReport?.code !== 'DCC' &&
            this.selectedReport?.code !== 'RVL'
        );
    }

    get ProjectOptions(): NU<IReferential[]> {
        return vxm.project.dropdownProjectListOptions.map(x => {
            return {
                id: x.id,
                code: x.trigram,
                label: x.trigram
            };
        });
    }

    get formIsValid(): boolean {
        if (this.selectedReport?.code === undefined) {
            return false;
        }
        return (
            this.openingDate != null &&
            this.closingDate != null &&
            this.openingDate < this.closingDate &&
            this.selectedReport !== null &&
            (this.selectedReport?.code === 'CHG' ||
                this.selectedReport?.code === 'ADV' ||
                this.selectedReport?.code === 'DSC' ||
                this.selectedReport?.code === 'DCC' ||
                this.selectedReport?.code === 'RVL' ||
                this.agency != null || (this.agenciesIds != null && this.agenciesIds.length > 0))
        );
    }

    get displayPickerDate(): boolean {
        return this.selectedReport?.code !== '';
    }

    get isDataSurfer(): boolean {
        let res = false;
        if (this.selectedReport) {
            const select = this.selectedReport.code;
            res =
                select === 'ADV' ||
                select === 'DSA' ||
                select === 'DAI' ||
                select === 'DSM' ||
                select === 'DSC' ||
                select === 'DCC' ||
                select === 'CHG';
        }
        if (!res) {
            this.isPdf = false;
        }
        return res;
    }

    get appTokenMgr(): IEmployeeAppRoleManager {
        return appTokenMgr;
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Rapports');
        vxm.app.changeTitleExt('Financiers');

        const agencyCallData = await agencyApi.getAllBase();
        if (isCallValidAndNotCancelled<IAgency[]>(agencyCallData)) {
            this.agencies = agencyCallData?.datas;
        }
        const studioCallData = await studioApi.getAllBase();
        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.studios = studioCallData?.datas;
        }
        this.openingDate.setMonth(this.openingDate.getMonth() - 3);
        this.selectedReport = null;
        this.agency = null;
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseSave = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseSave = false;
        }
        // We wait for the file to download before removing loading state
        /* setTimeout(() => {
            this.promiseSave = false;
        }, 1000); */
    }

    async generateValuedLoadPerProfileReport(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        const studioIds = this.studioIds;
        return this.generateReport(
            reportingApi.downloadValuedLoadPerProfileReport(startDate, endDate, studioIds ?? []),
            `Charge_Valorisee_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateValuedLoadPerProfileReportPdf(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        const studioIds = this.studioIds;
        return this.generateReport(
            reportingApi.downloadValuedLoadPerProfileReportPdf(startDate, endDate, studioIds ?? []),
            `Charge_Valorisee_${this.formatDate(new Date())}.pdf`
        );
    }

    async generateInvoiceListReport(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const agency = this.agency;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadReport(startDate, endDate, String(agency!.id)),
            `Rapport_Factures_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateDataSurferAccounting(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        const parametersLists: IParametersList = {} as IParametersList; 
        parametersLists.projectsIds = this.projectsIds;
        if (this.selectedReport?.id === 7) { 
            parametersLists.agenciesIds = this.agenciesIds;
        } else { 
            parametersLists.agenciesIds?.push(this.agency?.id ?? 0);
        }
        await this.generateReport(
            reportingApi.downloadDataSurferAccounting(startDate, endDate, parametersLists),
            `Data-Surfer-Comptabilité-CA_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateDataSurferAccountingPdf(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        const parametersLists: IParametersList = {} as IParametersList; 
        parametersLists.projectsIds = this.projectsIds;
        if (this.selectedReport?.id === 7) { 
            parametersLists.agenciesIds = this.agenciesIds;
        } else { 
            parametersLists.agenciesIds?.push(this.agency?.id ?? 0);
        }

        await this.generateReport(
            reportingApi.downloadDataSurferAccountingPdf(startDate, endDate, parametersLists),
            `Data-Surfer-Comptabilité-CA_${this.formatDate(new Date())}.pdf`
        );
    }

    async generateDataSurferAccountingForInvoices(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const agency = this.agency;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDataSurferAccountingForInvoices(startDate, endDate, String(agency!.id)),
            `Data-Surfer-Comptabilité-Factures_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateDataSurferAccountingForInvoicesPdf(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const agency = this.agency;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDataSurferAccountingForInvoicesPdf(startDate, endDate, String(agency!.id)),
            `Data-Surfer-Comptabilité-Factures_${this.formatDate(new Date())}.pdf`
        );
    }

    async generateAdvancement(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDataSurferAdvancement(startDate, endDate),
            `Data-Surfer-Avancement_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateAdvancementPdf(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDataSurferAdvancementPdf(startDate, endDate),
            `Data-Surfer-Avancement_${this.formatDate(new Date())}.pdf`
        );
    }

    async generateDataSurferCommandes(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDSPurchaseOrder(startDate, endDate),
            `DS_Carnet_Commandes_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateDataSurferCommandesDetails(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDSPurchaseOrderDetails(startDate, endDate),
            `DS_Carnet_Commandes_Détails_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateDataSurferCommandesPdf(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDSPurchaseOrderPdf(startDate, endDate),
            `DS_Carnet_Commandes_${this.formatDate(new Date())}.pdf`
        );
    }

    async generateDataSurferCommandesDetailsPdf(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDSPurchaseOrderDetailsPdf(startDate, endDate),
            `DS_Carnet_Commandes_Détails_${this.formatDate(new Date())}.pdf`
        );
    }

    async generateDataSurferMouvements(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const agency = this.agency;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDSMouvementsReport(startDate, endDate, String(agency!.id)),
            `DS_Mouvements_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateDataSurferMouvementsPdf(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const agency = this.agency;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDSMouvementsReportPdf(startDate, endDate, String(agency!.id)),
            `DS_Mouvements_${this.formatDate(new Date())}.pdf`
        );
    }

    async generateCutOff(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const agency = this.agency;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadCutoff(startDate, endDate, String(agency!.id)),
            `CUT-OFF_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateDeviationReport(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const agency = this.agency;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadDeviationReport(startDate, endDate, String(agency!.id)),
            `Rapport_Ecarts_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateInsuranceReport(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const agency = this.agency;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadInsuranceReport(startDate, endDate, String(agency!.id)),
            `Rapport_Assurances_${this.formatDate(new Date())}.xlsx`
        );
    }

    async generateValidatedLeave(): Promise<void> {
        if (!this.formIsValid) {
            return;
        }
        const openingDate = this.openingDate;
        const closingDate = this.closingDate;
        const startDate = this.formatDate(openingDate);
        const endDate = this.formatDate(closingDate);
        await this.generateReport(
            reportingApi.downloadValidatedLeavesReport(startDate, endDate),
            `Rapport_Congé_${this.formatDate(new Date())}.xlsx`
        );
    }
}
