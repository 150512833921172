import { IContractLine } from '@/entity/contract/contract-line';
import { IProject } from '@/entity/project/project';
import { ICrossProjectImputationRecap, IImputationRecap, IImputationRecapFilter, IImputationsDetaillees } from '@/entity/rh/imputation-recap';
import { IImputationWorkflow } from '@/entity/rh/workflow';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import { IFilterCache } from '@t/filter-cache-service';
import { NU } from '@t/type';
import ApiBase from './api-base';

class ImputationApi extends ApiBase<IImputationWorkflow> {
    constructor(urlBase: string = 'api/imputations') {
        super(urlBase);
    }

    public async getImputationsByEmployeeIdentifierAndPeriod(
        employeeIdentifier: string,
        year: number,
        month: number
    ): Promise<ICancellableResult<IImputationWorkflow>> {
        return await ajaxCall.baseAjaxCall<IImputationWorkflow>('get', this.url, {
            urlParameter: [employeeIdentifier + ''],
            queryParameter: {
                year: year + '',
                month: month + ''
            }
        });
    }

    public async insertImputations(workflow: IImputationWorkflow): Promise<ICancellableResult<number>> {
        return await ajaxCall.post<number, IImputationWorkflow>(this.url, workflow);
    }    

    public async getImputationsDetaillees(data: IImputationRecapFilter): Promise<ICancellableResult<IImputationsDetaillees[]>> {
        return await ajaxCall.baseAjaxCall<IImputationsDetaillees[], IImputationRecapFilter>('post', this.url, {
            urlParameter: ['getImputationsDetaillees'],
            payload: data,
        });
    }

    public async getImputationRecapList(data: IImputationRecapFilter): Promise<ICancellableResult<IImputationRecap[]>> {
        return await ajaxCall.baseAjaxCall<IImputationRecap[], IImputationRecapFilter>('post', this.url, {
            urlParameter: ['fetchImputationRecap'],
            payload: data,
        });
    }
    
    public async exportImpRestData(data: string): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, string>('post', this.url, {
            payload: data,
            urlParameter: ['exportImpRestData'],
            responseType: 'blob'
        });
    }

    public async restitutionImputation(projectId: number, newContractLineId: number, oldContractLinePhase: string, triggerId: string): Promise<ICancellableResult<boolean>> {
        const result = await ajaxCall.baseAjaxCall<boolean>('post', this.url, {
            urlParameter: ['restitutionImputation'],
            queryParameter: {
                projectId: `${projectId}`,
                newContractLineId: `${newContractLineId}`,
                oldContractLinePhase: `${oldContractLinePhase}`,
                triggerId: `${triggerId}`
            }
        });
        return result;
    }

    public async getCrossProjectRecapBetweenDates(beginDate: Date, endDate: Date, perMonth: boolean = true): Promise<ICancellableResult<ICrossProjectImputationRecap[]>> {
        const result = await ajaxCall.baseAjaxCall<ICrossProjectImputationRecap[]>('get', this.url, {
            urlParameter: ['crossProject'],
            queryParameter: {
                beginYear: `${beginDate.getFullYear()}`,
                beginMonth: `${beginDate.getMonth() + 1}`,
                beginDay: `${beginDate.getDate()}`,
                endYear: `${endDate.getFullYear()}`,
                endMonth: `${endDate.getMonth() + 1}`,
                endDay: `${endDate.getDate()}`,
                perMonth: `${perMonth}`
            }
        });
        result?.datas?.map(x => {
            x.recaps.map(y => {
                y.fromDate = new Date(y.fromDate);
                y.toDate = new Date(y.toDate);
            });
        });
        return result;
    }

    public async exportCrossProjectRecapBetweenDates(projectId: number, beginDate: Date, endDate: Date, perMonth: boolean = true, perEmployee: boolean, filter: NU<IFilterCache>): Promise<ICancellableResult<string>> {
        const result = await ajaxCall.baseAjaxCall<string, IFilterCache>('post', this.url, {
            urlParameter: [`${projectId}`, 'crossProject', 'excel'],
            queryParameter: {
                beginYear: `${beginDate.getFullYear()}`,
                beginMonth: `${beginDate.getMonth() + 1}`,
                beginDay: `${beginDate.getDate()}`,
                endYear: `${endDate.getFullYear()}`,
                endMonth: `${endDate.getMonth() + 1}`,
                endDay: `${endDate.getDate()}`,
                perEmployee: `${perEmployee}`,
                perMonth: `${perMonth}`
            },
            payload: filter,
            responseType: 'blob'
        });
        return result;
    }
}

export const imputationApi: ImputationApi = new ImputationApi();

class ContractLineApi extends ApiBase<IContractLine> {
    constructor(urlBase: string = 'api/imputations') {
        super(urlBase);
    }

    public async getContractLineByMonth(
        employeeIdentifier: string,
        year: number,
        month: number
    ): Promise<ICancellableResult<IProject[]>> {
        return await ajaxCall.baseAjaxCall<IProject[]>('get', `${this.url}/contractLine`, {
            urlParameter: [employeeIdentifier + ''],
            queryParameter: {
                year: year + '',
                month: month + ''
            }
        });
    }
}

export const contractLineApi: ContractLineApi = new ContractLineApi();
