


























































































































































































































































































import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IClient } from '@/entity/shared/client';
import { IClientAddress } from '@/entity/shared/client-address';
import { IContact } from '@/entity/shared/contact';
import { clientApi } from '@/wapi/client-api';
import { vxm } from '@/store';
import { IReferential } from '@/entity/shared/referential';
import { NU } from '@t/type';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { groupNamingConventionManager } from '@t/group-naming-convention-manager';
import { IGraphRequestParameter } from '@/entity/shared/graph-request-parameter';
import { moduleApiGraph } from '@t/module-api-graph';
import { deepCopy } from '@t/object';

interface IVM<T> {
    uniq: number;
    element: T;
}

@Component({
    components: {
        InputElement
    }
})
export default class ClientInformation extends Vue {
    @Prop({ required: true }) id!: string;
    private code: string = 'CLT';

    @Validations()
    validations = {
        client: {
            label: { required }
        }
    };

    private newContact: { mail: string; firstName?: string; lastName?: string } = { mail: '' };
    private newAddress: IClientAddress = {};
    private autoIncrementUniqAddress: number = 1;

    private client: NU<IClient> = {} as IClient;
    private contacts: NU<IVM<IContact>[]> = [];
    private removedContacts: IContact[] = [];
    private autoIncrementUniqContact: number = 1;

    get addresses(): NU<IVM<IClientAddress>[]> {
        if (this.client && this.client.clientAddresses) {
            return this.client.clientAddresses.map(x => {
                return { uniq: this.autoIncrementUniqContact++, element: x } as IVM<IClientAddress>;
            });
        }
        return [];
    }

    private groupId: string = '';
    private d: any = {};

    get thirdPartyType(): NU<IReferential[]> {
        return vxm.referential.thirdPartyType;
    }

    get thirdPartyAddressType(): NU<IReferential[]> {
        return vxm.referential.thirdPartyAddressType;
    }

    private getLabelofThirdPartyAddressType(id: number): string {
        if (!this.thirdPartyAddressType || this.thirdPartyAddressType.length === 0 || id === null) {
            return '';
        } else {
            return this.thirdPartyAddressType.filter(x => x.id === id)[0].label;
        }
    }

    private addClientAddressLine(): void {
        this.newAddress = {
            id: undefined,
            tempId: this.autoIncrementUniqAddress++,
            code: '',
            thirdPartyId: Number(this.id),
            thirdPartyAddressTypeId: null,
            label: '',
            postalCode: '',
            city: '',
            country: ''
        };
        (this.$refs['add-client-address-modal'] as any).show();
    }

    private addAddress(): void {
        if (this.newAddress.id === undefined) {
            this.newAddress.id = 0;
        }
        if (this.client && this.client.clientAddresses) {
            const index = this.client.clientAddresses.findIndex(
                x =>
                    ((x.id as number) > 0 && x.id === this.newAddress.id) ||
                    (x.id === 0 && x.tempId === this.newAddress.tempId)
            );
            if (index > -1) {
                this.client.clientAddresses.splice(index, 1, this.newAddress);
            } else {
                this.client.clientAddresses.push(this.newAddress);
            }
        }
        (this.$refs['add-client-address-modal'] as any).hide();
    }

    private deleteClientAddressLine(item: IClientAddress): void {
        const index = this.client?.clientAddresses?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.client?.clientAddresses?.splice(index, 1);
        }
    }

    private editClientAddressLine(item: IClientAddress): void {
        if (this.client && this.client.clientAddresses) {
            this.newAddress = deepCopy<IClientAddress>(item);
        }
        (this.$refs['add-client-address-modal'] as any).show();
    }

    private addContactLine(): void {
        this.newContact = { mail: '', firstName: '', lastName: '' };
        (this.$refs['add-client-contact-modal'] as any).show();
    }

    private async addContact(): Promise<void> {
        if (this.contacts) {
            this.contacts.push({
                uniq: this.autoIncrementUniqContact++,
                element: {
                    id: null,
                    mail: this.newContact.mail,
                    displayName: this.newContact.firstName + ' ' + this.newContact.lastName
                }
            });
            this.$nextTick(() => {
                this.$nextTick(async () => {
                    const mgt = (this.$refs['nContact-' + this.newContact.mail] as any)[0];

                    const usr = await moduleApiGraph.Client.api(
                        `users/?$filter=mail+eq+'${this.newContact.mail}'`
                    ).get();
                    if (usr == null || usr.value == null || usr.value.length === 0) {
                        mgt.personDetails = {
                            mail: this.newContact.mail
                        };
                        if (this.newContact.firstName && this.newContact.lastName) {
                            mgt.personDetails = {
                                ...mgt.personDetails,
                                displayName: this.newContact.firstName + ' ' + this.newContact.lastName
                            };
                        }
                    } else {
                        mgt.personDetails = usr.value[0];
                    }
                });
            });
        }
        (this.$refs['add-client-contact-modal'] as any).hide();
    }

    private deleteContactLine(item: IVM<IContact>): void {
        const index = this.contacts?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.removedContacts.push(...(this.contacts as IVM<IContact>[]).splice(index, 1).map(x => x.element));
        }
    }

    async updateClient(): Promise<void> {
        if (this.client) {
            const callData = await clientApi.patchBase(this.client.id + '', this.client);
            if (isCallValidAndNotCancelled(callData)) {
                if (this.contacts) {
                    await this.createContact(
                        this.contacts?.filter(x => !x.element.id).map(x => x.element),
                        this.client.id as number
                    );
                }

                if (this.removedContacts && this.removedContacts.length > 0) {
                    await clientApi.removeContactInADGroup(this.removedContacts, this.client.id as number);
                    this.removedContacts.splice(0, this.removedContacts.length);
                }
                this.$bvToast.toast('Mise à jour du client effectué avec succès', {
                    title: `Client: ${this.client.label}`,
                    variant: 'success',
                    solid: true
                });
            }
        }
    }

    async createClient(): Promise<void> {
        if (this.client) {
            const callData = await clientApi.createClient(this.client);
            if (isCallValidAndNotCancelled(callData) && callData.datas) {
                await this.createContact(
                    this.contacts?.map(x => x.element),
                    Number((callData.datas as any).thirdPartyId)
                );
                this.groupId = (callData.datas as any).groupId;
                await this.$router.push({
                    name: 'client-information',
                    params: { id: (callData.datas as any).thirdPartyId }
                });
            }
        }
        this.client = {} as IClient;
        const clientCallData = await clientApi.getByIdAndCode(this.id, this.code);
        if (isCallValidAndNotCancelled<IClient>(clientCallData)) {
            this.client = clientCallData?.datas;
            if (this.client) {
                vxm.app.changeTitleExt('Informations de ' + this.client.label);
                this.groupId = await this.getGroupIdByName(this.client.label as string);
                if (this.groupId) {
                    const urlGraph = '/groups/' + this.groupId + '/members?$select=id,displayName,mail';
                    const graphData = await moduleApiGraph.Client.api(urlGraph).get();
                    this.contacts = graphData.value.map(x => {
                        return {
                            uniq: this.autoIncrementUniqContact++,
                            element: {
                                id: x.id,
                                displayName: x.displayName,
                                mail: x.mail
                            }
                        } as IVM<IContact>;
                    });
                }
            }
            this.$bvToast.toast('Création du client effectué avec succès', {
                title: `Client: ${this.client?.label}`,
                variant: 'success',
                solid: true
            });
        }
    }

    async createContact(contact: NU<IContact[]>, clientId: number): Promise<void> {
        if (contact) {
            const callData = await clientApi.createContactInADGroup(contact, clientId);
            if (isCallValidAndNotCancelled(callData) && callData.datas) {
            }
        }
    }

    async save(): Promise<void> {
        if (this.client?.id === 0) {
            await this.createClient();
        } else {
            await this.updateClient();
        }
    }

    async fetchReferencial(): Promise<void> {
        await vxm.referential.fetchThirdPartyType();
        await vxm.referential.fetchThirdPartyAddressType();
    }

    private newClient(): void {
        const clientAddresses: IClientAddress[] = [] as IClientAddress[];
        this.client = {
            id: 0,
            code: this.code,
            label: '',
            thirdPartyTypeId: this.thirdPartyType?.filter(x => x.code === 'CLT')[0].id,
            parentId: null,
            directLine: '',
            webSite: '',
            mail: '',
            comment: '',
            clientAddresses: clientAddresses
        } as IClient;
        this.contacts = [] as IVM<IContact>[];
    }

    private getGraphField(p: keyof IGraphRequestParameter): string {
        return p;
    }

    async getGroupIdByName(groupName: string): Promise<string> {
        const gn = groupNamingConventionManager.encodeGroupName(groupName);
        const getResult = await moduleApiGraph.Client.api('/groups')
            .filter(`${this.getGraphField('displayName')}+eq+'${gn}'`)
            .get();
        if (getResult.value && getResult.value[0]) {
            return getResult.value[0].id;
        } else {
            return '';
        }
    }

    async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Clients');
        await this.fetchReferencial();
        if (this.id === '0') {
            vxm.app.changeTitleExt("Création d'un nouveau client");
            this.newClient();
        } else {
            if (this.code === undefined || this.code === null) {
                this.code = 'CLT';
            }
            const clientCallData = await clientApi.getByIdAndCode(this.id, this.code);
            if (isCallValidAndNotCancelled<IClient>(clientCallData)) {
                this.client = clientCallData?.datas;
                if (this.client) {
                    vxm.app.changeTitleExt('Informations de ' + this.client.label);
                    this.groupId = await this.getGroupIdByName(this.client.label as string);
                    if (this.groupId) {
                        const urlGraph = '/groups/' + this.groupId + '/members?$select=id,displayName,mail';
                        const graphData = await moduleApiGraph.Client.api(urlGraph).get();
                        this.contacts = graphData.value.map(x => {
                            return {
                                uniq: this.autoIncrementUniqContact++,
                                element: {
                                    id: x.id,
                                    displayName: x.displayName,
                                    mail: x.mail
                                }
                            } as IVM<IContact>;
                        });
                    }
                }
            }
        }
    }
}
