














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ImputationGridHeader extends Vue {
    @Prop({ required: true })
    private cellsToDisplay!: string[];

    @Prop({ required: true })
    private numberToDisplay!: number[];

    getClass(day: string, val: number, index: number): string {
        return val === -1 ? 'number color-dark-grey week-style' : day === 'dim.' || day === 'sam.' ? 'number color-grey' : 'number color-light-grey';
    }
}
