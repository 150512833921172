import ClientBase from '@/components/client/client-base.vue';
import ClientInformation from '@/components/client/client-information.vue';
import ClientList from '@/components/client/client-list.vue';
import FeePdf from '@/components/fee-pdf/fee-pdf.vue';
import EffectiveFeeInformation from '@/components/fee/effective-fee/effective-fee-information.vue';
import Home from '@/components/home/home.vue';
import InvoicePdf from '@/components/invoice-pdf/invoice-pdf.vue';
import Login from '@/components/login/login.vue';
import ExternalValidation from '@/components/external-validation/external-validation.vue';
import NoAccess from '@/components/login/noaccess.vue';
import ProjectManagements from '@/components/project-managements/project-managements.vue';
import ProjectPlanification from '@/components/project-planification/project-planification.vue';
import ProjectBase from '@/components/project/project-base.vue';
import ProjectInformation from '@/components/project/project-information.vue';
import ProjectList from '@/components/project/project-list.vue';
import ProvisionalFeeBase from '@/components/provisional-fee/provisional-fee-base.vue';
import ProvisionalFeeList from '@/components/provisional-fee/provisional-fee-list.vue';
import ReferentialBank from '@/components/referential/referential-bank.vue';
import ReferentialAgency from '@/components/referential/referential-agency.vue';
import ReferentialStudio from '@/components/referential/referential-studio.vue';
import ReferentialBase from '@/components/referential/referential-base.vue';
import ReferentialEmployeeRole from '@/components/referential/referential-employee-role.vue';
import ImputationBase from '@/components/rh/imputation/imputation-base.vue';
import UserImputation from '@/components/rh/imputation/user-imputation.vue';
import LeaveBase from '@/components/rh/leave/leave-base.vue';
import LeaveInformation from '@/components/rh/leave/leave-information.vue';
import OverTimeBase from '@/components/rh/over-time/over-time-base.vue';
import OverTimeInformation from '@/components/rh/over-time/over-time-information.vue';
import RealizedOverTimeBase from '@/components/rh/realized-over-time/realized-over-time-base.vue';
import RealizedOverTimeInformation from '@/components/rh/realized-over-time/realized-over-time-information.vue';
import RHBase from '@/components/rh/rh-base.vue';
import UserInformation from '@/components/rh/users/user-information.vue';
import UserList from '@/components/rh/users/user-list.vue';
import SubcontractingBase from '@/components/subcontracting/subcontracting-base.vue';
import SubcontractingList from '@/components/subcontracting/subcontracting-list.vue';
import SupplierBase from '@/components/supplier/supplier-base.vue';
import SupplierInformation from '@/components/supplier/supplier-information.vue';
import SupplierList from '@/components/supplier/supplier-list.vue';
import ReportingBase from '@/components/reporting/reporting-base.vue';
import ReportingInformation from '@/components/reporting/reporting-information.vue';
import { authModule } from '@t/session';
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router/types/router';
import { AccountInfo } from '@azure/msal-browser';
import { AccountTokenClaims } from '@/entity/shared/user';
import { appTokenMgr } from '@t/employee-app-role';
import ImputationRecapList from '@/components/rh/imputation-recap/imputation-recap-list.vue';

export type IRoute = RouteConfig;

export const routeDefinition: IRoute[] = [
    {
        path: '/',
        name: 'root',
        redirect: {
            name: 'home'
        }
    },
    {
        path: '/home',
        name: 'home',
        component: Home
    },
    {
        path: '/users',
        component: RHBase,
        children: [
            {
                path: '',
                name: 'users',
                component: UserList,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    const acc = authModule.getAccount() as AccountInfo;
                    if (appTokenMgr.isAdmin(acc) || appTokenMgr.isAssociate(acc)) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: 'imputations-recap',
                name: 'imputationsRecap',
                component: ImputationRecapList,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    const acc = authModule.getAccount() as AccountInfo;
                    if (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc)) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: ':id',
                props: true,
                name: 'user-information',
                component: UserInformation,
                beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    const acc = authModule.getAccount() as AccountInfo;
                    if (
                        appTokenMgr.isAdmin(acc) ||
                        appTokenMgr.isAssociate(acc) ||
                        to.params.id === ((acc.idTokenClaims as AccountTokenClaims).oid as string)
                    ) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: ':id/leaves',
                component: LeaveBase,
                props: true,
                children: [
                    {
                        path: ':leaveId',
                        name: 'leave-information',
                        component: LeaveInformation,
                        props: true,
                        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                            const acc = authModule.getAccount() as AccountInfo;
                            if (
                                appTokenMgr.isAdmin(acc) ||
                                to.params.id === ((acc.idTokenClaims as AccountTokenClaims).oid as string)
                            ) {
                                next();
                            } else {
                                next(false);
                            }
                        }
                    }
                ]
            },
            {
                path: ':id/leaves/:leaveId/validation',
                component: LeaveInformation,
                name: 'leave-information-validation',
                props: true
            },
            {
                path: ':id/overtimes',
                component: OverTimeBase,
                props: true,
                children: [
                    {
                        path: ':overTimeId',
                        name: 'over-time-information',
                        component: OverTimeInformation,
                        props: true
                    }
                ]
            },
            {
                path: ':id/overtimes/:overTimeId/validation',
                component: OverTimeInformation,
                name: 'over-time-information-validation',
                props: true
            },
            {
                path: ':id/realizedovertimes',
                component: RealizedOverTimeBase,
                props: true,
                children: [
                    {
                        path: ':realizedOverTimeId',
                        name: 'realized-over-time-information',
                        component: RealizedOverTimeInformation,
                        props: true
                    }
                ]
            },
            {
                path: ':id/realizedovertimes/:realizedOverTimeId/validation',
                component: RealizedOverTimeInformation,
                name: 'realized-over-time-information-validation',
                props: true
            },
            {
                path: ':id/imputations',
                component: ImputationBase,
                children: [
                    {
                        path: '',
                        name: 'imputations',
                        component: UserImputation,
                        props: true,
                        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                            const acc = authModule.getAccount() as AccountInfo;
                            if (
                                appTokenMgr.isAdmin(acc) ||
                                to.params.id === ((acc.idTokenClaims as AccountTokenClaims).oid as string)
                            ) {
                                next();
                            } else {
                                next(false);
                            }
                        }
                    }
                ]
            },
            {
                path: ':id/fees',
                name: 'effective-fee-information',
                component: EffectiveFeeInformation,
                props: true,
                beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    const acc = authModule.getAccount() as AccountInfo;
                    if (
                        appTokenMgr.isAdmin(acc) ||
                        to.params.id === ((acc.idTokenClaims as AccountTokenClaims).oid as string)
                    ) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: ':id/fees/pdf',
                name: 'fee-pdf',
                component: FeePdf,
                props: true
            }
        ]
    },
    {
        path: '/projects',
        component: ProjectBase,
        children: [
            {
                path: '',
                name: 'projects',
                component: ProjectList
            },
            {
                path: ':id',
                name: 'project-information',
                component: ProjectInformation,
                props: true,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: ':id/invoices/:invoiceId',
                name: 'invoice-pdf',
                component: InvoicePdf,
                props: true
            }
        ]
    },
    {
        path: '/clients',
        component: ClientBase,
        children: [
            {
                path: '',
                name: 'clients',
                component: ClientList,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: ':id',
                name: 'client-information',
                component: ClientInformation,
                props: true,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            }
        ]
    },
    {
        path: '/suppliers',
        component: SupplierBase,
        children: [
            {
                path: '',
                name: 'suppliers',
                component: SupplierList,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: ':id',
                name: 'supplier-information',
                component: SupplierInformation,
                props: true,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            }
        ]
    },
    {
        path: '/planification',
        name: 'projectPlanification',
        component: ProjectPlanification,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
            const acc = authModule.getAccount() as AccountInfo;
            if (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc)) {
                next();
            } else {
                next(false);
            }
        }
    },
    {
        path: '/subcontracting',
        component: SubcontractingBase,
        children: [
            {
                path: '',
                name: 'subcontracting',
                component: SubcontractingList,
                beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    const acc = authModule.getAccount() as AccountInfo;
                    if (
                        appTokenMgr.isAdmin(acc) ||
                        appTokenMgr.isDaf(acc) ||
                        appTokenMgr.isStudioManager(acc) ||
                        appTokenMgr.isCP(acc)
                    ) {
                        next();
                    } else {
                        next(false);
                    }
                }
            }
        ]
    },
    {
        path: '/provisionalfee',
        component: ProvisionalFeeBase,
        children: [
            {
                path: '',
                name: 'provisionalfee',
                component: ProvisionalFeeList
            }
        ]
    },
    {
        path: '/managements',
        name: 'projectManagements',
        component: ProjectManagements,
        beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
            const acc = authModule.getAccount() as AccountInfo;
            if (appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc)) {
                next();
            } else {
                next(false);
            }
        }
    },
    {
        path: '/referential',
        component: ReferentialBase,
        children: [
            {
                path: 'banks',
                name: 'banks',
                component: ReferentialBank,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: 'employeeroles',
                name: 'employeeRoles',
                component: ReferentialEmployeeRole,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: 'agencies',
                name: 'agencies',
                component: ReferentialAgency,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            },
            {
                path: 'studios',
                name: 'studios',
                component: ReferentialStudio,
                beforeEnter: async (_to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
                    if (appTokenMgr.isAdmin()) {
                        next();
                    } else {
                        next(false);
                    }
                }
            }
        ]
    },
    {
        path: '/reporting',
        name: 'reporting',
        component: ReportingBase,
        props: true,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
            const acc = authModule.getAccount() as AccountInfo;
            if (
                appTokenMgr.isAdmin(acc) ||
                to.params.id === ((acc.idTokenClaims as AccountTokenClaims).oid as string)
            ) {
                next();
            } else {
                next(false);
            }
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
    {
        path: '/noaccess',
        name: 'not-a-user',
        component: NoAccess
    },
    {
        path: '/externalValidation',
        name: 'externalValidation',
        component: ExternalValidation
    },
    {
        path: '*',
        redirect: '/'
    }
    // {
    //     path: '/unauthorized',
    //     name: 'unauthorized',
    //     component: UnauthorizedPage,
    // }
];
