




































































































































import { IPurchaseOrder } from '@/entity/purchase-order/purchase-order';
import { ICreatePurchaseOrder } from '@/entity/purchase-order/create-purchase-order';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { purchaseOrderApi } from '@/wapi/purchase-order-api';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { format } from 'date-fns';
import { BTable } from 'bootstrap-vue';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { Validations } from 'vuelidate-property-decorators';
import { required } from 'vuelidate/lib/validators';
import { appTokenMgr } from '@t/employee-app-role';
import frenchLocale from 'date-fns/locale/fr';
import { ICancellableResult } from '@t/ajax-wrapper';

@Component({
    components: {
        InputElement
    }
})
export default class PurchaseOrderList extends Vue {
    @Prop({ required: true }) projectId!: string;

    private createPurchaseOrderObject: ICreatePurchaseOrder = {} as ICreatePurchaseOrder;
    private filteredPurchaseOrders: NU<IPurchaseOrder[]> = [];
    private filterData: string = '';
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private purchaseOrdersCurrentPage: number = 1;
    private purchaseOrdersPerPage: number = 10;
    private promiseExport: boolean = false;

    @Validations()
    validations = {
        createPurchaseOrderObject: {
            description: { required }
        }
    };

    get isReadOnly(): boolean {
        return !appTokenMgr.isAdmin() || (vxm.project.projectData?.isSale ?? false);
    }

    get purchaseOrders(): NU<IPurchaseOrder[]> {
        const list = vxm.project.purchaseOrderList;
        this.filteredPurchaseOrders = list;
        return list;
    }

    get totalPurchaseOrders(): number {
        if (this.filteredPurchaseOrders && this.filteredPurchaseOrders.length > 0) {
            return this.filteredPurchaseOrders.length;
        }
        return 0;
    }

    get sumTotalExcTax(): number {
        return Number((this.displayedPOPage?.map((x: IPurchaseOrder) => x.totalExcTax).reduce((x: number, y: number) => x + y, 0) ?? 0).toFixed(2));
    }

    get sumTotalExcTaxFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.sumTotalExcTax);
    }

    get displayedPOPage(): Array<IPurchaseOrder> {
        if (this.filteredPurchaseOrders != null) {
            return this.filteredPurchaseOrders?.slice((this.purchaseOrdersCurrentPage - 1) * 10, this.purchaseOrdersCurrentPage * 10);
        }
        return [];
    }

    get sumAmountInvoiced(): string {
        const invoices = vxm.project.invoicesList;
        const invoicesTotalExcTax = invoices?.filter(x => x.purchaseOrderId !== null && this.displayedPOPage?.find(y => y.id === x.purchaseOrderId))?.map(x => x.totalExcTax);
        let amount = 0;
        if (invoicesTotalExcTax && invoicesTotalExcTax.length === 1) {
            amount = invoicesTotalExcTax[0] ?? 0;
        }
        if (invoicesTotalExcTax && invoicesTotalExcTax.length > 1) {
            amount = invoicesTotalExcTax.reduce((a: number, b: number) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)) ?? 0;
        }
        const percentage = Number((amount > 0 && this.sumTotalExcTax > 0 ? (amount / this.sumTotalExcTax) * 100 : 0).toFixed(2));
        return `${Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount)} 
            (${new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(percentage / 100)})`;
    }

    private isBusy: boolean = true;

    private editPurchaseOrder(item: IPurchaseOrder[], _index: number, _event: Event): void {
        this.$emit('selectedPurchaseOrder', item[0]);
    }

    private baseFields: IBootstrapTableColumn[] = [
        { label: 'Référence', key: 'reference', sortable: true },
        { label: 'Désignation', key: 'description', sortable: true },
        { label: 'Fournisseur', key: 'supplier.label', sortable: true },
        {
            label: 'Date de création',
            key: 'date',
            sortable: true,
            formatter: (val: Date, key?: string, item?) => format(new Date(String(val)), 'dd/MM/yyyy')
        },
        {
            label: 'Total HT',
            key: 'totalExcTax',
            tdClass: 'moneyCell',
            sortable: false,
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val)
        },
        {
            label: 'Facturé HT',
            key: 'amountInvoiced',
            tdClass: 'moneyCell',
            sortable: false,
            formatter: (val: number, key?: string, item?) => this.amountInvoiced(item)
        }
    ];

    private async mounted(): Promise<void> {
        this.getPurchaseOrderList();
        this.isBusy = false;
    }

    private async getPurchaseOrderList(): Promise<void> {
        await vxm.project.fetchPurchaseOrders(this.projectId);
    }

    private async updatePurchaseOrderList(): Promise<void> {
        await vxm.project.updatePurchaseOrders(this.projectId);
    }

    public showModal(): void {
        this.createPurchaseOrderObject = {} as ICreatePurchaseOrder;
        const purchaseOrderNumber = '00' + ((this.purchaseOrders?.length ?? 0) + 1);
        const projectTrigram = vxm.project.projectData?.trigram;
        const defaultProjectDesignation = `${vxm.project.projectData?.trigram}_${vxm.project.projectData?.designation}`;
        this.createPurchaseOrderObject.reference = `${projectTrigram?.toUpperCase()}${purchaseOrderNumber.substr(
            purchaseOrderNumber.length - 3,
            purchaseOrderNumber.length
        )}`;
        this.createPurchaseOrderObject.description = `${projectTrigram?.toUpperCase()}_`;
        this.createPurchaseOrderObject.projectId = +this.projectId;
        this.createPurchaseOrderObject.projectDesignation = defaultProjectDesignation ?? '';
        (this.$refs['create-purchase-order-modal'] as any).show();
    }

    public async createPurchaseOrder(): Promise<void> {
        const result = await purchaseOrderApi.createPurchaseOrder(this.createPurchaseOrderObject);
        if (result.cancelled === false && !result.error && result.datas) {
            (this.$refs['create-purchase-order-modal'] as any).hide();
            this.updatePurchaseOrderList().then(x => {
                (this.$refs['purchase-order-table'] as BTable).selectRow((this.purchaseOrders?.length ?? 0) - 1);
            });
        }
    }

    get fields(): Array<IBootstrapTableColumn | string> {
        return this.baseFields;
    }

    private onFiltered(filteredItems: IPurchaseOrder[]) {
        this.filteredPurchaseOrders = filteredItems;
        this.purchaseOrdersCurrentPage = 1;
    }

    private amountInvoiced(item: IPurchaseOrder): string {
        const invoices = vxm.project.invoicesList;
        const invoicesTotalExcTax = invoices?.filter(x => x.purchaseOrderId === item.id)?.map(x => x.totalExcTax);
        let amount = 0;
        if (invoicesTotalExcTax && invoicesTotalExcTax.length === 1) amount = invoicesTotalExcTax[0] ?? 0;
        if (invoicesTotalExcTax && invoicesTotalExcTax.length > 1) {
            amount = invoicesTotalExcTax.reduce((a: number, b: number) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)) ?? 0;
        }
        const percentage = Number(
            (amount > 0 && item.totalExcTax > 0 ? (amount / item.totalExcTax) * 100 : 0).toFixed(2)
        );
        return `${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(
            amount
        )} (${new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(
            percentage / 100
        )})`;
    }

    async exportListPurchaseOrder(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const listPurchaseOrders = this.filteredPurchaseOrders!.map((x) => x.id);
        await this.generateListPurchaseOrder(
            purchaseOrderApi.exportPurchaseOrderData(listPurchaseOrders as any, sortKeySelected as string, sortKeyDescSelected as any),
            `Liste_bons_de_commandes${this.formatDate(new Date())}.xlsx`
        );
    }
  
    private async generateListPurchaseOrder(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd-HH:mm:ss', { locale: frenchLocale }) ?? '';
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        var a, b;
        if (key === 'description') {
            a = itemA[key];
            b = itemB[key];
            if (a === null) a = '';
            if (b === null) b = '';
            return this.strcmp(a.replaceAll('_', '-').toLowerCase(), b.replaceAll('_', '-').toLowerCase());
        }
    }
}
