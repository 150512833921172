


























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import BaseLoader from '@/components/shared/base-loader.vue';

import { IInvoice } from '@/entity/invoice/invoice';
import { IModelInvoice } from '@/entity/pdf/pdf-model-invoice';
import { ISignature } from '@/entity/pdf/pdf-signature';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { invoiceApi } from '@/wapi/invoice-api';
import { NU } from '@t/type';

@Component({
    components: {
        BaseLoader
    }
})
export default class InvoicePdfCard extends Vue {
    @Prop({ required: false, type: Boolean }) private generatingPdf?: boolean;
    @Prop({ required: true }) private invoice!: IInvoice | undefined;

    private parametersVisible: boolean = true;
    private selectedModelCode: string = '';
    private option: string = '';

    get optionInput(): string {
        return this.option;
    }

    set optionInput(value: string) {
        this.option = value;
        this.$emit('change:option', value);
    }

    @Watch('selectedModel')
    private selectedModelChange() {
        if (!this.selectedModel?.hasOption) {
            this.optionInput = '';
        } else {
            this.optionInput = this.invoice?.progressComment ?? '';
        }
    }

    get selectedModel(): IModelInvoice | undefined {
        const selectedModel = this.modelInvoices.find((m) => m.code === this.selectedModelCode);
        this.$emit('change:model', selectedModel ?? null);
        return selectedModel;
    }

    private ComptabilityImg = require('@/assets/sign-comptability.png');
    private RichezImg = require('@/assets/sign-richez.png');
    private signatures: ISignature[] = [];
    private modelInvoices: IModelInvoice[] = [];
    private selectedSignatureCode: string = '';

    get selectedSignatureCodeInput(): string {
        return this.selectedSignatureCode;
    }

    set selectedSignatureCodeInput(value: string) {
        this.selectedSignatureCode = value;
        const signature = this.signatures.find((s) => s.value === this.selectedSignatureCode);
        signature!.img = (signature!.id === 1) ? this.ComptabilityImg : this.RichezImg;
        this.$emit('change:signature', signature ?? null);
    }
    
    private senderEmail: string = '';
    get senderEmailInput(): string {
        return this.senderEmail;
    }

    set senderEmailInput(value: string) {
        this.$emit('change:email', value);
        this.senderEmail = value;
    }

    private clientContact: string = '';
    get clientContactInput(): string {
        if (this.invoice?.toAttentionOf) {
            this.clientContact = this.invoice?.toAttentionOf;
            return this.invoice?.toAttentionOf;
        } else return this.clientContact;
    }

    set clientContactInput(value: string) {
        this.$emit('change:contact', value);
        this.clientContact = value;
    }

    private generateWord() {
        this.$emit('generate-word');
    }

    private generatePdf() {
        this.$emit('generate-pdf');
    }

    private goBack() {
        this.$emit('go-back');
    }

    private toggleParameters() {
        this.parametersVisible = !this.parametersVisible;
    }

    private async mounted() {
        const datasInvoiceSignings = await invoiceApi.getAllSigningInvoice();
        if (isCallValidAndNotCancelled<ISignature[] | null>(datasInvoiceSignings)) {
            this.signatures = datasInvoiceSignings?.datas ?? this.signatures;
            if (this.invoice?.signingId && this.signatures) {
                var signat = this.signatures.find(m => m.id === this.invoice?.signingId)!;
                this.selectedSignatureCode = signat.value!;
                signat.img = (signat.id === 1) ? this.ComptabilityImg : this.RichezImg;        
            }
        }
        const datasInvoiceModels = await invoiceApi.getAllModelInvoice();
        if (isCallValidAndNotCancelled<IModelInvoice[] | null>(datasInvoiceModels)) {
            this.modelInvoices = datasInvoiceModels?.datas ?? this.modelInvoices;
            if (this.invoice?.modelInvoiceId && this.modelInvoices) {
                this.selectedModelCode = this.modelInvoices.find(m => m.id === this.invoice?.modelInvoiceId)!.code!;           
            }
        }
        this.optionInput = this.invoice?.progressComment ?? '';
        this.clientContactInput = this.invoice?.toAttentionOf ?? '';
    }
}
