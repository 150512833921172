import { IClient } from '@/entity/shared/client';
import { IContact } from '@/entity/shared/contact';
import ApiBase from '@api/api-base';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';

class ClientApi extends ApiBase<IClient> {
    /**
     *
     */
    constructor(urlBase: string = 'api/clients') {
        super(urlBase);
    }

    public async createClient(data: IClient): Promise<ICancellableResult<string>> {
        return await ajaxCall.post<string, IClient>(this.url, data);
    }

    public async getAllByCode(code: string): Promise<ICancellableResult<IClient[]> | null> {
        return await ajaxCall.get<IClient[]>(this.url, { urlParameter: 'code', code });
    }

    public async getByIdAndCode(id: string, code: string): Promise<ICancellableResult<IClient> | null> {
        return await ajaxCall.baseAjaxCall<IClient>('get', this.url, { urlParameter: [id], queryParameter: { code } });
    }

    public async createContactInADGroup(data: IContact[], clientId: number): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, IContact[]>('post', this.url, {
            urlParameter: [clientId + '', '/contacts'],
            payload: data
        });
    }

    public async removeContactInADGroup(data: IContact[], clientId: number): Promise<ICancellableResult<void>> {
        return await ajaxCall.baseAjaxCall<void, IContact[]>('patch', this.url, {
            urlParameter: [clientId + '', '/contacts'],
            payload: data
        });
    }

    public async exportClientData(data: number[], sortKeySelected: string, isSortDesc: boolean): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                ClientIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
            },
            urlParameter: ['exportClientData'],
            responseType: 'blob'
        });
    }
}
export const clientApi: ClientApi = new ClientApi();
