





















































































































































































































import { IManagementPhase, IProjectSave, IProjectSaveContractData, IRegroupingContractLine } from '@/entity/management/management-phase';
import { IProject } from '@/entity/project/project';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { managementApi } from '@/wapi/management-api';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { NU } from '@t/type';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { Component, Vue, Watch } from 'vue-property-decorator';
import PercentageInput from '@c/shared/percentage-input.vue';
import { vxm } from '@/store';
import { BTable } from 'bootstrap-vue';
import { appTokenMgr } from '@t/employee-app-role';
import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { projectApi } from '@/wapi/project-api';
import moment from 'moment';
import ProjectManagementModal from './project-management-modal.vue';

@Component({
    components: {
        PercentageInput,
        ProjectManagementModal
    }
})
export default class ProjectManagements extends Vue {
    managementPhases: NU<IManagementPhase[]> = [];
    regroupingContractLineList: IRegroupingContractLine[] = [];
    targetRegroupingContractLineId: number = 0;
    savePending: boolean = false;
    private isBusy: boolean = true;
    private promiseExport: boolean = false;
    selectedPlanificationPhaseToUpdate: IPlanificationPhase = {} as IPlanificationPhase;
    private selectedProjectSaveId: NU<number> = null;   

    get filteredManagementPhases(): IManagementPhase[] {
        const result =
            this.managementPhases?.filter(
                (x) =>
                    this.regroupingContractLineList.find((y) => y.attachedContractLineId === x.contractLine.id) ==
                        null ||
                    this.regroupingContractLineList.find(
                        (y) =>
                            y.targetContractLineId === this.targetRegroupingContractLineId &&
                            x.contractLine.id === y.attachedContractLineId
                    ) != null
            ) ?? [];
        result.sort(
            (a, b) => (a.contractLine?.positionNumberIntoProject ?? 0) - (b.contractLine.positionNumberIntoProject ?? 0)
        );
        return result;
    }

    get selectedProject(): NU<IProject> {
        return vxm.project.dropdownProject;
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    get totalRate(): string {
        let val = 0;
        this.managementPhases?.forEach((element) => {
            val += element.contractLine.total;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalRegroupingRate(): string {
        let val = 0;
        this.managementPhases?.forEach((element) => {
            val += element.regroupingTotal;
        });
        val = Math.round(val);
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalProvisionalFees(): string {
        let val = 0;
        this.managementPhases?.forEach((element) => {
            val += element.provisionalFeesValue;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalSubContracts(): string {
        let val = 0;
        this.managementPhases?.forEach((element) => {
            val += element.subContractsValue;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalMargePercentage(): string {
        let val = 0;
        let totalHonors = 0;
        let totalBudget = 0;
        this.managementPhases?.forEach((element) => {
            totalHonors += element.regroupingTotal - element.provisionalFeesValue - element.subContractsValue;
            totalBudget += this.getBudget(element);
        });
        val = 100 - (totalBudget / totalHonors) * 100;
        return `${new Intl.NumberFormat('fr-FR', { style: 'percent', maximumFractionDigits: 0 }).format(
            (isNaN(val) ? 0 : val) / 100
        )}`;
    }

    get totalMargeAmount(): string {
        let totalHonors = 0;
        let totalBudget = 0;
        this.managementPhases?.forEach((element) => {
            totalHonors += element.regroupingTotal - element.provisionalFeesValue - element.subContractsValue;
            totalBudget += this.getBudget(element);
        });
        return `${new Intl.NumberFormat('fr-FR', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0
        }).format(totalHonors - totalBudget)}`;
    }

    get totalBudget(): string {
        let val = 0;
        this.managementPhases?.forEach((element) => {
            val += this.getBudget(element);
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totaConsumedRates(): string {
        let val = 0;
        this.managementPhases?.forEach((element) => {
            val += element.consumedRatesValue;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalCostRates(): string {
        let val = 0;
        this.managementPhases?.forEach((element) => {
            val += element.planificationPhaseCostValue;
        });
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            val
        );
    }

    get totalProgressAmountsValue(): number {
        let val = 0;
        this.managementPhases?.forEach((element) => {
            val += this.progressAmount(element);
        });
        return val;
    }

    get totalProgressAmounts(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            this.totalProgressAmountsValue
        );
    }

    private fields: Array<IBootstrapTableColumn> = [
        { label: '', key: 's', sortable: false, thStyle: 'max-width: 100px;', tdClass: 'td-s-width' },
        {
            label: 'Contrat',
            key: 'contractReference',
            formatter: (val: string, key?: string, item?) => val.toUpperCase(),
            tdClass: 'td-class bold-class'
        },
        {
            label: 'Mission',
            key: 'contractLine.designation',
            formatter: (val: string, key?: string, item?) => val.toUpperCase(),
            tdClass: 'td-class bold-class',
            thStyle: 'min-width:230px'
        },
        {
            label: 'Date de Début',
            key: 'planificationPhase.openingDate',
            formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR') : '-'),
            tdClass: 'td-class'
        },
        {
            label: 'Date de fin',
            key: 'planificationPhase.closingDate',
            formatter: (val: Date, key?: string, item?) => (val ? new Date(val).toLocaleDateString('fr-FR') : '-'),
            tdClass: 'td-class'
        },
        {
            label: 'Honoraires',
            key: 'contractLine.total',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class'
        },
        {
            label: 'Honos regroupés',
            key: 'regroupingTotal',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class'
        },
        {
            label: 'Frais',
            key: 'provisionalFeesValue',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class'
        },
        {
            label: 'STT',
            key: 'subContractsValue',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class'
        },
        {
            label: 'Marge',
            key: 'marginPercentage',
            thStyle: 'min-width:95px;width:95px',
            tdClass: 'td-class'
        },
        {
            label: 'Budget',
            key: 'getBudget',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    this.getBudget(item) ?? 0
                ),
            tdClass: 'td-class'
        },
        {
            label: 'Prévisionnel',
            key: 'planificationPhaseCostValue',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class'
        },
        {
            label: 'Consommé',
            key: 'consumedRatesValue',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    val ?? 0
                ),
            tdClass: 'td-class'
        },
        {
            label: 'Avcmt',
            key: 'advancement',
            thStyle: 'min-width:95px;width:95px',
            tdClass: 'td-class'
        },
        {
            label: 'Situ selon avcmt',
            key: 'progressAmount',
            formatter: (val: number, key?: string, item?) =>
                new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
                    this.progressAmount(item) ?? 0
                ),
            tdClass: 'td-class',
            thStyle: 'min-width:100px'
        },
        {
            label: '',
            key: 'regrouping',
            thStyle: 'min-width:100px;width:100px'
        }
    ];

    get formatedOpeningDate(): string {
        if (this.selectedProject?.openingDate) {
            return (
                format(new Date(String(this.selectedProject?.openingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    get formatedClosingDate(): string {
        if (this.selectedProject?.closingDate) {
            return (
                format(new Date(String(this.selectedProject?.closingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    @Watch('selectedProjectId')
    async assigneProject(newVal: number, _oldVal: number): Promise<void> {
        if (newVal && newVal > 0) {
            await this.fetchData(newVal);         
        }
    }

    @Watch('regroupingContractLineList')
    assigneregrouping(newVal: IRegroupingContractLine[], _oldVal: IRegroupingContractLine[]): void {
        this.setSelectedRowForGroupingLine(newVal);
    }

    private setSelectedRowForGroupingLine(val: IRegroupingContractLine[]): void {
        const table = this.$refs['management-table'] as BTable;
        this.filteredManagementPhases?.forEach((element, index) => {
            const regroupingContractLine = val.find((x) => x.attachedContractLineId === element.contractLine.id);
            if (
                (regroupingContractLine || element.contractLine.id === this.targetRegroupingContractLineId) &&
                this.targetRegroupingContractLineId !== 0
            ) {
                setTimeout(() => {
                    table.selectRow(index);
                }, 100);
            } else {
                setTimeout(() => {
                    table.unselectRow(index);
                }, 100);
            }
        });
    }

    async getManagementPhases(projectId: number): Promise<void> {
        const phaseCallData = await managementApi.getAllPhasesByProjectId(projectId);

        if (isCallValidAndNotCancelled<IManagementPhase[]>(phaseCallData)) {
            this.managementPhases = phaseCallData?.datas;
        }
    }

    async getRegroupingContractLines(projectId: number): Promise<void> {
        const CallData = await managementApi.getAllRegroupingContractLinesByProjectId(projectId);

        if (isCallValidAndNotCancelled<IRegroupingContractLine[]>(CallData)) {
            this.regroupingContractLineList = CallData?.datas ?? [];
        }
    }

    async saveManagementPhases(): Promise<void> {
        this.savePending = true;
        const callData = await managementApi.patchPhases(this.managementPhases ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Gestion de projet',
                variant: 'success',
                solid: true
            });
            if (this.selectedProjectId && this.selectedProjectId !== 0) {
                await this.getManagementPhases(this.selectedProjectId);
            }
        }
        this.savePending = false;
    }

    private getBudget(management: IManagementPhase): number {
        if (management && management.contractLine) {
            return Number(
                (
                    (management.regroupingTotal - management.provisionalFeesValue - management.subContractsValue) *
                    ((100 - management.contractLine.marginPercentage) / 100)
                ).toFixed(0)
            );
        }
        return 0;
    }

    private progressAmount(management: IManagementPhase): number {
        if (management && management.contractLine) {
            if (management.contractLine.advancement === 0) return 0;
            if (management.contractLine.advancement === 100) {
                return Number((this.getBudget(management) - management.consumedRatesValue).toFixed(0));
            }
            return Number(
                (
                    this.getBudget(management) * (management.contractLine.advancement / 100) -
                    management.consumedRatesValue
                ).toFixed(0)
            );
        }
        return 0;
    }

    private setTarget(item: IManagementPhase): void {
        this.targetRegroupingContractLineId = item.contractLine.id;
        // (this.$refs['management-table'] as BTable).selectRow(this.managementPhases?.indexOf(item) ?? 0);
        this.setSelectedRowForGroupingLine(this.regroupingContractLineList);
    }

    private cancelRegrouping(item: IManagementPhase): void {
        this.targetRegroupingContractLineId = 0;
        this.setSelectedRowForGroupingLine(this.regroupingContractLineList);
    }

    private async submitRegrouping(item: IManagementPhase): Promise<void> {
        this.savePending = true;
        if (!this.selectedProjectId || this.selectedProjectId === 0) {
            return;
        }
        const CallData = await managementApi.patchRegroupingContractLines(
            this.selectedProjectId,
            this.regroupingContractLineList
        );

        if (isCallValidAndNotCancelled<boolean>(CallData)) {
            if (this.selectedProjectId && this.selectedProjectId !== 0) {
                this.fetchData(this.selectedProjectId);
            }
            this.savePending = false;
        }
    }

    private addRegrouping(item: IManagementPhase): void {
        const exist = this.regroupingContractLineList?.find(
            (x) =>
                x.attachedContractLineId === item.contractLine.id &&
                x.targetContractLineId === this.targetRegroupingContractLineId
        );
        if (exist) {
            const index = this.regroupingContractLineList?.indexOf(exist);
            if (index !== undefined && index > -1) {
                this.regroupingContractLineList?.splice(index, 1);
            }
        } else {
            this.regroupingContractLineList?.push({
                projectId: this.selectedProjectId,
                targetContractLineId: this.targetRegroupingContractLineId,
                attachedContractLineId: item.contractLine.id
            } as IRegroupingContractLine);
        }
    }

    private getAttachedNumber(item: IManagementPhase): number {
        return (
            this.regroupingContractLineList?.filter((x) => x.targetContractLineId === item.contractLine.id)?.length ?? 0
        );
    }

    private isAlreadyRegrouping(item: IManagementPhase): boolean {
        return this.getAttachedNumber(item) > 0;
    }

    private canEdit(): boolean {
        return appTokenMgr.isAdmin() || appTokenMgr.isStudioManager();
    }

    sortDown(item: IManagementPhase): void {
        let index = this.managementPhases?.indexOf(item) ?? -1;
        let regroupingIndexsToMove: number[] = [];
        if (index !== undefined && index > -1 && this.managementPhases) {
            do {
                regroupingIndexsToMove = [];
                this.managementPhases.forEach((element, indexArray) => {
                    const isRegroupingElement =
                        this.regroupingContractLineList.find(
                            (y) =>
                                y.targetContractLineId === item.contractLine.id &&
                                y.attachedContractLineId === element.contractLine.id
                        ) !== undefined;
                    if (isRegroupingElement) {
                        regroupingIndexsToMove.push(indexArray);
                    }
                });
                regroupingIndexsToMove = regroupingIndexsToMove.reverse();
                regroupingIndexsToMove.forEach((element) => {
                    this.managementPhases?.splice(element + 1, 0, this.managementPhases?.splice(element, 1)[0]);
                });
                this.managementPhases?.splice(index + 1, 0, this.managementPhases?.splice(index, 1)[0]);
                index = this.managementPhases?.indexOf(item) ?? -1;
            } while (
                this.managementPhases &&
                index < this.managementPhases.length - 1 &&
                this.regroupingContractLineList.find(
                    (y) =>
                        this.managementPhases &&
                        y.attachedContractLineId === this.managementPhases[index + 1].contractLine.id &&
                        y.targetContractLineId !== item.contractLine.id
                ) !== undefined
            );
        }
    }

    sortUp(item: IManagementPhase): void {
        let index = this.managementPhases?.indexOf(item) ?? -1;
        let regroupingIndexsToMove: number[] = [];
        if (index !== undefined && index > -1 && this.managementPhases) {
            do {
                regroupingIndexsToMove = [];
                this.managementPhases?.splice(index - 1, 0, this.managementPhases?.splice(index, 1)[0]);
                this.managementPhases.forEach((element, indexArray) => {
                    const isRegroupingElement =
                        this.regroupingContractLineList.find(
                            (y) =>
                                y.targetContractLineId === item.contractLine.id &&
                                y.attachedContractLineId === element.contractLine.id
                        ) !== undefined;
                    if (isRegroupingElement) {
                        regroupingIndexsToMove.push(indexArray);
                    }
                });
                regroupingIndexsToMove.forEach((element) => {
                    this.managementPhases?.splice(element - 1, 0, this.managementPhases?.splice(element, 1)[0]);
                });
                index = this.managementPhases?.indexOf(item) ?? -1;
            } while (
                index > 0 &&
                this.regroupingContractLineList.find(
                    (y) =>
                        this.managementPhases &&
                        y.targetContractLineId === this.managementPhases[index - 1].contractLine.id
                ) !== undefined
            );
        }
    }

    private async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Gestion de projet');
        if (this.selectedProjectId && this.selectedProjectId > 0) {
            this.fetchData(this.selectedProjectId);
        }
        this.isBusy = false;       
    }

    private async fetchData(projectId: number): Promise<void> {
        this.targetRegroupingContractLineId = 0;
        await this.getManagementPhases(projectId);
        await this.getRegroupingContractLines(projectId);
    }

    async exportListManagement(): Promise<void> {
        await this.generateReport(
            managementApi.exportManagementData({ data: this.selectedProjectId } as any),
            `Gestion_de_projet_${this.selectedProject?.designation}_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }
}
