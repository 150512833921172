




















































































































































































import { vxm } from '@/store';
import { moduleApiGraph } from '@t/module-api-graph';
import { authModule } from '@t/session';
import { Component, Watch } from 'vue-property-decorator';
import RHBaseClass from '../rh-class-base.vue';
import {
    User,
    Application,
    AppRole,
    AppRoleAssignment,
    ServicePrincipal
} from '@microsoft/microsoft-graph-types/microsoft-graph';
import { employeeApi } from '@/wapi/employee-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IWorkflowSummary } from '@/entity/rh/workflow';
import { IEmployee } from '@/entity/shared/employee';
import { workflowApi } from '@/wapi/workflow-api';
import InputElement from '@c/shared/input-element.vue';
import UserLeave from '@c/rh/users/user-leave.vue';
import UserLeaveToValidate from '@c/rh/users/user-leave-to-validate.vue';
import UserOverTime from '@c/rh/users/user-over-time.vue';
import UserOverTimeToValidate from '@c/rh/users/user-over-time-to-validate.vue';
import UserRealizedOverTime from '@c/rh/users/user-realized-over-time.vue';
import UserRealizedOverTimeToValidate from '@c/rh/users/user-realized-over-time-to-validate.vue';
import { IEmployeeRole, IReferential } from '@/entity/shared/referential';
import { IOdataBaseReq } from '@/entity/rh/test-req';
import { IStudio } from '@/entity/project/studio';
import { currentServerConfiguration } from '@s/server-configuration';
import { NU } from '@t/type';
import { studioApi } from '@/wapi/studio-api';
import { IAgency } from '@/entity/project/agency';
import { agencyApi } from '@/wapi/agency-api';
import { appTokenMgr } from '@t/employee-app-role';

@Component({
    components: {
        InputElement,
        UserLeave,
        UserLeaveToValidate,
        UserOverTime,
        UserOverTimeToValidate,
        UserRealizedOverTime,
        UserRealizedOverTimeToValidate
    }
})
export default class UserInformation extends RHBaseClass {
    workflowLeaveList: IWorkflowSummary[] = [];
    workflowLeaveListToValidate: IWorkflowSummary[] = [];
    workflowOverTimeList: IWorkflowSummary[] = [];
    workflowOverTimeListToValidate: IWorkflowSummary[] = [];
    workflowRealizedOverTimeList: IWorkflowSummary[] = [];
    workflowRealizedOverTimeListToValidate: IWorkflowSummary[] = [];
    teleworkingCount: number = 0;
    currentEmployee: IEmployee = {} as IEmployee;
    currentAppRoles: string[] = [];
    oldAppRoles: AppRoleAssignment[] = [];
    app: Application = {};
    sPrincipal: ServicePrincipal = {};
    studios: NU<IStudio[]> = [];
    agencies: NU<IAgency[]> = [];
    admin: boolean = false;

    get isAdmin(): boolean {
        return this.admin;
    }

    get employeeRoles(): IEmployeeRole[] {
        if (vxm.referential.employeeRoles) {
            return vxm.referential.employeeRoles;
        }
        return [];
    }

    get appRoles(): AppRole[] {
        if (this.app.appRoles) {
            return this.app.appRoles;
        }
        return [];
    }

    get employeeContracts(): IReferential[] {
        if (vxm.referential.employeeContracts) {
            return vxm.referential.employeeContracts;
        }
        return [];
    }

    get employeeServices(): IReferential[] {
        if (vxm.referential.employeeServices) {
            return vxm.referential.employeeServices;
        }
        return [];
    }

    get isCurrentUserInformations(): boolean {
        const employeeIdentifier = authModule.getAccount()!.localAccountId!;
        return employeeIdentifier === this.id;
    }

    get isTrigramValid(): boolean {
        return (
            this.currentEmployee.trigram !== null &&
            this.currentEmployee.trigram !== undefined &&
            this.currentEmployee.trigram!.length === 3
        );
    }

    async created(): Promise<void> {
        await this.fetchReferential();
    }

    private async updateAppRoles() {
        const addRoles = this.currentAppRoles.filter((x) => this.oldAppRoles.find((y) => y.appRoleId === x) == null);
        const remRoles = this.oldAppRoles.filter((x) => this.currentAppRoles.find((y) => y === x.appRoleId) == null);

        addRoles.forEach(async (element) => {
            const t = {
                principalId: this.id,
                resourceId: this.sPrincipal.id,
                appRoleId: element
            } as AppRoleAssignment;

            await moduleApiGraph.Client.api(`/users/${this.id}/appRoleAssignments`).post(t);
            if (this.app.appRoles?.find((x) => x.id === element)?.value === 'Admin') {
                const roleDefAdd = {
                    roleDefinitionId: currentServerConfiguration.getConfiguration().pRoleId,
                    principalId: this.id,
                    directoryScopeId: '/'
                };

                await moduleApiGraph.Client.api('roleManagement/directory/roleAssignments')
                    .version('beta')
                    .post(roleDefAdd);
            }
        });

        remRoles.forEach(async (element) => {
            await moduleApiGraph.Client.api(`/users/${this.id}/appRoleAssignments/${element.id}`).delete();

            if (this.app.appRoles?.find((x) => x.id === element.appRoleId)?.value === 'Admin') {
                const assign: IOdataBaseReq<any> = await moduleApiGraph.Client.api(
                    `roleManagement/directory/roleAssignments?$filter=roleDefinitionId+eq+'${
                        currentServerConfiguration.getConfiguration().pRoleId
                    }'`
                )
                    .version('beta')
                    .get();
                if (assign.value && assign.value.length > 0) {
                    await moduleApiGraph.Client.api(`roleManagement/directory/roleAssignments/${assign.value[0].id}`)
                        .version('beta')
                        .delete();
                }
            }
        });
    }

    private async saveEmployee(): Promise<void> {
        await employeeApi.patchBase(this.id, this.currentEmployee).then((x) => {
            if (x) {
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: 'Utilisateur',
                    variant: 'success',
                    solid: true
                });
            }
        });
        if (this.admin) {
            await this.updateAppRoles();
        }
    }

    async fetchReferential(): Promise<void> {
        const studioCallData = await studioApi.getAllBase();

        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.studios = studioCallData?.datas;
        }

        const agencyCallData = await agencyApi.getAllBase();

        if (isCallValidAndNotCancelled<IAgency[]>(agencyCallData)) {
            this.agencies = agencyCallData?.datas;
        }

        await vxm.referential.fetchEmployeeRoles();
        await vxm.referential.fetchEmployeeServices();
        await vxm.referential.fetchEmployeeContracts();
    }

    async onLoad(): Promise<void> {
        this.currentAppRoles = [];
        this.admin = appTokenMgr.isAdmin();
        await this.getEmployeeData();
        if (this.admin) {
            await this.getAssignedAppRole();
        }
    }

    @Watch('id')
    async onEmployeeIdChange(newVal: number, _oldVal: number): Promise<void> {
        await this.onLoad();
    }

    async mounted(): Promise<void> {
        await this.onLoad();
    }

    private async getAssignedAppRole(): Promise<void> {
        const allApplicationWithRoles: IOdataBaseReq<Application> = await moduleApiGraph.Client.api(
            '/applications?$select=appRoles,id,displayName,appId'
        ).get();
        if (allApplicationWithRoles) {
            this.app = allApplicationWithRoles.value.find(
                (app) => app.appId === currentServerConfiguration.getConfiguration().clientId
            ) as Application;

            const sPrincipal: IOdataBaseReq<ServicePrincipal> = await moduleApiGraph.Client.api(
                `/servicePrincipals?$filter=displayName+eq+'${this.app?.displayName}'`
            ).get();

            if (sPrincipal && sPrincipal.value && sPrincipal.value.length > 0) {
                this.sPrincipal = sPrincipal.value[0];

                const currentUserAppRole: IOdataBaseReq<AppRoleAssignment> = await moduleApiGraph.Client.api(
                    `/users/${this.id}/appRoleAssignments?$filter=resourceId+eq+${this.sPrincipal.id}`
                ).get();

                this.currentAppRoles = currentUserAppRole.value
                    .map((x) => x.appRoleId)
                    .filter((x) => x != null) as string[];
                this.oldAppRoles = currentUserAppRole.value;
            }
        } else {
            throw new Error('application not found (azure)');
        }
    }

    private async getEmployeeData(): Promise<void> {
        vxm.app.changeTitleMain('Utilisateurs');
        if (this.isCurrentUserInformations) {
            vxm.app.changeTitleExt('Vos informations');
        } else {
            const currentUserData: User = await moduleApiGraph.Client.api(`/users/${this.id}`).get();
            vxm.app.changeTitleExt(`Informations de ${currentUserData.displayName}`);
        }

        const data = await employeeApi.getBase(this.id);
        if (isCallValidAndNotCancelled(data)) {
            this.currentEmployee = data?.datas as IEmployee;
            if (this.currentEmployee.trigram === undefined || this.currentEmployee.trigram === null) {
                this.currentEmployee.trigram = '';
            }
        }
        const leaves = await workflowApi.getLeaveWorkflows(this.id);
        this.workflowLeaveList = [];
        if (isCallValidAndNotCancelled(leaves)) {
            this.workflowLeaveList.push(
                ...((leaves.datas as IWorkflowSummary[]).map((x) => {
                    x.betweenMin = new Date(x.betweenMin);
                    x.betweenMax = new Date(x.betweenMax);
                    return x;
                }) as IWorkflowSummary[])
            );
        }

        const overTimes = await workflowApi.getOverTimeWorkflows(this.id);
        this.workflowOverTimeList = [];
        if (isCallValidAndNotCancelled(overTimes)) {
            this.workflowOverTimeList.push(
                ...((overTimes.datas as IWorkflowSummary[]).map((x) => {
                    x.betweenMin = new Date(x.betweenMin);
                    x.betweenMax = new Date(x.betweenMax);
                    return x;
                }) as IWorkflowSummary[])
            );
        }

        const realizedOverTimes = await workflowApi.getRealizedOverTimeWorkflows(this.id);
        this.workflowRealizedOverTimeList = [];
        if (isCallValidAndNotCancelled(realizedOverTimes)) {
            this.workflowRealizedOverTimeList.push(
                ...((realizedOverTimes.datas as IWorkflowSummary[]).map((x) => {
                    x.betweenMin = new Date(x.betweenMin);
                    x.betweenMax = new Date(x.betweenMax);
                    return x;
                }) as IWorkflowSummary[])
            );
        }

        const getTeleworkingCount = await workflowApi.GetTeleworkingCount(this.id);
        if (isCallValidAndNotCancelled(getTeleworkingCount)) {
            this.teleworkingCount = getTeleworkingCount.datas as number;
        }

        if (this.isCurrentUserInformations) {
            const leavesToValidate = await workflowApi.getLeaveWorkflowsToValidate(this.id);
            this.workflowLeaveListToValidate = [];
            if (isCallValidAndNotCancelled(leavesToValidate)) {
                this.workflowLeaveListToValidate.push(
                    ...((leavesToValidate.datas as IWorkflowSummary[]).map((x) => {
                        x.betweenMin = new Date(x.betweenMin);
                        x.betweenMax = new Date(x.betweenMax);
                        return x;
                    }) as IWorkflowSummary[])
                );
            }

            const overTimesToValidate = await workflowApi.getOverTimeWorkflowsToValidate(this.id);
            this.workflowOverTimeListToValidate = [];
            if (isCallValidAndNotCancelled(overTimesToValidate)) {
                this.workflowOverTimeListToValidate.push(
                    ...((overTimesToValidate.datas as IWorkflowSummary[]).map((x) => {
                        x.betweenMin = new Date(x.betweenMin);
                        x.betweenMax = new Date(x.betweenMax);
                        return x;
                    }) as IWorkflowSummary[])
                );
            }

            const realizedOverTimesToValidate = await workflowApi.getRealizedOverTimeWorkflowsToValidate(this.id);
            this.workflowRealizedOverTimeListToValidate = [];
            if (isCallValidAndNotCancelled(realizedOverTimesToValidate)) {
                this.workflowRealizedOverTimeListToValidate.push(
                    ...((realizedOverTimesToValidate.datas as IWorkflowSummary[]).map((x) => {
                        x.betweenMin = new Date(x.betweenMin);
                        x.betweenMax = new Date(x.betweenMax);
                        return x;
                    }) as IWorkflowSummary[])
                );
            }
        }
    }
}
