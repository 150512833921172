



































import { IImputationWorkflowItem } from '@/entity/rh/workflow';
import { NU } from '@t/type';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class ImputationCalendarDay extends Vue {
    @Prop({ required: true })
    private imputationDay: NU<IImputationWorkflowItem[]>
    
    @Prop({ required: true })
    private monthDay!: number;

    @Prop({ default: true })
    private readonly!: boolean;

    @Prop({ default: false })
    private weekendDay!: boolean;

    getImputationClass(imputation: IImputationWorkflowItem): string {
        const dayPartCode = imputation.dayPartType.code;
        const imputationDayPartClass = 'imputation-calendar-day-value';
        return `${imputationDayPartClass} ${dayPartCode} ${imputation.value > 1 || imputation.value < 0 ? 'error' : ''}`;
    }

    get imputations(): NU<IImputationWorkflowItem[]> {
        if (this.weekendDay) {
            return this.imputationDay?.filter(x => x.dayPartType.code === 'CHA');
        }
        return this.imputationDay;
    }
}
