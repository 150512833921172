





































































































import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { imputationApi } from '@/wapi/imputation-api';
import { vxm } from '@/store';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { ICrossProjectImputationRecap, IProjectRecap } from '@/entity/rh/imputation-recap';
import { NU } from '@t/type';
import { IEmployeeRole } from '@/entity/shared/referential';
import AffectationFilter from './affectation-filter.vue';
import { filterCacheService, IFilterCache } from '@/tools/filter-cache-service';
import { IEmployeeFilter, IRoleFilter } from './filter-entities';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { IEmployee } from '@/entity/shared/employee';
import { employeeApi } from '@/wapi/employee-api';
import { IStudio } from '@/entity/project/studio';
import { studioApi } from '@/wapi/studio-api';
import { appTokenMgr } from '@t/employee-app-role';
import { moduleApiGraph } from '@t/module-api-graph';
import { IOdataBaseReq } from '@/entity/rh/test-req';
import { User } from '@microsoft/microsoft-graph-types/microsoft-graph';

interface RadioOption {
    text: string;
    value: number;
    code: string;
}

interface ICellData {
    value: number;
}

interface UserRowCell extends ICellData {
    isValid: boolean;
    date: Date;
    role?: NU<string>;
}

interface IDictionary<T> {
    [key: string]: string | boolean | T | NU<string>;
}

interface TableRow<T> extends IDictionary<T> {
    name: string;
    employeeId?: NU<string>;
    isUserRow: boolean;
}

@Component({
    components: {
        AffectationFilter
    }
})
export default class Affectations extends Vue {
    @Prop({ required: true })
    private employeeIds!: string[];

    private loading: boolean = false;
    private excelLoading: boolean = false;
    private showFilters: boolean = false;
    private currentDurationFilterId: number = 1;
    private currentDisplayTableOptionId: number = 1;
    private projectFilter: NU<IFilterCache> = null;
    private imputationRecap: ICrossProjectImputationRecap[] = [];
    private allEmployees: IEmployee[] = [];
    private showTableByRoles: boolean = false;
    private isAdmin: boolean = true;
    private isStudioManager: boolean = false;
    studios: NU<IStudio[]> = [];
    private selectedStudio: NU<number> = null;
    private durationFilters: RadioOption[] = [
        {
            value: 1,
            code: '6M',
            text: '6 Mois'
        },
        {
            value: 2,
            code: '6S',
            text: '6 Semaines'
        }
    ];

    private displayTableOptions: RadioOption[] = [
        {
            value: 1,
            code: 'EMP',
            text: 'Par Employé'
        },
        {
            value: 2,
            code: 'ROL',
            text: 'Par Profil'
        }
    ];

    created(): void {
        this.fetchData();
    }

    async mounted() {
        const studioCallData = await studioApi.getAllBase();
        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.studios = studioCallData?.datas;
            if (this.studios) {
                this.studios.push({ id: 0, label: 'Tous' });
            }
        }
        this.isAdmin = appTokenMgr.isAdmin();
        this.isStudioManager = appTokenMgr.isStudioManager();
    }

    async fetchData(): Promise<void> {
        this.loading = true;
        this.imputationRecap = await this.fetchEmployeeImputations();
        this.allEmployees = await this.fetchEmployees();
        this.projectFilter = filterCacheService.getFilters();
        this.loading = false;
    }

    rowClass(d: NU<TableRow<ICellData>>): string {
        if (d) {
            if (d.isUserRow) {
                return 'headRow';
            } else if (d.name === '_') {
                return 'emptyRow';
            }
        }
        return '';
    }

    updateFilter(newFilter: { employeeIds: IEmployeeFilter[]; roles: IRoleFilter[] }): void {
        this.projectFilter = {
            employeeIds: newFilter.employeeIds.map((x) => x.employeeId),
            roles: newFilter.roles.map((x) => x.role)
        };
        filterCacheService.saveFilters(this.projectFilter.roles, this.projectFilter.employeeIds);
        this.showFilters = false;
    }

    cancelFilter(): void {
        this.showFilters = false;
    }

    onFilterShow(): void {
        this.showFilters = true;
    }

    @Watch('currentDurationFilterId')
    filterOnChange(): void {
        this.fetchData();
    }
    
    @Watch('selectedStudio')
    filterStudio(): void {
        this.fetchData();
    }

    @Watch('selectedProjectId')
    onProjectChanged(): void {
        this.fetchData();
    }
    
    async fetchEmployees(): Promise<IEmployee[]> {
        // const employeeCall = await employeeApi.getAllBase();
        const employeeCall = await employeeApi.getEmployeeNameActive();
        var employees: IEmployee[] = [];
        if (isCallValidAndNotCancelled(employeeCall)) {
            employees = employeeCall!.datas!;
        }
        if (this.isAdmin && this.selectedStudio != null && this.selectedStudio !== 0) {   
            employees = employees.filter(emp => emp.studioId && emp.studioId === this.selectedStudio);            
        }
        if (!this.isAdmin && this.isStudioManager) {
            await moduleApiGraph.Client.api('/me').get().then(value => {
                const employeesCurrent = employees.filter(emp => emp.studioId && emp.id === value.id.toUpperCase())[0];
                employees = employees.filter(emp => emp.studioId === employeesCurrent.studioId);
            });
        }
        return employees;
    }

    async fetchEmployeeImputations(): Promise<ICrossProjectImputationRecap[]> {
        this.imputationRecap = [];
        const today = new Date(this.beginDate);
        var limit: Date;
        if (this.currentDurationFilter?.code === '6M') {
            limit = new Date(today.getFullYear(), today.getMonth() + 6, 1);
        } else {
            limit = new Date(today);
            limit.setDate(today.getDate() - today.getDay() + 7 * 6);
        }
        const imputationRecaps = await imputationApi.getCrossProjectRecapBetweenDates(
            today,
            limit,
            this.currentDurationFilter?.code === '6M' ?? true
        );
        if (isCallValidAndNotCancelled(imputationRecaps)) {
            return imputationRecaps!.datas!;
        }
        return [];
    }

    get columnsToDisplay(): Date[] {
        const today = new Date(this.beginDate);

        const result: Date[] = this.listOfPeriods.map((x) => x.begin);
        return result;
    }

    get allExistingRoles(): IEmployeeRole[] {
        return vxm.referential.employeeRoles ?? [];
    }

    get distinctEmployeeRoles(): IEmployeeRole[] {
        const result: IEmployeeRole[] = [];
        for (let imputationIndex = 0; imputationIndex < this.imputationRecap.length; imputationIndex++) {
            const curRole = this.imputationRecap[imputationIndex].role;
            if (curRole !== null && result.every((x) => x.id !== curRole.id)) {
                result.push(curRole);
            }
        }
        return result;
    }

    get rolesOfProject(): IEmployeeRole[] {
        const result: IEmployeeRole[] = [];
        for (let imputationIndex = 0; imputationIndex < this.imputationRecap.length; imputationIndex++) {
            const curImputation = this.imputationRecap[imputationIndex];
            if (
                curImputation.role !== null &&
                result.every((x) => x.id !== curImputation.role.id) &&
                (this.selectedProjectId || curImputation.recaps.some((x) => x.project.id === this.selectedProjectId))
            ) {
                result.push(curImputation.role);
            }
        }
        return result;
    }

    get employeeFilters(): string[] {
        return this.projectFilter ? this.projectFilter.employeeIds : this.allEmployees.map(x => x.id);
    }

    get tableColumns(): IBootstrapTableColumn[] {
        return [
            {
                key: 'name',
                label: this.displayEmployeeTable ? 'Collaborateur' : 'Rôle',
                stickyColumn: true,
                thClass: 'headerClass'
            },
            ...this.columnsToDisplay.map((x) => {
                return {
                    key: this.genKey(x),
                    thClass: 'headerClass fixedColumn',
                    label: this.genLabel(x),
                    tdClass: (value: any, key: string, item: any) => [
                        this.getCellClass(value as ICellData, key, item as TableRow<ICellData>),
                        'alignRight'
                    ],
                    formatter: (item) => this.fomatCell(item as ICellData)
                } as IBootstrapTableColumn;
            })
        ];
    }

    genLabel(date: Date): string {
        if (this.currentDurationFilter?.code === '6M') {
            return date.toLocaleString('default', { month: 'long', year: '2-digit' });
        } else {
            const nextDate: Date = new Date(date);
            nextDate.setDate(date.getDate() + 6);
            return `${date.toLocaleString('default', {
                day: '2-digit',
                month: '2-digit',
                year: '2-digit'
            })} - ${nextDate.toLocaleString('default', { day: '2-digit', month: '2-digit', year: '2-digit' })}`;
        }
    }

    fomatCell(item: ICellData | null): any {
        return item?.value ?? null;
    }

    getCellClass(value: ICellData, key: string, item: TableRow<ICellData>): string {
        if ((key && key === 'name') || !item.isUserRow || !value) {
            return '';
        }
        if (value) {
            return (value as UserRowCell).isValid ? 'cellSuccess' : 'cellError';
        }
        return '';
    }

    get displayEmployeeTable(): boolean {
        return this.currentDisplayTableOption?.code === 'EMP' ?? true;
    }

    get currentDurationFilter(): RadioOption | null {
        return this.durationFilters.find((x) => x.value === this.currentDurationFilterId) || null;
    }

    get currentDisplayTableOption(): RadioOption | null {
        return this.displayTableOptions.find((x) => x.value === this.currentDisplayTableOptionId) || null;
    }

    get beginDate(): Date {
        var currentDay: number;
        if (this.currentDurationFilter?.code === '6M') {
            currentDay = 1;
        } else {
            const today = new Date();
            currentDay = today.getDate() - today.getDay();
        }
        return new Date(new Date().getFullYear(), new Date().getMonth(), currentDay);
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    get listOfPeriods(): { begin: Date; end: Date }[] {
        const result: { begin: Date; end: Date }[] = [];
        var currentDate: Date = new Date(this.beginDate);
        var end: Date;
        if (this.currentDurationFilter?.code === '6M') {
            end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, 1);
        } else {
            end = new Date(currentDate);
            end.setDate(currentDate.getDate() - currentDate.getDay() + 7 * 6);
        }
        while (currentDate < end) {
            var nextGap: Date;
            if (this.currentDurationFilter?.code === '6M') {
                nextGap = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
            } else {
                nextGap = new Date(currentDate);
                nextGap.setDate(currentDate.getDate() + 7);
            }
            result.push({
                begin: currentDate,
                end: nextGap
            });
            currentDate = nextGap;
        }
        return result;
    }

    genKey(date: Date): string {
        if (this.currentDurationFilter?.code === '6M') {
            return `M${date.getMonth() + 1}/${date.getFullYear()}`;
        } else {
            return `D${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
    }

    groupRecapsByProject(recaps: IProjectRecap[]): { [projectId: string]: IProjectRecap[] } {
        const result: { [projectId: string]: IProjectRecap[] } = {};
        recaps.map((x) => {
            const key: string = `${x.project.id}`;
            if (!Object.keys(result).includes(key)) {
                result[key] = [];
            }
            result[key].push(x);
        });
        Object.keys(result).map((x) => {
            result[x].sort((a, b) => this.compareDate(a.fromDate, b.fromDate));
        });
        return result;
    }

    compareDate(d1: Date, d2: Date): number {
        if (d1 < d2) {
            return -1;
        } else if (d1 > d2) {
            return 1;
        } else {
            return 0;
        }
    }

    get getMaxForPeriod(): number {
        return this.currentDurationFilter?.code === '6M' ? 40 : 10;
    }

    get filteredRecaps(): ICrossProjectImputationRecap[] {
        if (this.projectFilter) {
            const result: ICrossProjectImputationRecap[] = [];
            for (let recapIndex = 0; recapIndex < this.imputationRecap.length; recapIndex++) {
                const currentUserRecap = this.imputationRecap[recapIndex];
                if (
                    (this.displayEmployeeTable &&
                        this.projectFilter.employeeIds.some((x) => x === currentUserRecap.employeeId)) ||
                    (!this.displayEmployeeTable && (
                        currentUserRecap.role === null || 
                        this.projectFilter.roles.some((x) => x.id === currentUserRecap.role.id)
                    ))
                ) {
                    const toAdd: ICrossProjectImputationRecap = {
                        employeeId: currentUserRecap.employeeId,
                        role: currentUserRecap.role,
                        recaps: []
                    };
                    for (
                        let currentRecapIndex = 0;
                        currentRecapIndex < currentUserRecap.recaps.length;
                        currentRecapIndex++
                    ) {
                        const currentRecap = currentUserRecap.recaps[currentRecapIndex];
                        toAdd.recaps.push(currentRecap);
                    }
                    result.push(toAdd);
                }
            }
            return result;
        }
        return this.imputationRecap;
    }

    get tableDataForEmployeeTable(): TableRow<ICellData>[] {
        const result: TableRow<ICellData>[] = [];
        const toShow = this.filteredRecaps;
        const employeeIdsList = this.employeeFilters;
        for (let index = 0; index < employeeIdsList.length; index++) {
            const currentEmployeeId = employeeIdsList[index];
            const currentUser = toShow.find(x => x.employeeId === currentEmployeeId);
            
            const userRow: TableRow<UserRowCell> = {
                name: currentEmployeeId,
                isUserRow: true,
                role: currentUser?.role?.label ?? ''
            };
            result.push(userRow);

            if (currentUser) {
                const grouped: { [projectId: string]: IProjectRecap[] } = this.groupRecapsByProject(currentUser.recaps);
                const projectIds: string[] = Object.keys(grouped);
                for (let projectIdIndex = 0; projectIdIndex < projectIds.length; projectIdIndex++) {
                    const currentProjectId = projectIds[projectIdIndex];
                    const currentProjectRecaps = grouped[currentProjectId];
                    if (currentProjectRecaps && currentProjectRecaps.length > 0) {
                        const currentLine: TableRow<ICellData> = {
                            name: `${currentProjectRecaps[0].project.trigram}`,
                            isUserRow: false
                        };
                        currentProjectRecaps.forEach((x) => {
                            const curKey = this.genKey(x.fromDate);
                            currentLine[curKey] = { value: x.quantity };
                            if (!userRow[curKey]) {
                                const cell: UserRowCell = {
                                    value: x.quantity,
                                    date: x.fromDate,
                                    isValid: true
                                };
                                userRow[curKey] = cell;
                            } else {
                                (userRow[curKey] as UserRowCell).value += x.quantity;
                            }
                        });
                        result.push(currentLine);
                    }
                }
            }
            const userRowKeys = Object.keys(userRow)
                .filter((x) => x !== 'name' && x !== 'isUserRow' && x !== 'role');
            userRowKeys.map((x) => {
                const currentVal: UserRowCell = userRow[x] as UserRowCell;
                currentVal.isValid = currentVal.value <= this.getMaxForPeriod;
            });
            result.push({
                name: '_',
                isUserRow: false
            } as TableRow<ICellData>);
        }
        return result;
    }

    get roleFilters(): IEmployeeRole[] {
        return this.projectFilter?.roles ?? this.allExistingRoles;
    }

    get tableDataForRoleTable(): TableRow<ICellData>[] {
        const distinctRoles = this.roleFilters;
        const result: TableRow<ICellData>[] = [];
        distinctRoles.forEach(async (currentRole) => {
            let nbLines: number = 0;
            const headRow: TableRow<UserRowCell> = {
                name: currentRole.label,
                isUserRow: true
            };
            result.push(headRow);
            let recapsForRole: ICrossProjectImputationRecap[] = [];
            let selectedStudioToFilter: number = 0;
            if (!this.isAdmin && this.isStudioManager) {
                await moduleApiGraph.Client.api('/me').get().then(value => {
                    selectedStudioToFilter = value.studioId;
                });
            }
            if (this.isAdmin && this.selectedStudio != null && this.selectedStudio !== 0) {
                selectedStudioToFilter = this.selectedStudio;
            }
            if (selectedStudioToFilter !== 0) {
                recapsForRole = this.filteredRecaps.filter(x => x.role !== null && 
                    x.role.id === currentRole.id && 
                    this.allEmployees.find(e => e.id === x.employeeId && e.studioId && e.studioId === selectedStudioToFilter) !== undefined);
            }
            else {
                recapsForRole = this.filteredRecaps.filter(x => x.role !== null && x.role.id === currentRole.id);
            }
            recapsForRole.forEach((userRecap) => {
                const grouped = this.groupRecapsByProject(userRecap.recaps);
                const projectIds = Object.keys(grouped);
                projectIds.forEach((projectId) => {
                    const periods = grouped[projectId];
                    const currentLine: TableRow<ICellData> = {
                        employeeId: userRecap.employeeId,
                        name: periods[0].project.trigram,
                        isUserRow: false
                    };
                    periods.forEach((period) => {
                        const curKey = this.genKey(period.fromDate);
                        currentLine[curKey] = { value: period.quantity };
                        if (!headRow[curKey]) {
                            const cell: UserRowCell = {
                                value: period.quantity,
                                date: period.fromDate,
                                isValid: true
                            };
                            headRow[curKey] = cell;
                        } else {
                            (headRow[curKey] as UserRowCell).value += period.quantity;
                        }
                    });
                    nbLines++;
                    result.push(currentLine);
                });
            });
            const headRowKeys = Object.keys(headRow).filter((x) => x !== 'name' && x !== 'isUserRow' && x !== 'role');
            headRowKeys.map((x) => {
                const currentVal: UserRowCell = headRow[x] as UserRowCell;
                currentVal.isValid = currentVal.value <= (this.getMaxForPeriod * nbLines);
            });
            result.push({
                name: '_',
                isUserRow: false
            } as TableRow<ICellData>);
        });

        return result;
    }

    get tableData(): TableRow<ICellData>[] {
        return this.displayEmployeeTable ? this.tableDataForEmployeeTable : this.tableDataForRoleTable;
    }

    async exportToExcel(): Promise<void> {
        const today = new Date(this.beginDate);
        var limit: Date;
        if (this.currentDurationFilter?.code === '6M') {
            limit = new Date(today.getFullYear(), today.getMonth() + 6, 1);
        } else {
            limit = new Date(today);
            limit.setDate(today.getDate() - today.getDay() + 7 * 6);
        }
        const request = imputationApi.exportCrossProjectRecapBetweenDates(
            this.selectedProjectId ?? 0,
            today,
            limit,
            this.currentDurationFilter?.code === '6M' ?? true,
            this.displayEmployeeTable,
            this.projectFilter ?? {
                roles: this.roleFilters,
                employeeIds: this.employeeFilters
            }
        );
        await this.generateExcelFile(request, `Affectation_Ressources_${this.formatDate(new Date())}.xlsx`);
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateExcelFile(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.excelLoading = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.excelLoading = false;
        }
        setTimeout(() => {
            this.excelLoading = false;
        }, 1000);
    }
}
