










































import { Component, Prop, Vue } from 'vue-property-decorator';

import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';

import { IInvoice } from '@/entity/invoice/invoice';
import { IInvoiceLine } from '@/entity/invoice/invoice-line';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';

@Component
export default class InvoicePdfPage2 extends Vue {
    @Prop({ required: true }) private invoice!: IInvoice | undefined;

    get noRef(): string {
        return this.invoice?.reference ?? '';
    }

    private currentDate(): string {
        return format(new Date(), 'd MMMM Y', { locale: frenchLocale });
    }

    get missionDescription(): string {
        return this.invoice?.invoiceObject ?? '';
    }

    get invoiceDetails(): IInvoiceLine[] {
        if (this.invoice && this.invoice.invoiceLines) {
            return this.invoice?.invoiceLines;
        }
        return [];
    }

    private defaultTableNumberFormatter(value: number) {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value);
    }
    
    private defaultTableNumberPercentFormatter(value: number) {
        return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(value / 100);
    }

    private lastPercentageNumberFormatter(value: number, key, item: IInvoiceLine) {
        const lastPercentage = item.cumulatedPercentage - item.invoicedPercentage;
        return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(lastPercentage / 100);
    }

    get fieldHeaders(): IBootstrapTableColumn[] {
        return [
            { key: 'designation', label: 'Eléments de mission' },
            { key: 'totalAmountET', label: 'Montant HT', tdClass: 'text-right', formatter: this.defaultTableNumberFormatter },
            // { key: 'lastPercentage', label: '% Précédent', tdClass: 'text-right', formatter: this.lastPercentageNumberFormatter },
            { key: 'invoicedPercentage', label: '% Facturé', tdClass: 'text-right', formatter: this.defaultTableNumberPercentFormatter },
            { key: 'cumulatedPercentage', label: '% Cumul', tdClass: 'text-right', formatter: this.defaultTableNumberPercentFormatter },
            { key: 'invoicedTotalET', label: 'Facturé ce jour HT', tdClass: 'text-right', formatter: this.defaultTableNumberFormatter },
            { key: 'cumulatedAmount', label: 'Cumul Facturé HT', tdClass: 'text-right', formatter: this.defaultTableNumberFormatter },
            { key: 'comment', label: 'Texte', thStyle: 'width:200px' },
        ];
    }

    get amountTotalET(): number {
        let total: number = 0;
        if (this.invoiceDetails) {
            this.invoiceDetails.forEach(line => {
                if (line.totalAmountET) {
                    total += line.totalAmountET;
                }
            });
        }
        return total;
    }

    get amountTotalETDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.amountTotalET);
    }

    get amountTaxFree() : number {
        return this.invoice?.totalExcTax ?? 0;
    }

    get amountTaxFreeDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.amountTaxFree);
    }

    get amountTotalCumulInvoiced(): number {
        let total: number = 0;
        if (this.invoiceDetails) {
            this.invoiceDetails.forEach(line => {
                if (line.cumulatedAmount) {
                    total += line.cumulatedAmount;
                }
            });
        }
        return total;
    }

    get amountTotalCumulInvoicedDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.amountTotalCumulInvoiced);
    }

    get previousInvoiced(): number {
        return this.amountTotalCumulInvoiced - this.amountTaxFree;
    }

    get previousInvoicedDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.previousInvoiced);
    }

    get amountRevised(): number {
        return this.invoice?.invoiceLines?.find(x => x.isRevisingLine === true)?.invoicedTotalET ?? 0;
    }

    get amountRevisedDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.amountRevised);
    }
}
