



































































































import { Component, Vue } from 'vue-property-decorator';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { NU } from '@t/type';
import { vxm } from '@/store';
import { IEmployeeRole } from '@/entity/shared/referential';
import { referentialApi } from '@/wapi/referential-api';
import CurrencyInput from '@c/shared/currency-input.vue';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';

@Component({
    components: {
        CurrencyInput
    }
})
export default class ReferentialEmployeeRole extends Vue {
    employeeRoles: NU<IEmployeeRole[]> = [];
    private promiseExport: boolean = false;
    private isBusy: boolean = true;

    get isValidForm(): boolean {
        let res = true;
        this.employeeRoles?.forEach((element) => {
            res = res && element.code !== undefined && element.code.length === 3;
            res = res && element.label !== undefined && element.label !== '';
            res = res && element.rate !== undefined;
        });
        return res;
    }

    async getEmployeeRoles(): Promise<void> {
        const employeeRolesCallData = await referentialApi.getAllEmployeeRoles();
        if (isCallValidAndNotCancelled<IEmployeeRole[]>(employeeRolesCallData)) {
            if (employeeRolesCallData?.datas) {
                this.employeeRoles = employeeRolesCallData.datas;
            }
        }
    }

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: 'Code', key: 'code', thStyle: 'width:200px' },
        { label: 'Description', key: 'label' },
        { label: 'Coûts par 1/2 journée (€)', key: 'rate' },
        { label: '', key: 'delete', thStyle: 'width:80px' }
    ];

    async saveEmployeeRoles(): Promise<void> {
        const callData = await referentialApi.patchEmployeeRoles(this.employeeRoles ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.getEmployeeRoles();
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Liste des profils utilisateurs',
                variant: 'success',
                solid: true
            });
        }
    }

    addEmployeeRole(): void {
        const employeeRole = {} as IEmployeeRole;
        this.employeeRoles?.push(employeeRole);
    }

    deleteEmployeeRoleLine(item: IEmployeeRole): void {
        const index = this.employeeRoles?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.employeeRoles?.splice(index, 1);
        }
    }

    formatterCode(val: string): string {
        if (!val) {
            return val;
        }
        return val.toUpperCase().trim().substring(0, 3);
    }

    async exportCatEmployes(): Promise<void> {
        await this.generateReport(
            referentialApi.exportCatEmployesData(),
            `Liste_des_profils_utilisateurs"_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private async mounted() {
        vxm.app.changeTitleMain('Liste des profils utilisateurs');
        this.getEmployeeRoles();
        this.isBusy = false;
    }
}
