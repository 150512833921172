import { IEmployee } from '@/entity/shared/employee';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';

class EmployeeApi extends ApiBase<IEmployee> {
    constructor(urlBase: string = 'api/employees') {
        super(urlBase);
    }

    public async getEmployeesSummary(): Promise<ICancellableResult<Array<IEmployee>>> {
        return await ajaxCall.baseAjaxCall('get', this.url, { urlParameter: ['summary'] });
    }

    public async exportEmployeeData(data: (string | undefined)[], sortKeySelected: string, isSortDesc: boolean): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: 
            {
                EmployeeIdList: data,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
            },
            urlParameter: ['exportemployeedata'],
            responseType: 'blob'
        });
    }

    public async getEmployeeNameActive(): Promise<ICancellableResult<Array<IEmployee>>> {
        return await ajaxCall.baseAjaxCall('get', this.url, { urlParameter: ['nameActive'] });
    }
}

export const employeeApi: EmployeeApi = new EmployeeApi();
