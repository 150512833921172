import { IEmployeeRole } from '@/entity/shared/referential';

const FILTER_CACHE_FIELD: string = 'FILTER_CACHE_ITEM';

export interface IFilterCache {
    roles: IEmployeeRole[];
    employeeIds: string[];
}

class FilterCacheService {
    getFilters(): IFilterCache | null {
        const filterCache: string | null = localStorage.getItem(FILTER_CACHE_FIELD);
        if (filterCache !== null && filterCache !== '') {
            const objectFilter: IFilterCache = JSON.parse(filterCache);
            return objectFilter ?? null;
        }
        return null;
    }

    saveFilters(roles: IEmployeeRole[], employeeIds: string[]): IFilterCache {
        const current: IFilterCache = {
            employeeIds: employeeIds,
            roles: roles
        };
        localStorage.setItem(FILTER_CACHE_FIELD, JSON.stringify(current));
        return current;
    }
}

export const filterCacheService = new FilterCacheService();
