




















import Vue from 'vue';
import { Component, Prop, VModel, Watch } from 'vue-property-decorator';

@Component
export default class InputElement extends Vue {
    @Prop({ required: true }) inputId!: string;
    @VModel({ required: true }) data!: string;
    @Prop({ required: true }) label!: string;
    @Prop({}) stateContext!: any;
    @Prop({}) maxCharacter!: number;
    @Prop({ default: false }) readOnly!: boolean;
    @Prop({}) preValue!: string;

    private classList = '';
    private preValueWidth: string = '0.75rem';
    private moveLabel = false;

    mounted(): void {
        if ((this.data && this.data.length > 0) || (this.preValue && this.preValue.length > 0)) {
            this.moveLabel = true;
        }
    }

    get preValueExist(): boolean {
        return this.preValue !== null && this.preValue !== undefined && this.preValue.length > 0;
    }

    @Watch('preValue', { immediate: true })
    updatePreValue(newVal: string, _oldVal: string): void {
        this.$nextTick(() => {
            if (newVal && newVal.length > 0) {
                const calculateSize = this.$refs['calculate-size'] as HTMLElement;
                const rawSize = this.$refs['raw-size'] as HTMLElement;
                const width = calculateSize.clientWidth - rawSize.clientWidth;
                this.preValueWidth = '' + width;
                if (this.data.startsWith(newVal)) {
                    this.data = this.data.replace(newVal + '_', '');
                }
            }

            if ((this.data && this.data.length > 0) || (newVal && newVal.length > 0)) {
                this.moveLabel = true;
            } else {
                this.moveLabel = false;
            }
        });
    }

    onInput = (value: string): void => {
        this.$emit('input', value);
    };

    onFocus(ev: FocusEvent): void {
        this.moveLabel = true;
    }

    onBlur(ev: FocusEvent): void {
        if ((!this.data || this.data.length === 0) && !this.preValueExist) this.moveLabel = false;
    }

    getValidationState(p: { dirty: boolean; validated: boolean; valid: boolean | null }): boolean | null {
        if (p == null) return null;
        return p.dirty || p.validated ? p.valid : null;
    }
}
