
























import { IContractLine } from '@/entity/contract/contract-line';
import { IProjectComment } from '@/entity/project/project-comment';
import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({})
export default class CommentForm extends Vue {
    @Prop({
        required: true
    })
    private comment!: IProjectComment;

    @Prop({ required: true })
    private contractLines!: IContractLine[];

    get contractLineOptions(): {id: number, code: string, label: string }[] {
        return this.contractLines.map((x, index) => {
            return {
                id: x.id,
                code: `CL${index}`,
                label: `${x.designation}`
            };
        });
    }

    get isSubmittable(): boolean {
        return this.comment!.content !== '';
    }

    onSave(): void {
        if (this.isSubmittable) {
            this.$emit('comment-on-save', this.comment);
        }
    }
}
