












import { IContractLine } from '@/entity/contract/contract-line';
import { IProject } from '@/entity/project/project';
import { Component, Vue, Prop } from 'vue-property-decorator';
import ImputationContractLineItem from './imputation-contract-line-item.vue';
@Component({
    components: {
        ImputationContractLineItem
    }
})
export default class ImputationList extends Vue {
    @Prop({ required: true })
    private projects!: IProject[];
    
    @Prop({ required: true })
    private selectedDate!: Date;

    @Prop({ required: true })
    private readonly!: boolean;

    get contractLines(): IContractLine[] {
        const result: IContractLine[] = [];
        for (let p = 0; p < this.projects.length; p++) {
            const project = this.projects[p];
            for (let c = 0; c < project.contracts!.length; c++) {
                const contract = project.contracts![c];
                for (let l = 0; l < contract.contractLines!.length; l++) {
                    result.push(contract.contractLines![l]);
                }
            }
        }
        return result;  
    }
}
