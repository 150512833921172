




















































































































import { Component, Vue } from 'vue-property-decorator';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { NU } from '@t/type';
import { vxm } from '@/store';
import { IBank } from '@/entity/shared/referential';
import { IAgency } from '@/entity/project/agency';
import { referentialApi } from '@/wapi/referential-api';
import { agencyApi } from '@/wapi/agency-api';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';

@Component({})
export default class ReferentialBank extends Vue {
    banks: NU<IBank[]> = [];
    agencies: NU<IAgency[]> = [];
    private promiseExport: boolean = false;
    private isBusy: boolean = true;

    get isValidForm(): boolean {
        let res = true;
        this.banks?.forEach((element) => {
            res = res && element.agencyId !== undefined && element.agencyId !== 0;
            res = res && element.code !== undefined && element.code.length === 3;
            res = res && element.label !== undefined && element.label !== '';
            res = res && element.iban !== undefined && element.iban.length === 33;
            res = res && element.bic !== undefined && element.bic !== '';
        });
        return res;
    }

    async getBanks(): Promise<void> {
        const banksCallData = await referentialApi.getAllBanks();
        if (isCallValidAndNotCancelled<IBank[]>(banksCallData)) {
            if (banksCallData?.datas) {
                this.banks = banksCallData.datas;
            }
        }
        const agencyCallData = await agencyApi.getAllBase();

        if (isCallValidAndNotCancelled<IAgency[]>(agencyCallData)) {
            this.agencies = agencyCallData?.datas;
        }
        // this.isBusy = false;
    }

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: 'Société', key: 'agencyId', thStyle: 'width:230px' },
        { label: 'Code', key: 'code', thStyle: 'width:150px' },
        { label: 'Description', key: 'label' },
        { label: 'IBAN', key: 'iban' },
        { label: 'BIC', key: 'bic' },
        { label: '', key: 'delete', thStyle: 'width:80px' }
    ];

    async saveBanks(): Promise<void> {
        const callData = await referentialApi.patchBanks(this.banks ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.getBanks();
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Liste de banques',
                variant: 'success',
                solid: true
            });
        }
    }

    async exportReferBank(): Promise<void> {
        await this.generateReport(
            referentialApi.exportReferBankData(),
            `Liste_des_banques_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    addBank(): void {
        const bank = {} as IBank;
        this.banks?.push(bank);
    }

    deleteBankLine(item: IBank): void {
        const index = this.banks?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.banks?.splice(index, 1);
        }
    }

    formatterIBAN(val: string): string {
        if (!val) {
            return val;
        }
        return val
            .toUpperCase()
            .replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim()
            .substring(0, 33);
    }

    formatterCode(val: string): string {
        if (!val) {
            return val;
        }
        return val.toUpperCase().trim().substring(0, 3);
    }

    private async mounted() {
        vxm.app.changeTitleMain('Liste des banques');
        this.getBanks();
        this.isBusy = false;
    }
}
