





























































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';

import { vxm } from '@/store';
import { clientApi } from '@/wapi/client-api';
import { NumberToWord } from '@t/number-word';

import { IClientAddress } from '@/entity/shared/client-address';
import { IContract } from '@/entity/contract/contract';
import { IClient } from '@/entity/shared/client';
import { IInvoice } from '@/entity/invoice/invoice';
import { IModelInvoice } from '@/entity/pdf/pdf-model-invoice';
import { ISignature } from '@/entity/pdf/pdf-signature';
import { IBank } from '@/entity/shared/referential';
import { agencyApi } from '@/wapi/agency-api';
import { IAgency } from '@/entity/project/agency';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';

@Component
export default class InvoicePdfPage1 extends Vue {
    @Prop({ required: true, type: String }) private senderEmail!: string;
    @Prop({ required: true }) private invoice!: IInvoice | undefined;
    @Prop({ required: true }) private clientContact!: string;
    @Prop({ required: true }) private optionInput!: string;
    @Prop({ required: true }) private selectedModel!: IModelInvoice | null;
    @Prop({ required: true }) private selectedSignature!: ISignature | null;

    private clients: IClient[] = [];

    get clientName(): string {
        return this.client?.label ?? '';
    }

    get clientAddress(): IClientAddress | undefined {
        const facturationType = vxm.referential.thirdPartyAddressType?.find((at) => at.code === 'FAC');
        if (facturationType && this.client && this.client.clientAddresses && this.client.clientAddresses.length > 0) {
            const addr = this.client.clientAddresses.find((a) => a.thirdPartyAddressTypeId === facturationType.id);
            return addr;
        }
    }

    get clientAddressStreet(): string {
        if (this.clientAddress) {
            return this.clientAddress.label!.replaceAll(String.fromCharCode(10), '<br />') ?? '';
        }
        return '';
    }

    get clientAddressCity(): string {
        if (this.clientAddress) {
            return this.clientAddress.city ?? '';
        }
        return '';
    }

    get clientAddressPostalCode(): string {
        if (this.clientAddress) {
            return this.clientAddress.postalCode ?? '';
        }
        return '';
    }

    get client(): IClient | undefined {
        if (this.contract && this.contract.clientId) {
            return this.clients.find((c) => c.id === this.contract?.clientId);
        }
    }

    get isComptability(): boolean {
        return this.selectedSignature?.id === 1;
    }

    get contract(): IContract | undefined {
        return vxm.project.contractsList?.find((c) => c.id === this.invoice?.contractId);
    }

    get noRef(): string {
        return this.invoice?.reference ?? '';
    }

    private currentDate(): string {
        return format(new Date(this.invoice!.date!), 'd MMMM Y', { locale: frenchLocale });
    }

    get missionDescription(): string {
        return this.invoice?.invoiceObject?.replaceAll(String.fromCharCode(10), '<br />') ?? '';
    }

    get typePayment(): string {
        if (this.selectedModel) {
            return this.optionInput ?? '';
        }
        return '';
    }

    get selectedModelCode(): string {
        return this.selectedModel ? this.selectedModel.code : '';
    }

    get displayTaxes(): boolean {
        return this.selectedModelCode !== 'NNT';
    }

    get signName(): string {
        return this.selectedSignature ? this.selectedSignature.text : '';
    }

    get signImg(): any {
        return this.selectedSignature ? this.selectedSignature.img : null;
    }

    get amountTaxFree(): number {
        return this.invoice?.totalExcTax ?? 0;
    }

    get amountTaxFreeDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.amountTaxFree);
    }

    get amountTVA(): number {
        return this.invoice?.tax ?? 0;
    }

    get toAttentionOf(): string {
        return this.invoice?.toAttentionOf ?? '';
    } 

    get amountTVADisplay(): string {
        const amountTVAPerc = this.amountTVA / 100;
        return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(amountTVAPerc);
    }

    get amountTVACalc(): number {
        return (this.amountTaxFree * this.amountTVA) / 100;
    }

    get amountTVACalcDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.amountTVACalc);
    }

    get amountTotal(): number {
        return this.invoice?.total ?? 0;
    }

    get amountTotalDisplay(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.amountTotal);
    }

    get amountText(): string {
        return NumberToWord(this.amountTotal);
    }

    get paymentOrder(): string {
        // return this.agency?.label ?? 'Richez_Associés';
        return 'Richez_Associés';
    }

    get bank(): IBank | undefined {
        const banks = vxm.referential.banks;
        if (this.invoice && this.invoice.contract) {
            return banks?.find((b) => b.id === this.invoice?.contract?.bankId);
        }
    }

    get bankAccount(): string {
        if (this.bank) {
            return this.bank.label + ' ' + this.changeTextViewIBAN(this.bank.iban);
        }
        return '';
    }

    private setIban(value: string) {
        this.$emit('change:iban', value);
    }

    private changeTextViewIBAN(iban: string): string {
        iban = iban.substring(17).replaceAll(' ', ''); 
        var res = iban.substring(0, 11) + ' ' + iban.substring(11);
        return res;
    }

    private agencies: IAgency[] = [];
    get agency(): IAgency | undefined {
        if (this.invoice && this.invoice.contract) {
            return this.agencies.find((a) => a.id === this.invoice?.contract?.agencyId);
        }
    }

    async mounted(): Promise<void> {
        const callClient = await clientApi.getAllByCode('CLT');
        if (callClient && callClient.datas && !callClient.cancelled) {
            this.clients = callClient.datas;
        }
        const agencyCallData = await agencyApi.getAllBase();
        if (isCallValidAndNotCancelled<IAgency[]>(agencyCallData)) {
            if (agencyCallData?.datas) {
                this.agencies = agencyCallData?.datas;
            }
        }
        this.setIban(this.bankAccount);
    }
}
