import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { IEmployee } from '@/entity/shared/employee';
import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import ApiBase from './api-base';

class PlanificationApi extends ApiBase<IPlanificationPhase> {
    /**
     *
     */
    constructor(urlBase: string = 'api/planification') {
        super(urlBase);
    }

    public async getAllPhasesByProjectId(projectId: number): Promise<ICancellableResult<IPlanificationPhase[]>> {
        return await ajaxCall.baseAjaxCall<IPlanificationPhase[]>('get', this.url, {
            urlParameter: ['planificationphases'],
            queryParameter: { projectId: projectId + '' }
        });
    }

    public async getEmployeeHistorization(id: string): Promise<ICancellableResult<IEmployee>> {
        return await ajaxCall.baseAjaxCall<IEmployee, string>('get', this.url, { urlParameter: ['employee', id, 'historization'] });
    }

    public async patchPhases(data: IPlanificationPhase[]): Promise<ICancellableResult<boolean>> {
        return await ajaxCall.baseAjaxCall<boolean, IPlanificationPhase[]>('patch', this.url, { urlParameter: ['patchphases'], payload: data });
    }

    public async exportFeeData(data: number[], idProject: number, sortKeySelected: string, isSortDesc: boolean): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, any>('post', this.url, {
            payload: {
                FeeIdList: data,
                ProjectId: idProject,
                SortKey: sortKeySelected,
                isSortKeyDesc: isSortDesc,
            },
            urlParameter: ['exportFeeData'],
            responseType: 'blob'
        });
    }
}

export const planificationApi: PlanificationApi = new PlanificationApi();
