




































































































































































































































import { ICreateProject } from '@/entity/project/create-project';
import { projectApi } from '@api/project-api';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { IProject } from '@/entity/project/project';
import { IClient } from '@/entity/shared/client';
import { IAgency } from '@/entity/project/agency';
import InputElement from '@c/shared/input-element.vue';
import { MgtPerson } from '@microsoft/mgt';
import { vxm } from '@/store';
import { appTokenMgr, IEmployeeAppRoleManager } from '@t/employee-app-role';
import { authModule } from '@t/session';
import { NU } from '@t/type';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { ICancellableResult } from '@t/ajax-wrapper';
import { IStudio } from '@/entity/project/studio';

@Component({
    components: {
        InputElement
    }
})
export default class ProjectList extends Vue {
    private filteredDataAllColumns: string = '';
    private trigram: string = '';
    private designation: string = '';
    private isInternalBusiness: boolean = false;
    private columnPlaceholder: string = 'Nouvelle colonne';
    private fieldSelected: string = '';
    private isBusy: boolean = true;
    // private projectsPerPage: number = 5000;
    private projectsCurrentPage: number = 1;
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private isTrigramValid: boolean = true;
    private checkingTrigram: boolean = false;
    private projects: IProject[] = [];
    private filteredProjects: IProject[] = [];
    private clients: IClient[] = [];
    private agencies: IAgency[] = [];
    private promiseExport: boolean = false;
    private selectedFieldToAdd: NU<IBootstrapTableColumn> = null;
    private graphManagers: { [key: string]: string } = {}
    private addedAdditionalFields: IBootstrapTableColumn[] = [];
    private additionalFields: Array<IBootstrapTableColumn> = [
        { label: 'Affaire Interne', key: 'isInternalBusiness', sortable: true },
        {
            label: 'Date de Début',
            key: 'openingDate',
            sortable: true,
            formatter: (val: Date, key?: string, item?) => new Date(val).toLocaleDateString('fr-FR')
        },
        {
            label: 'Date de fin',
            key: 'closingDate',
            sortable: true,
            formatter: (val: Date, key?: string, item?) => new Date(val).toLocaleDateString('fr-FR')
        },
        { label: 'Mandataire', key: 'isDelegate', sortable: true },
        { label: 'Soldé', key: 'isSale', sortable: true },
        {
            label: 'Studio',
            key: 'studio',
            sortable: true,
            formatter: (val: IStudio, key?: string, item?) => (val ? (val.code ? val.code : ' ') : ' ')
        }
    ];

    private baseFields: Array<IBootstrapTableColumn | string> = [
        { label: 'Trigramme', key: 'trigram', sortable: true, thStyle: 'width:100px', tdClass: 'padding-left-td' },
        { label: 'Désignation', key: 'designation', sortable: true },
        {
            label: 'Client',
            key: 'clients',
            sortable: true,
            formatter: (val: IClient[], key?: string, item?) => val.map((x) => x.label).join(', ')
        },
        {
            label: 'Société(s)',
            key: 'agencies',
            sortable: true,
            formatter: (val: IAgency[], key?: string, item?) => val.map((x) => x.label).join(', ')
        },
        {
            label: 'Responsable Studio',
            key: 'studioManagerId',
            sortable: true,
            formatter: (v, _field, _object) => {
                const element: MgtPerson = this.$refs['studioManager' + v] as MgtPerson;
                if (element && element.id) {
                    if (element.personDetails?.displayName) {
                        return element.personDetails?.displayName?.toLowerCase();
                    }
                }
                return '';
            },
            filterByFormatted: true
        }
    ];

    private onFiltered(filteredItems: IProject[]) {
        this.filteredProjects = filteredItems;
    }

    private editProject(item: IProject, _index: number, _event: Event): void {
        const acc = authModule.getAccount();
        if (
            appTokenMgr.isAdmin(acc) ||
            appTokenMgr.isStudioManager(acc) ||
            appTokenMgr.isCP(acc) ||
            appTokenMgr.isDaf(acc) ||
            appTokenMgr.isAssociate(acc)
        ) {
            vxm.project.resetProject();
            this.$router.push({ name: 'project-information', params: { id: item.id + '' } });
        }
    }

    get appTokenMgr(): IEmployeeAppRoleManager {
        return appTokenMgr;
    }

    get fields(): Array<IBootstrapTableColumn | string> {
        if (this.addedAdditionalFields) {
            return this.baseFields.concat(this.addedAdditionalFields);
        }
        return this.baseFields;
    }

    get additionalFieldChoice(): Array<IBootstrapTableColumn> {
        return this.additionalFields.filter(
            (x) => this.addedAdditionalFields.map((y) => y.key).find((z) => z === x.key) === undefined
        );
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        var b0, a0;
        var a, b;
        if (key === 'trigram' || key === 'designation') {
            const a = itemA[key];
            const b = itemB[key];
            return this.strcmp(a.toUpperCase(), b.toUpperCase());
        } else if (key === 'clients' || key === 'agencies') {
            a = itemA[key];
            b = itemB[key];
            if (a.length === 0) {
                a0 = '';
            } else if (a[0].label === undefined) {
                a0 = '';
            } else a0 = a[0].label;
            if (b.length === 0) {
                b0 = '';
            } else if (b[0].label === undefined) {
                b0 = '';
            } else b0 = b[0].label;
            return this.strcmp(a0, b0);
        } else if (key === 'studioManagerId') {
            a = itemA[key];
            if (this.graphManagers[a] === '') {
                const elementA: MgtPerson = this.$refs['studioManager' + a] as MgtPerson;
                this.graphManagers[a] = elementA && elementA.id ? elementA.personDetails?.displayName ?? 'zzzzzzzzzzzz' : '';
            }
            if (this.graphManagers[a]) {
                a0 = this.graphManagers[a].toLowerCase();
            }
            b = itemB[key];
            if (this.graphManagers[b] === '') {
                const elementB: MgtPerson = this.$refs['studioManager' + b] as MgtPerson;
                this.graphManagers[b] = elementB && elementB.id ? elementB.personDetails?.displayName ?? 'zzzzzzzzzzzz' : '';  
            }
            if (this.graphManagers[b]) {
                b0 = this.graphManagers[b].toLowerCase();
            }
            if (a0 === undefined) a0 = '';
            if (b0 === undefined) b0 = '';
            return this.strcmp(a0.toUpperCase(), b0.toUpperCase());
        }
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    get totalProjects(): number {
        if (this.filteredProjects && this.filteredProjects.length !== this.projects.length) {
            return this.filteredProjects.length;
        }
        return this.projects.length;
    }

    private colors: string[] = [
        'rgba(255, 0, 0, 0.5)',
        'rgba(0, 255, 0, 0.5)',
        'rgba(0, 0, 255, 0.5)',
        'rgba(0, 255, 255, 0.5)',
        'rgba(255, 0, 255, 0.5)'
    ];
    
    private async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Projets');
        vxm.app.changeTitleExt('Liste des projets'); 
        const call = await projectApi.getAllBase();
        if (call != null && call.cancelled === false && !call.error && call.datas != null) {
            for (let i = 0; i < call.datas.length; ++i) {
                if (call.datas[i].studioManagerId && this.graphManagers[call.datas[i].studioManagerId!] === undefined) {
                    this.graphManagers[call.datas[i].studioManagerId!] = '';
                }   
                (call.datas[i] as any).state = this.colors[i % this.colors.length];
            }
            this.projects = call.datas;
            this.filteredProjects = call.datas;
        }
        this.isBusy = false;
    }

    @Watch('trigram')
    public async checkTrigram(newVal: string): Promise<void> {
        if (newVal && newVal.length === 3) {
            this.checkingTrigram = true;
            const result = await projectApi.checkTrigram(newVal);
            this.checkingTrigram = false;
            if (result.cancelled === false && result.datas != null) {
                this.isTrigramValid = result.datas;
            }
        } else {
            this.isTrigramValid = true;
        }
    }

    public showModal(): void {
        (this.$refs['create-project-modal'] as any).show();
    }

    async exportProjects(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const listIds = 
            this.filteredProjects && this.filteredProjects.length !== this.projects.length
                ? this.filteredProjects.map((x) => x.id)
                : this.filteredDataByColumn && this.filteredDataByColumn.length !== this.projects.length
                    ? this.filteredDataByColumn.map((x) => x.id) 
                    : this.projects.map((x) => x.id);
        const listAdds = this.addedAdditionalFields.map((x) => x.label);
        await this.generateReport(
            projectApi.exportProjectsData(
                listIds,
                listAdds as any,
                sortKeySelected as string,
                sortKeyDescSelected as any
            ),
            `Liste_des_projets_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    @Watch('selectedFieldToAdd')
    public addColumn(newVal: string, _oldVal: string): void {
        const fields = this.additionalFields.find((x) => x.label === newVal);
        if (fields) {
            this.addedAdditionalFields.push(fields);
        }
    }

    public deleteColumn(key: string): void {
        const fields = this.addedAdditionalFields.findIndex(
            (x: IBootstrapTableColumn | string) => (x as IBootstrapTableColumn).key === key
        );
        if (fields >= 0) {
            this.listOfFilters[this.addedAdditionalFields[fields].key!].value = '';
            this.addedAdditionalFields.splice(fields, 1);
        }
    }

    public async createProject(): Promise<void> {
        const result = await projectApi.createProject({
            trigram: this.trigram,
            designation: this.designation,
            isInternalBusiness: this.isInternalBusiness
        } as ICreateProject);
        if (result.cancelled === false && !result.error && result.datas) {
            await this.$router.push({ name: 'project-information', params: { id: result.datas } });
        }
    }

    private listOfFilters: any = {
        trigram: {
            columnName: 'trigram',
            value: ''
        },
        designation: {
            columnName: 'designation',
            value: ''
        },
        clients: {
            columnName: 'clients',
            value: ''
        },
        agencies: {
            columnName: 'agencies',
            value: ''
        },
        studioManagerId: {
            columnName: 'studioManagerId',
            value: ''
        },
        openingDate: {
            columnName: 'openingDate',
            value: ''
        },
        closingDate: {
            columnName: 'closingDate',
            value: ''
        },
        isDelegate: {
            columnName: 'isDelegate',
            value: ''
        },
        isSale: {
            columnName: 'isSale',
            value: ''
        },
        isInternalBusiness: {
            columnName: 'isInternalBusiness',
            value: ''
        },
        studio: {
            columnName: 'studio',
            value: ''
        }
    };

    get filteredDataByColumn(): IProject[] {
        const keys = Object.keys(this.listOfFilters);
        const filtersApplied = keys.filter((x) => this.listOfFilters[x].value.length > 0);
        if (filtersApplied.length > 0) {
            this.filteredProjects = this.projects.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
            return this.projects.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        }
        this.filteredProjects = this.projects.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        return this.projects;
    }

    public filterListByColumn(key: string, x: IProject): boolean {
        if (key === 'trigram') {
            return x.trigram.toUpperCase().includes(this.listOfFilters.trigram.value.toUpperCase());
        } else if (key === 'designation') {
            return x.designation.toLowerCase().includes(this.listOfFilters.designation.value.toLowerCase());
        } else if (key === 'clients') {
            return x.clients ? x.clients.some((c) => c.label ? c.label.toUpperCase().includes(this.listOfFilters.clients.value.toUpperCase()) : false) : false;
        } else if (key === 'agencies') {
            return x.agencies ? x.agencies.some((a) => a.label ? a.label.toUpperCase().includes(this.listOfFilters.agencies.value.toUpperCase()) : false) : false;
        } else if (key === 'studioManagerId') {
            if (x.studioManagerId) {
                var name;
                var a = x.studioManagerId;
                const elementA: MgtPerson = this.$refs['studioManager' + a] as MgtPerson;
                if (elementA && elementA.id) {
                    if (elementA.personDetails?.displayName) {
                        name = elementA.personDetails?.displayName?.toLowerCase();
                    } else return false;
                } else return false;
                return name.toUpperCase().includes(this.listOfFilters.studioManagerId.value.toUpperCase());
            } else return false;
        } else if (key === 'openingDate') {
            return (x.openingDate) ? new Date(x.openingDate).toLocaleDateString('fr-FR').includes(this.listOfFilters.openingDate.value) : false;
        } else if (key === 'closingDate') {
            return (x.closingDate) ? new Date(x.closingDate).toLocaleDateString('fr-FR').includes(this.listOfFilters.closingDate.value) : false;
        } else if (key === 'isDelegate') {
            var isDelegate = 'OUI';
            var noIsDelegate = 'NON';
            return (x.isDelegate) ? isDelegate.includes(this.listOfFilters.isDelegate.value.toUpperCase()) : noIsDelegate.includes(this.listOfFilters.isDelegate.value.toUpperCase());
        } else if (key === 'isSale') {
            var IsSale = 'OUI';
            var noIsSale = 'NON';
            return (x.isSale) ? IsSale.includes(this.listOfFilters.isSale.value.toUpperCase()) : noIsSale.includes(this.listOfFilters.isSale.value.toUpperCase());
        } else if (key === 'isInternalBusiness') {
            var IsInternalBusiness = 'OUI';
            var NoIsInternalBusiness = 'NON';
            return (x.isInternalBusiness) ? IsInternalBusiness.includes(this.listOfFilters.isInternalBusiness.value.toUpperCase()) : NoIsInternalBusiness.includes(this.listOfFilters.isInternalBusiness.value.toUpperCase());
        } else if (key === 'studio') {
            return (x.studio) ? (x.studio.code) ? x.studio.code.toUpperCase().includes(this.listOfFilters.studio.value.toUpperCase()) : false : false;
        } else {
            return false;
        }
    }
}
