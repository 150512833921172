






import { Component, Vue } from 'vue-property-decorator';
import ReportingInformation from '@/components/reporting/reporting-information.vue';

@Component({
    components: {
        ReportingInformation
    }
})
export default class ReportingBase extends Vue {

}
