








































































































import { Component, Vue } from 'vue-property-decorator';
import { supplierApi } from '@api/supplier-api';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { ICancellableResult } from '@t/ajax-wrapper';
import { ISupplier } from '@/entity/shared/supplier';
import { ISupplierAddress } from '@/entity/shared/supplier-address';
import InputElement from '@c/shared/input-element.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { vxm } from '@/store';
import { moduleApiGraph } from '@t/module-api-graph';
import ContactGroupForList from '@c/shared/contact-group-for-list.vue';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
interface IDisplayNameForId {
    [id: number]: string;
}

@Component({
    components: {
        InputElement,
        ValidationProvider,
        ValidationObserver,
        ContactGroupForList
    }
})
export default class SupplierList extends Vue {
    private dictDisplayNames: IDisplayNameForId = {};
    private filteredDataAllColumns: string = '';
    private isBusy: boolean = true;
    private suppliersPerPage: number = 50;
    private suppliersCurrentPage: number = 1;
    private promiseExport: boolean = false;
    private filteredSuppliers: ISupplier[] = [];
    private suppliers: ISupplier[] = [];
    private addresses: ISupplierAddress[] = [];
    private code: string = 'SPL';
    private mySortBy: string = '';
    private mySortDesc: boolean = false;

    private supplierFields: Array<IBootstrapTableColumn | string> = [
        { label: '', key: 'dummy', sortable: false },
        { label: 'Nom du Fournisseur', key: 'label', sortable: true },
        { label: 'Ligne Directe', key: 'directLine', sortable: true },
        { label: 'Adresse Mail', key: 'mail', sortable: true },
        { label: 'Site Web', key: 'webSite', sortable: true },
        { label: 'Contacts', key: 'users', sortable: false }
    ];

    private listOfFilters: any = {
        dummy: {
            columnName: 'dummy',
            value: ''
        },
        label: {
            columnName: 'label',
            value: ''
        },
        directLine: {
            columnName: 'directLine',
            value: ''
        },
        mail: {
            columnName: 'mail',
            value: ''
        },
        webSite: {
            columnName: 'webSite',
            value: ''
        },
        users: {
            columnName: 'users',
            value: ''
        }
    };

    get totalSuppliers(): number {
        if (this.filteredSuppliers && this.filteredSuppliers.length !== this.suppliers.length) {
            return this.filteredSuppliers.length;
        }
        return this.suppliers.length;
    }

    get fields(): Array<IBootstrapTableColumn | string> {
        return this.supplierFields;
    }

    get filteredDataByColumn(): ISupplier[] {
        const keys = Object.keys(this.listOfFilters);
        const filtersApplied = keys.filter((x) => this.listOfFilters[x].value.length > 0);
        if (filtersApplied.length > 0) {
            return this.suppliers.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        }
        return this.suppliers;
    }

    private async getAdGroupId(supplierLabel: string): Promise<string> {
        const group = await moduleApiGraph.Client.api(
            "/groups/?$filter=displayName+eq+'Ind." + supplierLabel + "'"
        ).get();
        if (group && group.value && group.value.length > 0) {
            return group.value[0].id;
        }
        return '';
    }

    async exportListSupplier(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const listSupplierIds =
            this.filteredSuppliers && this.filteredSuppliers.length !== this.suppliers.length
                ? this.filteredSuppliers.map((x) => x.id)
                : this.filteredDataByColumn && this.filteredDataByColumn.length !== this.suppliers.length
                    ? this.filteredDataByColumn.map((x) => x.id)
                    : this.suppliers.map((x) => x.id);
        await this.generateReport(
            supplierApi.exportSupplierData(
                listSupplierIds as any,
                sortKeySelected as string,
                sortKeyDescSelected as any
            ),
            `Liste_des_fournisseurs_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        var a, b;
        if (key === 'label' || key === 'mail' || key === 'webSite') {
            a = itemA[key];
            b = itemB[key];
            if (a === null) a = '';
            if (b === null) b = '';
            return this.strcmp(a.toUpperCase(), b.toUpperCase());
        } 
        if (key === 'directLine')
        {
            a = itemA[key];
            b = itemB[key];
            if (a === null) a = '';
            if (b === null) b = '';
            return this.strcmp(a.replaceAll(' ', '').replaceAll('.', ''), b.replaceAll(' ', '').replaceAll('.', ''));
        }
    }

    private async checkGroupExistence(): Promise<void> {
        try {
            for (let i = 0; i < this.filteredSuppliers.length; i++) {
                const res = await this.getAdGroupId(this.filteredSuppliers[i].label as string);
                if (res && res.length > 0) {
                    // push guid en base ??
                    this.filteredSuppliers[i].azureId = res;
                    await supplierApi.patchBase(this.filteredSuppliers[i].id + '', this.filteredSuppliers[i]);
                } else {
                    // creation du groupe
                    await supplierApi.createSupplier(this.filteredSuppliers[i]);
                }
            }
        } catch (error) {}
    }

    private onFiltered(filteredItems: ISupplier[]) {
        this.filteredSuppliers = filteredItems;
    }

    private editSupplier(item: ISupplier, _index: number, _event: Event): void {
        this.$router.push({ name: 'supplier-information', params: { id: item.id + '' } });
    }

    private createSupplierPage(): void {
        this.$router.push({ name: 'supplier-information', params: { id: '0' } });
    }

    private async mounted(): Promise<void> {
        vxm.app.changeTitleMain('Fournisseurs');
        vxm.app.changeTitleExt('Liste des fournisseurs');
        if (this.code === undefined || this.code === null) {
            this.code = 'SPL';
        }
        const call = await supplierApi.getAllByCode(this.code);
        if (call != null && call.cancelled === false && !call.error && call.datas != null) {
            this.suppliers = call.datas;
            this.filteredSuppliers = call.datas;
            /// A ENLEVER LES COMMENTAIRES lorsqu'il sera possible et utile d'utiliser Graph d'azure AD 
            /* for (let i = 0; i < this.filteredSuppliers.length; i++) {
                try {
                    const res = await this.getAdGroupId(this.filteredSuppliers[i].label as string);
                    if (res && res.length > 0) {
                        const urlGraph = '/groups/' + res + '/members?$select=id,displayName,mail';
                        const graphData = await moduleApiGraph.Client.api(urlGraph).get();
                        if (graphData.value && graphData.value.length > 0) {
                            this.dictDisplayNames[this.filteredSuppliers[i].id!] = graphData.value
                                .map((x) => x.displayName)
                                .reduce((a, b) => a + b);
                        }
                    }
                } catch {}
            } */
        }
        this.isBusy = false;
    }

    public filterListByColumn(key: string, x: ISupplier): boolean {
        if (key === 'label' && this.listOfFilters.label.value != null) {
            return x.label ? x.label.toUpperCase().includes(this.listOfFilters.label.value.toUpperCase()) : false;
        } else if (key === 'directLine' && this.listOfFilters.directline === undefined) {
            return x.directLine ? x.directLine.replaceAll(' ', '').replaceAll('.', '').includes(this.listOfFilters.directLine.value.replaceAll(' ', '').replaceAll('.', '')) : false;
        } else if (key === 'mail' && this.listOfFilters.mail.value != null) {
            return x.mail ? x.mail.toUpperCase().includes(this.listOfFilters.mail.value.toUpperCase()) : false;
        } else if (key === 'webSite' && this.listOfFilters.webSite.value != null) {
            return x.webSite ? x.webSite.toUpperCase().includes(this.listOfFilters.webSite.value.toUpperCase()) : false;
        } else if (key === 'users') {
            var displayNames = this.dictDisplayNames[x.id!];
            if (displayNames) {
                return displayNames.toUpperCase().includes(this.listOfFilters.users.value.toUpperCase());
            } else return false;
        } else if (key === 'dummy') {
            return false;
        } else return false;
    }
}
