











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ImputationGridRecap extends Vue {
    @Prop({ required: true })
    private sums!: number[];

    @Prop({ required: true })
    private error!: boolean[];

    @Prop({ required: true })
    private cellsToDisplay!: string[];

    @Prop({ required: true })
    private numberToDisplay!: number[];

    getClass(display: boolean, day: string, val: number) {
        return display ? 'sum-format error' : val === -1 
            ? 'sum-format color-dark-grey' : day === 'dim.' || day === 'sam.' 
                ? 'sum-format color-grey' : 'sum-format color-light-grey';
    } 
}
