



















































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import RHBaseClass from '../rh-class-base.vue';
import { vxm } from '@/store';
import { workflowApi } from '@api/workflow-api';
import { overTimeApi } from '@api/over-time-api';
import { IOverTimeValidate, IOverTimeWorkflowItem, IWorkflow, IWorkflowValidator } from '@/entity/rh/workflow';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import InputElementMgt from '@c/shared/input-element-mgt.vue';
import { authModule } from '@t/session';
import { moduleApiGraph } from '@t/module-api-graph';
import { format, max, min } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { IProject } from '@/entity/project/project';
import { projectApi } from '@/wapi/project-api';
import { ISelectListOption } from '@/entity/shared/select-list-option';

interface IUnique {
    id?: string;
    uniqueValue: number;
    defaultId?: string;
    comment?: string;
    validAction: number;
}

@Component({
    components: {
        InputElementMgt
    }
})
export default class OverTimeInformation extends RHBaseClass {
    @Prop({}) overTimeId!: number;
    private currentWorkflow: IWorkflow<IOverTimeWorkflowItem> = {} as IWorkflow<IOverTimeWorkflowItem>;
    private validators: Array<IUnique> = [];
    private overTimes: Array<IOverTimeWorkflowItem> = [];
    private projects: ISelectListOption[] = [];
    private isSetValidators: boolean = true;
    private uniqueValidatorValue: number = 1;

    private fields: Array<IBootstrapTableColumn | string> = [
        {
            label: 'Projet',
            key: 'projectId',
            thStyle: 'text-align: center;min-width:200px'
        },
        { label: 'Date de début', key: 'fromDate', thStyle: 'text-align: center;min-width:150px' },
        { label: 'Date de fin', key: 'toDate', thStyle: 'text-align: center;min-width:150px' },
        { label: 'Heures / jour', key: 'hoursPerDay', thStyle: 'text-align: center;min-width:100px' },
        { label: '', key: 'delete' }
    ];

    deleteOverTimeElementLine(item: IOverTimeWorkflowItem): void {
        const index = this.overTimes?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.overTimes?.splice(index, 1);
        }
    }

    addOverTimeElement(): void {
        const overTimeElement = {} as IOverTimeWorkflowItem;
        overTimeElement.hoursPerDay = 1;
        this.overTimes?.push(overTimeElement);
    }

    get isValidationPage(): boolean {
        return this.$router.currentRoute.name === 'over-time-information-validation';
    }

    get isValidOverTime(): boolean {
        let res = true;
        res = res && this.isSetValidators;
        res = res && this.overTimes && this.overTimes.length > 0;
        this.overTimes.forEach((element) => {
            res = res && element.projectId !== undefined && element.projectId !== null && element.projectId !== 0;
            res = res && element.fromDate !== undefined && element.fromDate !== null;
            res = res && element.toDate !== undefined && element.toDate !== null;
        });
        return res;
    }

    get hasBeenValidated(): boolean {
        const currentValidator = this.validators.find((x) => x.defaultId === this.currentUserId);
        return currentValidator?.validAction !== 0;
    }

    get formatedOverTimeDate(): string {
        if (this.currentWorkflow?.elements) {
            const fromDates = this.currentWorkflow.elements.map((x) => {
                return new Date(x.fromDate);
            });
            const toDates = this.currentWorkflow.elements.map((x) => {
                return new Date(x.toDate);
            });
            if (fromDates && fromDates.length > 0 && toDates && toDates.length > 0) {
                return `Du ${
                    format(new Date(String(min(fromDates))), 'EEEE dd MMMM yyyy', {
                        locale: frenchLocale
                    }) ?? ''
                } au ${
                    format(new Date(String(max(toDates))), 'EEEE dd MMMM yyyy', {
                        locale: frenchLocale
                    }) ?? ''
                }
                `;
            }
        }
        return '';
    }

    get currentUserId(): string {
        return authModule.getAccount()!.localAccountId!.toUpperCase();
    }

    get isCreateOverTimePage(): boolean {
        return this.currentWorkflow && (this.currentWorkflow.id === 0 || this.currentWorkflow.id === undefined);
    }

    private checkValidators(): void {
        let res = true;
        this.validators.forEach((element) => {
            res = res && element.defaultId !== null && element.defaultId !== undefined;
        });
        this.isSetValidators = res;
    }

    removeValidator(index: number): void {
        if (this.validators.length > 1) {
            this.validators.splice(
                this.validators.findIndex((x) => x.uniqueValue === index),
                1
            );
        }
    }

    addNewValidator(): void {
        if (this.validators.length < 3) {
            this.uniqueValidatorValue++;
            this.validators.push({ uniqueValue: this.uniqueValidatorValue } as IUnique);
        }
    }

    @Watch('overTimeId')
    async onOverTimeIdChange(newVal: number, _oldVal: number): Promise<void> {
        (this.currentWorkflow as unknown) = null;
        this.validators.splice(0, this.validators.length);
        if (newVal && newVal > 0) {
            const res = await workflowApi.getOverTimeWorkflowById(this.id, this.overTimeId);
            if (isCallValidAndNotCancelled(res)) {
                const workflow = res.datas as IWorkflow<IOverTimeWorkflowItem>;
                this.validators = workflow.validators.map((x) => {
                    return {
                        uniqueValue: this.uniqueValidatorValue++,
                        defaultId: x.employeeId,
                        validAction: x.workflowValidatorEvent?.status ?? 0,
                        comment: x.workflowValidatorEvent?.comment
                    } as IUnique;
                });
                this.overTimes = workflow.elements;
                this.currentWorkflow = workflow;
            }
        } else {
            this.currentWorkflow = {} as IWorkflow<IOverTimeWorkflowItem>;
            this.currentWorkflow.elements = [];
            this.addOverTimeElement();
        }
        if (this.validators.length === 0) {
            // need to have 3 validateors
            this.addDefaultValidator();
        }
        this.checkValidators();
        this.setPageTitle();
        const projectCallData = await projectApi.getAllBase();

        if (isCallValidAndNotCancelled<IProject[]>(projectCallData)) {
            this.projects =
                projectCallData?.datas?.map((x) => {
                    return {
                        id: x.id,
                        code: x.trigram,
                        label: x.designation
                    } as ISelectListOption;
                }) ?? [];
        }
    }

    private async validate(): Promise<void> {
        const validateObject: IOverTimeValidate = {} as IOverTimeValidate;
        validateObject.workflowId = this.currentWorkflow.id;
        validateObject.comment = this.validators.find((x) => x.defaultId === this.currentUserId)?.comment;
        validateObject.validate = true;
        const res = await overTimeApi.validateOverTimeWorkflow(validateObject, this.id);
        if (isCallValidAndNotCancelled(res)) {
            await this.onOverTimeIdChange(this.currentWorkflow.id, 0);
            this.$bvToast.toast('Validation effectué avec succès', {
                title: 'Demande de Charette',
                variant: 'success',
                solid: true
            });
        }
    }

    private async deny(): Promise<void> {
        const validateObject: IOverTimeValidate = {} as IOverTimeValidate;
        validateObject.workflowId = this.currentWorkflow.id;
        validateObject.comment = this.validators.find((x) => x.defaultId === this.currentUserId)?.comment;
        validateObject.validate = false;
        const res = await overTimeApi.validateOverTimeWorkflow(validateObject, this.id);
        if (isCallValidAndNotCancelled(res)) {
            await this.onOverTimeIdChange(this.currentWorkflow.id, 0);
            this.$bvToast.toast('Refus effectué avec succès', {
                title: 'Demande de Charette',
                variant: 'success',
                solid: true
            });
        }
    }

    addDefaultValidator(): void {
        // while (this.validators.length < 3) {
        this.uniqueValidatorValue++;
        this.validators.push({ uniqueValue: this.uniqueValidatorValue } as IUnique);
        // }
    }

    selectionChanged(event: CustomEvent, item: IUnique): void {
        if (event && event.detail) {
            item.defaultId = event.detail[0]?.id;
            item.id = event.detail[0]?.id;
            this.checkValidators();
        }
    }

    async created(): Promise<void> {
        this.setPageTitle();
    }

    private async setPageTitle(): Promise<void> {
        const employeeIdentifier = authModule.getAccount()!.localAccountId!;
        vxm.app.changeTitleMain('Demande de Charette');
        if (employeeIdentifier === this.id) {
            if (this.overTimeId > 0) {
                await vxm.app.changeTitleExt(`Votre demande (identifiant n°${this.overTimeId})`);
            } else {
                await vxm.app.changeTitleExt('Créer une nouvelle demande');
            }
        } else {
            const currentUserData = await moduleApiGraph.Client.api(`/users/${this.id}`).get();
            const prevName = currentUserData ? currentUserData.displayName + ' > ' : '';
            if (this.overTimeId > 0) {
                vxm.app.changeTitleExt(prevName + `demande (identifiant n°${this.overTimeId})`);
            } else {
                vxm.app.changeTitleExt(prevName + 'Créer une nouvelle demande');
            }
        }
    }

    async mounted(): Promise<void> {
        await this.onOverTimeIdChange(this.overTimeId, this.overTimeId);
    }

    private backToProfile() {
        this.$router.push({ name: 'user-information', params: { id: this.id as string } });
    }

    private backToCurrentUserProfile() {
        this.$router.push({
            name: 'user-information',
            params: { id: authModule.getAccount()!.localAccountId as string }
        });
    }

    async saveOverTime(): Promise<void> {
        this.currentWorkflow.elements = this.overTimes;
        const va = this.currentWorkflow.validators ?? [];
        const newVaArray: Array<IWorkflowValidator> = [];
        this.validators.forEach((element, index) => {
            const oldVal = va.filter((oldElement) => oldElement.employeeId === element.id);
            if (oldVal.length === 1) {
                oldVal[0].order = index + 1;
                newVaArray.push(oldVal[0]);
            } else if (element.id !== '') {
                newVaArray.push({ employeeId: element.id, order: index + 1 } as IWorkflowValidator);
            }
        });

        this.currentWorkflow.validators = newVaArray;
        if (!this.currentWorkflow.id || this.currentWorkflow.id === 0) {
            const res = await overTimeApi.createOverTimeWorkflow(this.currentWorkflow, this.id);
            if (isCallValidAndNotCancelled(res)) {
                this.currentWorkflow.id = res.datas as number;
                this.$router.push({
                    name: 'over-time-information',
                    params: { overTimeId: this.currentWorkflow.id + '' }
                });
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: 'Demande de Charette',
                    variant: 'success',
                    solid: true
                });
            }
        }
    }
    //
}
