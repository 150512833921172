





















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import RHBaseClass from '../rh-class-base.vue';
import CalendarDayPicker from '@c/shared/calendar-day-picker.vue';
import { vxm } from '@/store';
import { ICalendarHalfDay } from '@/entity/shared/calendar-day-picker';
import { workflowApi } from '@api/workflow-api';
import { leaveApi } from '@api/leave-api';
import { ILeaveValidate, ILeaveWorkflowItem, IWorkflow, IWorkflowValidator } from '@/entity/rh/workflow';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import InputElementMgt from '@c/shared/input-element-mgt.vue';
import { authModule } from '@t/session';
import { moduleApiGraph } from '@t/module-api-graph';
import { format, max, min } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';

interface IUnique {
    id?: string;
    uniqueValue: number;
    defaultId?: string;
    comment?: string;
    validAction: number;
}

@Component({
    components: {
        CalendarDayPicker,
        InputElementMgt
    }
})
export default class LeaveInformation extends RHBaseClass {
    @Prop({}) leaveId!: number;
    private defaultDate: Date = new Date();
    private currentMonth: number = 0;
    private currentYear: number = 0;
    private selectedHalfDays: Array<ICalendarHalfDay> = [];
    private currentWorkflow: IWorkflow<ILeaveWorkflowItem> = {} as IWorkflow<ILeaveWorkflowItem>;
    private validators: Array<IUnique> = [];
    private isSetValidators: boolean = true;
    private uniqueValidatorValue: number = 1;
    private defaultSelected: Array<ICalendarHalfDay> = [];

    get isValidationPage(): boolean {
        return this.$router.currentRoute.name === 'leave-information-validation';
    }

    get isValidLeave(): boolean {
        let res = true;
        res = res && this.isSetValidators;
        res = res && this.selectedHalfDays && this.selectedHalfDays.length > 0;
        return res;
    }

    get hasBeenValidated(): boolean {
        const currentValidator = this.validators.find((x) => x.defaultId === this.currentUserId);
        return currentValidator?.validAction !== 0;
    }

    get formatedLeaveDate(): string {
        if (this.currentWorkflow?.elements) {
            const dates = this.currentWorkflow.elements.map((x) => {
                return new Date(x.date);
            });
            if (dates && dates.length > 0) {
                return `Du ${
                    format(new Date(String(min(dates))), 'EEEE dd MMMM yyyy', {
                        locale: frenchLocale
                    }) ?? ''
                } au ${
                    format(new Date(String(max(dates))), 'EEEE dd MMMM yyyy', {
                        locale: frenchLocale
                    }) ?? ''
                }
                `;
            }
        }
        return '';
    }

    get currentUserId(): string {
        return authModule.getAccount()!.localAccountId!.toUpperCase();
    }

    get isCreateLeavePage(): boolean {
        return this.currentWorkflow && (this.currentWorkflow.id === 0 || this.currentWorkflow.id === undefined);
    }

    private checkValidators(): void {
        let res = true;
        this.validators.forEach((element) => {
            res = res && element.defaultId !== null && element.defaultId !== undefined;
        });
        this.isSetValidators = res;
    }

    removeValidator(index: number): void {
        if (this.validators.length > 1) {
            this.validators.splice(
                this.validators.findIndex((x) => x.uniqueValue === index),
                1
            );
        }
    }

    addNewValidator(): void {
        if (this.validators.length < 3) {
            this.uniqueValidatorValue++;
            this.validators.push({ uniqueValue: this.uniqueValidatorValue } as IUnique);
        }
    }

    @Watch('leaveId')
    async onLeaveIdChange(newVal: number, _oldVal: number): Promise<void> {
        (this.currentWorkflow as unknown) = null;
        this.validators.splice(0, this.validators.length);
        if (newVal && newVal > 0) {
            const res = await workflowApi.getLeaveWorkflowById(this.id, this.leaveId);
            if (isCallValidAndNotCancelled(res)) {
                const workflow = res.datas as IWorkflow<ILeaveWorkflowItem>;
                if (workflow.elements && workflow.elements.length > 0) {
                    this.defaultSelected = workflow.elements.map((x) => {
                        const d: Date = new Date(x.date);
                        return {
                            year: d.getFullYear(),
                            month: d.getMonth(),
                            day: d.getDate(),
                            dayPartId: x.dayPartTypeId
                        } as ICalendarHalfDay;
                    });
                    this.defaultDate = new Date(workflow.elements[0].date);
                }

                this.validators = workflow.validators.map((x) => {
                    return {
                        uniqueValue: this.uniqueValidatorValue++,
                        defaultId: x.employeeId,
                        validAction: x.workflowValidatorEvent?.status ?? 0,
                        comment: x.workflowValidatorEvent?.comment
                    } as IUnique;
                });
                this.currentWorkflow = workflow;
            }
        } else {
            this.defaultDate = new Date();
            this.currentWorkflow = {} as IWorkflow<ILeaveWorkflowItem>;
        }
        if (this.validators.length === 0) {
            // need to have 3 validateors
            this.addDefaultValidator();
        }
        this.checkValidators();
        this.currentMonth = this.defaultDate.getMonth();
        this.currentYear = this.defaultDate.getFullYear();
        this.setPageTitle();
    }

    private async validate(): Promise<void> {
        const validateObject: ILeaveValidate = {} as ILeaveValidate;
        validateObject.workflowId = this.currentWorkflow.id;
        validateObject.comment = this.validators.find((x) => x.defaultId === this.currentUserId)?.comment;
        validateObject.validate = true;
        const res = await leaveApi.validateLeaveWorkflow(validateObject, this.id);
        if (isCallValidAndNotCancelled(res)) {
            await this.onLeaveIdChange(this.currentWorkflow.id, 0);
            this.$bvToast.toast('Validation effectué avec succès', {
                title: 'Congés',
                variant: 'success',
                solid: true
            });
        }
    }

    private async deny(): Promise<void> {
        const validateObject: ILeaveValidate = {} as ILeaveValidate;
        validateObject.workflowId = this.currentWorkflow.id;
        validateObject.comment = this.validators.find((x) => x.defaultId === this.currentUserId)?.comment;
        validateObject.validate = false;
        const res = await leaveApi.validateLeaveWorkflow(validateObject, this.id);
        if (isCallValidAndNotCancelled(res)) {
            await this.onLeaveIdChange(this.currentWorkflow.id, 0);
            this.$bvToast.toast('Refus effectué avec succès', {
                title: 'Congés',
                variant: 'success',
                solid: true
            });
        }
    }

    addDefaultValidator(): void {
        // while (this.validators.length < 3) {
        this.uniqueValidatorValue++;
        this.validators.push({ uniqueValue: this.uniqueValidatorValue } as IUnique);
        // }
    }

    selectionChanged(event: CustomEvent, item: IUnique): void {
        if (event && event.detail) {
            item.defaultId = event.detail[0]?.id;
            item.id = event.detail[0]?.id;
            this.checkValidators();
        }
    }

    async created(): Promise<void> {
        this.setPageTitle();
    }

    private async setPageTitle(): Promise<void> {
        const employeeIdentifier = authModule.getAccount()!.localAccountId!;
        vxm.app.changeTitleMain('Congés');
        if (employeeIdentifier === this.id) {
            if (this.leaveId > 0) {
                await vxm.app.changeTitleExt(`Votre demande (identifiant n°${this.leaveId})`);
            } else {
                await vxm.app.changeTitleExt('Créer une nouvelle demande');
            }
        } else {
            const currentUserData = await moduleApiGraph.Client.api(`/users/${this.id}`).get();
            const prevName = currentUserData ? currentUserData.displayName + ' > ' : '';
            if (this.leaveId > 0) {
                vxm.app.changeTitleExt(prevName + `demande (identifiant n°${this.leaveId})`);
            } else {
                vxm.app.changeTitleExt(prevName + 'Créer une nouvelle demande');
            }
        }
    }

    async mounted(): Promise<void> {
        await this.onLeaveIdChange(this.leaveId, this.leaveId);
    }

    private onCollectionChange(halfDayCollection: Array<ICalendarHalfDay>) {
        this.selectedHalfDays = halfDayCollection;
    }

    private backToProfile() {
        this.$router.push({ name: 'user-information', params: { id: this.id as string } });
    }

    private backToCurrentUserProfile() {
        this.$router.push({
            name: 'user-information',
            params: { id: authModule.getAccount()!.localAccountId as string }
        });
    }

    async saveLeave(): Promise<void> {
        const es = this.currentWorkflow.elements ?? [];
        const newArray: Array<ILeaveWorkflowItem> = [];
        this.selectedHalfDays.forEach((element) => {
            const oldVal = es.filter((x) => {
                const d = new Date(x.date);
                return (
                    x.dayPartTypeId === element.dayPartId &&
                    d.getDate() === element.day &&
                    d.getMonth() === element.month &&
                    d.getFullYear() === element.year
                );
            });
            const newObject: ILeaveWorkflowItem = {
                date: new Date(element.year, element.month, element.day),
                value: 1,
                dayPartTypeId: element.dayPartId,
                id: null
            };
            if (oldVal.length === 1) {
                newObject.id = oldVal[0].id;
            }

            newArray.push(newObject);
        });
        this.currentWorkflow.elements = newArray;

        const va = this.currentWorkflow.validators ?? [];
        const newVaArray: Array<IWorkflowValidator> = [];
        this.validators.forEach((element, index) => {
            const oldVal = va.filter((oldElement) => oldElement.employeeId === element.id);
            if (oldVal.length === 1) {
                oldVal[0].order = index + 1;
                newVaArray.push(oldVal[0]);
            } else if (element.id !== '') {
                newVaArray.push({ employeeId: element.id, order: index + 1 } as IWorkflowValidator);
            }
        });

        this.currentWorkflow.validators = newVaArray;
        if (!this.currentWorkflow.id || this.currentWorkflow.id === 0) {
            const res = await leaveApi.createLeaveWorkflow(this.currentWorkflow, this.id);
            if (isCallValidAndNotCancelled(res)) {
                this.currentWorkflow.id = res.datas as number;
                this.$router.push({ name: 'leave-information', params: { leaveId: this.currentWorkflow.id + '' } });
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: 'Congés',
                    variant: 'success',
                    solid: true
                });
            }
        } else {
            const res = await leaveApi.updateLeaveWorkflow(this.currentWorkflow, this.id);
            if (isCallValidAndNotCancelled(res)) {
                await this.onLeaveIdChange(this.currentWorkflow.id, 0);
            }
        }
    }
    //
}
