var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentWorkflow != null)?_c('div',{attrs:{"id":"leave-block"}},[_c('calendar-day-picker',{staticClass:"leave-day-picker",attrs:{"defaultMonth":_vm.currentMonth,"defaultYear":_vm.currentYear,"defaultSelected":_vm.defaultSelected,"minDate":new Date(),"isReadonly":_vm.currentWorkflow && _vm.currentWorkflow.id > 0},on:{"collectionChanged":_vm.onCollectionChange}}),_c('div',{staticClass:"leave-val-picker"},[_vm._l((_vm.validators),function(v,index){return _c('div',{key:'valid' + v.uniqueValue,staticClass:"inner-leave-val-picker"},[_c('b-col',[_c('b-row',{staticStyle:{"height":"38px","width":"400px","margin-bottom":"15px"}},[_c('b-col',{attrs:{"cols":"10"}},[(_vm.isCreateLeavePage)?_c('input-element-mgt',{attrs:{"default-ids":v.defaultId && v.defaultId.length > 0 ? v.defaultId : null,"inputId":'employee-leave' + index,"label":'Validateur n°' + (index + 1)},on:{"selectionChanged":function($event){return _vm.selectionChanged($event, v)}}}):_c('mgt-person',{attrs:{"user-id":v.defaultId,"fetch-image":"true","view":"oneLine"}})],1),(!_vm.isCreateLeavePage)?_c('b-col',{attrs:{"cols":"2"}},[(v.validAction === 1)?_c('b-icon',{attrs:{"icon":"check-square","scale":"2","variant":"all"}}):(v.validAction === -1)?_c('b-icon',{attrs:{"icon":"x-circle","scale":"2","variant":"all"}}):_c('b-icon',{attrs:{"icon":"clock-history","scale":"2","variant":"all"}})],1):_c('b-col',{attrs:{"cols":"2"}},[(_vm.validators && _vm.validators.length > 1)?_c('b-button',{staticClass:"mb-2",attrs:{"variant":"outline-all"},on:{"click":function($event){return _vm.removeValidator(v.uniqueValue)}}},[_c('b-icon',{attrs:{"icon":"trash","aria-hidden":"true"}})],1):_vm._e()],1)],1),(
                        v.defaultId &&
                        (v.defaultId.toUpperCase() === _vm.currentUserId || v.comment !== null) &&
                        _vm.isValidationPage
                    )?_c('b-row',{staticStyle:{"margin-left":"2px","margin-bottom":"27px"}},[_c('b-form-textarea',{attrs:{"disabled":v.defaultId !== _vm.currentUserId || _vm.hasBeenValidated,"id":"comment-textarea","size":"sm","placeholder":"Commentaire..."},model:{value:(v.comment),callback:function ($$v) {_vm.$set(v, "comment", $$v)},expression:"v.comment"}})],1):_vm._e(),(
                        v.defaultId &&
                        v.defaultId.toUpperCase() === _vm.currentUserId &&
                        _vm.isValidationPage &&
                        !_vm.hasBeenValidated
                    )?_c('b-row',[_c('b-col',{attrs:{"align":"right"}},[_c('b-button',{attrs:{"variant":"outline-all"},on:{"click":_vm.deny}},[_vm._v("Refuser")]),_c('b-button',{staticStyle:{"margin-left":"10px"},attrs:{"variant":"outline-all"},on:{"click":_vm.validate}},[_vm._v("Valider")])],1)],1):_vm._e()],1)],1)}),_c('b-col',[(_vm.validators && _vm.validators.length < 3 && _vm.isCreateLeavePage)?_c('b-button',{staticClass:"mb-2",attrs:{"variant":"outline-all"},on:{"click":_vm.addNewValidator}},[_c('b-icon',{attrs:{"icon":"plus","aria-hidden":"true"}}),_vm._v(" Ajouter un validateur ")],1):_vm._e()],1)],2),(_vm.formatedLeaveDate !== '')?_c('div',{staticClass:"leave-date-header"},[_c('h4',[_c('b-badge',{attrs:{"pill":"","variant":"all"}},[_vm._v(_vm._s(_vm.formatedLeaveDate))])],1)]):_vm._e(),_c('div',{staticClass:"leave-val-picker-header"},[_vm._v("Validateurs:")]),(!_vm.isValidationPage)?_c('b-row',[_c('b-col',{attrs:{"cols":"6","align":"left"}},[_c('b-button',{attrs:{"variant":"outline-all"},on:{"click":_vm.backToProfile}},[_c('b-icon',{attrs:{"icon":"arrow-bar-left","aria-hidden":"true"}}),_vm._v(" Retour au Profil")],1)],1),_c('b-col',{attrs:{"cols":"6","align":"right"}},[(_vm.isCreateLeavePage)?_c('b-button',{staticStyle:{"margin-right":"75px"},attrs:{"variant":"outline-all","disabled":!_vm.isValidLeave},on:{"click":_vm.saveLeave}},[_vm._v("Enregistrer "),_c('b-icon',{attrs:{"icon":"pencil-square","aria-hidden":"true"}})],1):_vm._e()],1)],1):_c('b-row',{staticClass:"leave-save-row m-0"},[_c('b-col',{attrs:{"cols":"6","align":"left"}},[_c('b-button',{attrs:{"variant":"outline-all"},on:{"click":_vm.backToCurrentUserProfile}},[_c('b-icon',{attrs:{"icon":"arrow-bar-left","aria-hidden":"true"}}),_vm._v(" Retour au Profil")],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }