
































































































































import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { vxm } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import { moduleApiGraph } from '@t/module-api-graph';
import { User } from '@microsoft/microsoft-graph-types/microsoft-graph';
import { employeeApi } from '@api/employee-api';
import { IEmployee } from '@/entity/shared/employee';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IUserListData } from '@/entity/rh/user-list-data';
import { IOdataBaseReq } from '@/entity/rh/test-req';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';
import { IAgency } from '@/entity/project/agency';
import { MgtPerson } from '@microsoft/mgt';

@Component({})
export default class UserList extends Vue {
    private filteredDataAllColumns: string = '';
    private employeeList: IUserListData[] = [];
    private graphEmployees: { [key: string]: string } = {};
    private graphEmployeesDisplayName: { [key: string]: string } = {};
    private filteredEmployee: IUserListData[] = [];
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private filterData: string = '';
    private isBusy: boolean = true;
    private pendingGetList: boolean = false;
    private promiseExport: boolean = false;

    private fields: Array<IBootstrapTableColumn | string> = [
        {
            key: 'userId',
            label: 'Employé',
            sortable: true,
            formatter: (val: string, key?: string, item?) => val
        },
        {
            key: 'trigram',
            label: 'Trigramme',
            sortable: true,
            formatter: (val: string, key?: string, item?) => val || '-'
        },
        {
            key: 'agency',
            label: 'Société',
            sortable: true,
            formatter: (val?: IAgency, key?: string, item?) => (val ? (val.label ? val.label : '-') : '-')
        },
        {
            key: 'teleworking',
            label: 'Compteur télétravail',
            sortable: true,
            formatter: (val: string, key?: string, item?) => (val ?? 0) + ' jour(s)'
        }
    ];

    private listOfFilters: any = {
        userId: {
            columnName: 'userId',
            value: ''
        },
        trigram: {
            columnName: 'trigram',
            value: ''
        },
        agency: {
            columnName: 'agency',
            value: ''
        },
        teleworking: {
            columnName: 'teleworking',
            value: ''
        }
    };

    created(): void {
        vxm.app.changeTitleMain('Personnel');
    }

    async registerAllEmployees(): Promise<void> {
        const missingEmployee = this.employeeList.filter((x) => x.profileStatus === 'Manquant');
        missingEmployee.forEach(async (employee) => {
            const returnValue = await employeeApi.postBase({
                id: employee.user.id as string,
                isManager: false,
                entryDate: new Date()
            });
            if (isCallValidAndNotCancelled(returnValue)) {
                employee.profileStatus = 'Non';
            }
        });
    }

    async exportEmpoyee(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const listIds =
            this.filterData && this.filteredEmployee && this.filteredEmployee.length !== this.employeeList.length
                ? this.filteredEmployee.map((x) => x.user.id)
                : this.filteredDataByColumn && this.filteredDataByColumn.length !== this.employeeList.length
                    ? this.filteredDataByColumn.map((x) => x.user.id)
                    : this.employeeList.map((x) => x.user.id);
        await this.generateReport(
            employeeApi.exportEmployeeData(
                listIds as (string | undefined)[],
                sortKeySelected as string,
                sortKeyDescSelected as any
            ),
            `Personnel_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private onFiltered(filteredItems: IUserListData[]) {
        this.filteredEmployee = filteredItems;
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private onRowClick(item: IUserListData, index: number, event: any): void {
        if (event.srcElement.localName !== 'mgt-person') {
            this.$router.push({ name: 'user-information', params: { id: item.user.id as string } });
        }
    }

    async loadUsers(): Promise<void> {
        // this.pendingGetList = true;
        this.isBusy = true;
        const allUsersInDb = (await employeeApi.getAllBase())?.datas as Array<IEmployee>;
        let reqAllUsers: IOdataBaseReq<User> = {
            '@odata.nextLink': `${
                process.env.NODE_ENV === 'production'
                    ? '/groups/04c056a5-16b5-4052-b2c4-da098a26d57a/members'
                    : '/users'
            }?$top=10&$select=id,displayName,surname,givenName,userType`,
            value: new Array<User>()
        };
        do {
            reqAllUsers = await moduleApiGraph.Client.api(reqAllUsers['@odata.nextLink'] as string).get();
            this.employeeList.push(
                ...reqAllUsers.value.map<IUserListData>((x) => {
                    if (!allUsersInDb) {
                        return {
                            user: x,
                            profileStatus: 'Inconnu'
                        };
                    }
                    const dbUser = allUsersInDb.filter((y) => y.id === x.id?.toUpperCase());
                    if (dbUser && dbUser.length === 1) {
                        const dbEmployee = dbUser[0] as IEmployee;
                        this.graphEmployees[dbEmployee.id] = x.surname
                            ? x.givenName
                                ? this.sansAccent(x.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(x.surname).toLowerCase().replaceAll('-', '').replaceAll(' ', '')) &&
                                  this.sansAccent(x.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(x.givenName).toLowerCase().replaceAll('-', '').replaceAll(' ', ''))
                                    ? x.surname.toLowerCase() + ' ' + x.givenName.toLowerCase()
                                    : x.displayName!
                                : this.sansAccent(x.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(x.surname).toLowerCase().replaceAll('-', '').replaceAll(' ', ''))
                                    ? x.surname
                                    : x.displayName!
                            : x.displayName ?? '';
                        // ReplaceAll is used for the cases of tabulation inside displayName 
                        this.graphEmployeesDisplayName[dbEmployee.id] = x.displayName!.replaceAll('   ', ' ');
                        return {
                            user: x,
                            profileStatus:
                                dbEmployee.employeeRoleId !== undefined &&
                                dbEmployee.employeeRoleId > 0 &&
                                dbEmployee.employeeServiceId !== undefined &&
                                dbEmployee.employeeServiceId > 0 &&
                                dbEmployee.employeeContractId !== undefined &&
                                dbEmployee.employeeContractId > 0
                                    ? 'Oui'
                                    : 'Non',
                            entryDate: dbEmployee.entryDate,
                            employeeRole: dbEmployee.employeeRole?.label,
                            employeeService: dbEmployee.employeeService?.label,
                            employeeContract: dbEmployee.employeeContract?.label,
                            agency: dbEmployee.agency,
                            trigram: dbEmployee.trigram,
                            teleworking: dbEmployee.teleworking
                        };
                    } else {
                        return {
                            user: x,
                            profileStatus: 'Manquant'
                        };
                    }
                })
            );
        } while (reqAllUsers['@odata.nextLink'] != null);
        this.employeeList?.sort((a, b) => {
            var nameA = '';
            var nameB = '';
            nameA = a.user?.surname
                ? a.user?.givenName
                    ? this.sansAccent(a.user.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(a.user.surname).toLowerCase().replaceAll('-', '').replaceAll(' ', '')) &&
                      this.sansAccent(a.user.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(a.user.givenName).toLowerCase().replaceAll('-', '').replaceAll(' ', ''))
                        ? this.sansAccent(a.user.surname.toLowerCase() + ' ' + this.sansAccent(a.user.givenName.toLowerCase()))
                        : this.sansAccent(a.user.displayName!)
                    : this.sansAccent(a.user.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(a.user.surname).toLowerCase().replaceAll('-', '').replaceAll(' ', ''))
                        ? this.sansAccent(a.user.surname)
                        : this.sansAccent(a.user.displayName!)
                : this.sansAccent(a.user.displayName!) ?? '';
            nameB = b.user.surname
                ? b.user.givenName
                    ? this.sansAccent(b.user.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(b.user.surname).toLowerCase().replaceAll('-', '').replaceAll(' ', '')) &&
                       this.sansAccent(b.user.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(b.user.givenName).toLowerCase().replaceAll('-', '').replaceAll(' ', ''))
                        ? this.sansAccent(b.user.surname.toLowerCase() + ' ' + this.sansAccent(b.user?.givenName.toLowerCase()))
                        : this.sansAccent(b.user.displayName!)
                    : this.sansAccent(b.user.displayName!).toLowerCase().replaceAll('-', '').replaceAll(' ', '').includes(this.sansAccent(b.user.surname).toLowerCase().replaceAll('-', '').replaceAll(' ', ''))
                        ? this.sansAccent(b.user.surname)
                        : this.sansAccent(b.user.displayName!)
                : this.sansAccent(b.user.displayName!) ?? '';
            if (nameA.toLowerCase() < nameB.toLowerCase()) { 
                return -1; 
            }
            if (nameA.toLowerCase() > nameB.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        this.filteredEmployee = this.employeeList;
        this.onFiltered(this.employeeList);
        this.isBusy = false;
        this.pendingGetList = false;
    }

    get filteredDataByColumn(): IUserListData[] {
        const keys = Object.keys(this.listOfFilters);
        const filtersApplied = keys.filter((x) => this.listOfFilters[x].value.length > 0);
        if (filtersApplied.length > 0) {
            this.filteredEmployee = this.employeeList.filter((p) =>
                filtersApplied.every((f) => this.filterListByColumn(f, p))
            );
            return this.employeeList.filter((p) => filtersApplied.every((f) => this.filterListByColumn(f, p)));
        }
        this.filteredEmployee = this.employeeList;
        return this.employeeList;
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        var b0, a0;
        var a, b;
        if (key === 'trigram') {
            a = itemA[key];
            b = itemB[key];
            if (a == null) {
                a = '';
            }
            if (b == null) {
                b = '';
            }
            return this.strcmp(a.toUpperCase(), b.toUpperCase());
        }
        if (key === 'teleworking') {
            const a = itemA[key] ? itemA[key].toString() + ' jour(s)' : '0 jour(s)';
            const b = itemB[key] ? itemB[key].toString() + ' jour(s)' : '0 jour(s)';
            return this.strcmp(a.toLowerCase(), b.toLowerCase());
        } else if (key === 'agency') {
            a = itemA[key];
            b = itemB[key];
            if (a === undefined || a === null || a.length === 0) {
                a0 = '-';
            } else if (a.label === undefined) {
                a0 = '-';
            } else a0 = a.label;
            if (b === undefined || b === null || b.length === 0) {
                b0 = '-';
            } else if (b.label === undefined) {
                b0 = '-';
            } else b0 = b.label;
            return this.strcmp(a0, b0);
        } else if (key === 'userId') {
            const keyName = 'user';
            a = itemA[keyName].id.toUpperCase();
            if (this.graphEmployees[a]) {
                a0 = this.sansAccent(this.graphEmployees[a].toLowerCase());
            }
            b = itemB[keyName].id.toUpperCase();
            if (this.graphEmployees[b]) {
                b0 = this.sansAccent(this.graphEmployees[b].toLowerCase());
            }
            if (a0 === undefined) a0 = '';
            if (b0 === undefined) b0 = '';
            return this.strcmp(a0.toUpperCase(), b0.toUpperCase());
        }
    }

    private sansAccent = function (word: string) {
        var accent = [
            /[\300-\306]/g,
            /[\340-\346]/g, // A, a
            /[\310-\313]/g,
            /[\350-\353]/g, // E, e
            /[\314-\317]/g,
            /[\354-\357]/g, // I, i
            /[\322-\330]/g,
            /[\362-\370]/g, // O, o
            /[\331-\334]/g,
            /[\371-\374]/g, // U, u
            /[\321]/g,
            /[\361]/g, // N, n
            /[\307]/g,
            /[\347]/g // C, c
        ];
        var noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

        var str = word;
        for (var i = 0; i < accent.length; i++) {
            str = str.replace(accent[i], noaccent[i]);
        }
        return str;
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    public filterListByColumn(key: string, x: IUserListData): boolean {
        if (key === 'trigram') {
            return x.trigram
                ? x.trigram.toLowerCase().includes(this.listOfFilters.trigram.value.toLowerCase())
                : '-'.toUpperCase().includes(this.listOfFilters.trigram.value.toUpperCase());
        } else if (key === 'agency') {
            return x.agency
                ? x.agency!.label
                    ? x.agency!.label.toLowerCase().includes(this.listOfFilters.agency.value.toLowerCase())
                    : false
                : '-'.toLowerCase().includes(this.listOfFilters.agency.value.toLowerCase());
        } else if (key === 'teleworking') {
            return x.teleworking
                ? (x.teleworking.toString() + ' jour(s)')
                    .toLowerCase()
                    .includes(this.listOfFilters.teleworking.value.toString().toLowerCase())
                : '0 jour(s)'.includes(this.listOfFilters.teleworking.value.toString().toLowerCase());
        } else if (key === 'userId') {
            if (x.user.id) {
                var name = this.graphEmployeesDisplayName[x.user.id.toUpperCase()];
                if (name != null) {
                    return name.toLowerCase().includes(this.listOfFilters.userId.value.toLowerCase());
                }
                else {
                    return false;
                }
            } else return false;
        } else {
            return false;
        }
    }

    async mounted(): Promise<void> {
        await this.loadUsers();
    }
}
