

























































































































































































































































































































































































































































































































import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { IPlanificationPhaseProfile } from '@/entity/planification/planification-phase-profile';
import { IProject } from '@/entity/project/project';
import { planificationApi } from '@/wapi/planification-api';
import { projectApi } from '@/wapi/project-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { NU } from '@t/type';
import { format, isAfter, isBefore, isDate, isEqual, max, min } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { Component, Vue, Watch } from 'vue-property-decorator';
import InputElementMgt from '@c/shared/input-element-mgt.vue';
import CalendarDayPicker from '@c/shared/calendar-day-picker.vue';
import CurrencyInput from '@c/shared/currency-input.vue';
import RecapCalendar from '@c/project-planification/recap-calendar.vue';
import { vxm } from '@/store';
import { IEmployeeRole } from '@/entity/shared/referential';
import { ICalendarHalfDay, ICalendarProp } from '@/entity/shared/calendar-day-picker';
import { IPlanificationPhaseProfileWorkDay } from '@/entity/planification/planification-phase-profile-work-day';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { IPlanificationProfileScheduler } from '@/entity/planification/planification-profile-scheduler';
import { appTokenMgr } from '@t/employee-app-role';
import { authModule } from '@t/session';
import InputElement from '@c/shared/input-element.vue';
import { deepCopy } from '@t/object';
import { moduleApiGraph } from '@t/module-api-graph';
import { studioApi } from '@/wapi/studio-api';
import { IStudio } from '@/entity/project/studio';
import { IEmployee } from '@/entity/shared/employee';

@Component({
    components: {
        InputElementMgt,
        InputElement,
        CalendarDayPicker,
        CurrencyInput,
        RecapCalendar
    }
})
export default class ProjectPlanification extends Vue {
    isStudioManger: boolean = false;
    private defaultDate: Date = new Date();
    promiseSave: boolean = false;
    selectedPlanificationPhaseToUpdate: IPlanificationPhase = {} as IPlanificationPhase;
    private studios: NU<IStudio[]> = [];

    private weekDays: ISelectListOption[] = [
        { id: 1, code: 'CO1', label: 'Lundi' },
        { id: 2, code: 'CO2', label: 'Mardi' },
        { id: 3, code: 'CO3', label: 'Mercredi' },
        { id: 4, code: 'CO4', label: 'Jeudi' },
        { id: 5, code: 'CO5', label: 'Vendredi' }
    ];

    private dayTypesOptions = [
        { text: 'Matin', value: 'MOR' },
        { text: 'Aprés-midi', value: 'AFT' }
    ];

    private colors: string[] = ['#ff638480', '#36a2eb70', '#cc65fe75', '#ffce568c', '#28a74563', '#17a2b87d'];

    private currentMonth: number = 0;
    private currentYear: number = 0;

    planificationPhases: NU<IPlanificationPhase[]> = [];

    get employeeRoles(): NU<IEmployeeRole[]> {
        return vxm.referential.employeeRoles;
    }

    get selectedProject(): NU<IProject> {
        return vxm.project.dropdownProject;
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    private canEditOrDelete(): boolean {
        const acc = authModule.getAccount();
        return appTokenMgr.isAdmin(acc) || appTokenMgr.isStudioManager(acc) || appTokenMgr.isCP(acc);
    }

    private canAddNewProfile(): boolean {
        const acc = authModule.getAccount();
        return (
            appTokenMgr.isAdmin(acc) ||
            appTokenMgr.isDaf(acc) ||
            appTokenMgr.isStudioManager(acc) ||
            appTokenMgr.isCP(acc)
        );
    }

    get planificationProfileSchedulers(): IPlanificationProfileScheduler[] {
        const res: IPlanificationProfileScheduler[] = [];
        let indexColor: number = 0;
        this.planificationPhases?.forEach((phase) => {
            let totaleRateByPhase = 0;
            phase.planificationPhaseProfiles.forEach((element) => {
                totaleRateByPhase += element.totalRate;
            });
            const schedulerParent = {} as IPlanificationProfileScheduler;
            schedulerParent.employeeId = '';
            schedulerParent.profileId = 0;
            schedulerParent.profileNumber = phase.planificationPhaseProfiles.length;
            schedulerParent.contractLineId = phase.contractLineId;
            schedulerParent.contractLineLabel = phase.contractLine?.designation ?? '';
            schedulerParent.startDate = phase.openingDate;
            schedulerParent.endDate = phase.closingDate;
            schedulerParent.budget = phase.budget;
            schedulerParent.totalRate = totaleRateByPhase;
            schedulerParent.planificationPhaseProfileWorkDays = [];
            schedulerParent.phaseId = phase.id;
            schedulerParent.parentPhaseId = 0;
            schedulerParent.visible = true;
            schedulerParent.collapsedPhase = true;
            res.push(schedulerParent);
            phase.planificationPhaseProfiles.forEach((profile) => {
                const backgroundColor = this.generateLightColorRgb();
                const scheduler = {} as IPlanificationProfileScheduler;

                const dates = profile.planificationPhaseProfileWorkDays.map((x) => new Date(x.date));
                if (dates && dates.length > 0) {
                    scheduler.employeeId = profile.employeeId ?? '';
                    scheduler.profileId = profile.id;
                    scheduler.profileRole = profile.employeeRole?.label;
                    scheduler.contractLineId = phase.contractLineId;
                    scheduler.backgroundColor = this.colors[indexColor] ?? backgroundColor;
                    scheduler.contractLineLabel = phase.contractLine?.designation ?? '';
                    scheduler.startDate = min(dates);
                    scheduler.endDate = max(dates);
                    scheduler.budget = phase.budget;
                    scheduler.totalRate = profile.totalRate;
                    scheduler.planificationPhaseProfileWorkDays = profile.planificationPhaseProfileWorkDays;
                    scheduler.phaseId = 0;
                    scheduler.parentPhaseId = phase.id;
                    scheduler.visible = false;
                    res.push(scheduler);
                    indexColor++;
                }
            });
        });
        return res;
    }

    generateLightColorRgb(): string {
        const red = Math.floor(((1 + Math.random()) * 256) / 2);
        const green = Math.floor(((1 + Math.random()) * 256) / 2);
        const blue = Math.floor(((1 + Math.random()) * 256) / 2);
        return 'rgb(' + red + ', ' + green + ', ' + blue + ')';
    }

    private getPhaseTotalRate(item: IPlanificationPhase): number {
        let res = 0;
        item.planificationPhaseProfiles.forEach((element) => {
            res += element.totalRate;
        });
        return res;
    }

    private formattedPhaseTotalRate(item: IPlanificationPhase): string {
        return this.formattedCurrency(this.getPhaseTotalRate(item));
    }

    private formattedCurrency(item: number): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(
            item
        );
    }

    addProfile(item: IPlanificationPhase): void {
        const profile = {} as IPlanificationPhaseProfile;
        profile.employeeId = null;
        profile.planificationPhaseId = item.id;
        profile.totalRate = 0;
        profile.selectedDayTypes = ['MOR', 'AFT'];
        item.planificationPhaseProfiles.push(profile);
    }

    deleteProfileModal(index: number, phase: IPlanificationPhase): void {
        this.selectedPlanificationPhaseToUpdate?.planificationPhaseProfiles?.splice(index, 1);
    }

    isDisableAssigneWorkDates(profile: IPlanificationPhaseProfile): boolean {
        return !profile.startDate || !profile.endDate;
    }

    assigneWorkDatesForModal(profile: IPlanificationPhaseProfile, index: number, phase: IPlanificationPhase): void {
        const date = new Date(profile.startDate);
        const calendarPropWorkDays = {} as ICalendarProp;
        calendarPropWorkDays.defaultMonth = new Date(profile.startDate)?.getMonth() ?? 0;
        calendarPropWorkDays.defaultYear = new Date(profile.startDate)?.getFullYear() ?? 0;
        if (date && profile.endDate) {
            calendarPropWorkDays.defaultSelected = [];
            const dayPartTypes = vxm.referential.dayPartTypes;
            do {
                const dayNum = date.getDay();
                if (
                    dayNum !== 0 &&
                    dayNum !== 6 &&
                    (profile.selectedWeekDays === undefined ||
                        profile.selectedWeekDays.length === 0 ||
                        profile.selectedWeekDays?.find((x) => x === dayNum) !== undefined)
                ) {
                    if (
                        profile.selectedDayTypes.find((x) => x === 'MOR') !== undefined ||
                        profile.selectedDayTypes.length === 0
                    ) {
                        calendarPropWorkDays.defaultSelected.push({
                            year: date.getFullYear(),
                            month: date.getMonth(),
                            day: date.getDate(),
                            dayPartId: dayPartTypes?.filter((x) => x.code === 'MOR')[0].id ?? 1
                        } as ICalendarHalfDay);
                    }
                    if (
                        profile.selectedDayTypes.find((x) => x === 'AFT') !== undefined ||
                        profile.selectedDayTypes.length === 0
                    ) {
                        calendarPropWorkDays.defaultSelected.push({
                            year: date.getFullYear(),
                            month: date.getMonth(),
                            day: date.getDate(),
                            dayPartId: dayPartTypes?.filter((x) => x.code === 'AFT')[0].id ?? 2
                        } as ICalendarHalfDay);
                    }
                }
                date.setDate(date.getDate() + 1);
            } while (date.setHours(0, 0, 0, 0) <= new Date(profile.endDate).setHours(0, 0, 0, 0));
        }

        this.selectedPlanificationPhaseToUpdate?.planificationPhaseProfiles.forEach((x, i) => {
            if (i === index) {
                x.calendarPropWorkDays = calendarPropWorkDays;
                this.onCollectionChange(calendarPropWorkDays.defaultSelected, x);
            }
        });
    }

    get formatedOpeningDate(): string {
        if (this.selectedProject?.openingDate) {
            return (
                format(new Date(String(this.selectedProject?.openingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    get formatedClosingDate(): string {
        if (this.selectedProject?.closingDate) {
            return (
                format(new Date(String(this.selectedProject?.closingDate)), 'EEEE dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    @Watch('selectedProjectId')
    async assigneProject(newVal: number, _oldVal: number): Promise<void> {
        if (newVal && newVal > 0) {
            await this.getPlanificationPhases(newVal);
            await this.checkIsStudioManger(newVal);
        }
    }

    setOpeningAndClosingDateForPhase(phase: IPlanificationPhase): void {
        const dates: Date[] = [];
        if (phase && phase.planificationPhaseProfiles && phase.planificationPhaseProfiles.length > 0) {
            phase.planificationPhaseProfiles.forEach((profile) => {
                const itemDates = profile.planificationPhaseProfileWorkDays.map((x) => new Date(x.date));
                dates.push(...itemDates);
            });
            if (dates && dates.length > 0) {
                const minDate = min(dates);
                const maxDate = max(dates);
                minDate.setHours(3);
                maxDate.setHours(3);
                phase.openingDate = minDate.toISOString();
                phase.closingDate = maxDate.toISOString();
            }
        }
    }

    async getPlanificationPhases(projectId: number): Promise<void> {
        const phaseCallData = await planificationApi.getAllPhasesByProjectId(projectId);
        if (isCallValidAndNotCancelled<IPlanificationPhase[]>(phaseCallData)) {
            this.planificationPhases = phaseCallData?.datas;
            this.planificationPhases?.forEach((phase) =>
                phase.planificationPhaseProfiles.forEach((profile) => {
                    this.getEmployeeName(profile.employeeId).then((value) => {
                        profile.employeeName = value;
                    });
                    if (
                        profile.planificationPhaseProfileWorkDays &&
                        profile.planificationPhaseProfileWorkDays.length > 0
                    ) {
                        const defaultSelected = profile.planificationPhaseProfileWorkDays.map((x) => {
                            const d: Date = new Date(x.date);
                            return {
                                year: d.getFullYear(),
                                month: d.getMonth(),
                                day: d.getDate(),
                                dayPartId: x.dayPartTypeId
                            } as ICalendarHalfDay;
                        });
                        const date = new Date(profile.planificationPhaseProfileWorkDays[0].date);
                        profile.calendarPropWorkDays = {
                            defaultSelected: defaultSelected,
                            defaultMonth: date.getMonth(),
                            defaultYear: date.getFullYear()
                        };
                        this.setDateforProfile(profile);
                        profile.selectedDayTypes = ['MOR', 'AFT'];
                    } else {
                        profile.calendarPropWorkDays = {
                            defaultSelected: [],
                            defaultMonth: this.defaultDate.getMonth(),
                            defaultYear: this.defaultDate.getFullYear()
                        };
                    }
                })
            );
        }
    }

    private setDateforProfile(profile: IPlanificationPhaseProfile) {
        const dates = profile.planificationPhaseProfileWorkDays.map((x) => new Date(x.date));
        if (dates && dates.length > 0) {
            profile.startDate = min(dates);
            profile.endDate = max(dates);
        }
    }

    async checkIsStudioManger(projectId: number): Promise<void> {
        const checkCallData = await projectApi.checkStudioManagerUser(projectId + '');
        const acc = authModule.getAccount();
        if (isCallValidAndNotCancelled<boolean>(checkCallData)) {
            this.isStudioManger = (checkCallData?.datas ?? false) || appTokenMgr.isAdmin(acc);
        }
    }

    async savePlanificationPhases(): Promise<void> {
        this.promiseSave = true;
        const callData = await planificationApi.patchPhases(this.planificationPhases ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.getPlanificationPhases(this.selectedProject?.id ?? 0);
            this.promiseSave = false;
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Planification',
                variant: 'success',
                solid: true
            });
        }
    }

    private async selectedUserEvent(
        event: CustomEvent,
        item: { profile: IPlanificationPhaseProfile; phase: IPlanificationPhase }
    ) {
        if (event && event.detail && event.detail.length > 0 && event.detail[0]) {
            item.profile.employeeId = event.detail[0].id;
            const u = await planificationApi.getEmployeeHistorization(item.profile.employeeId as string);
            item.profile.employee = u?.datas as IEmployee;
        } else {
            item.profile.employeeId = null;
            item.profile.employee = null as any;
        }
        this.setProfileTotalRate(item.profile, item.phase);
    }

    private assignEmployeeRole(profile: IPlanificationPhaseProfile, phase: IPlanificationPhase) {
        profile.employeeRoleId = profile.employeeRole?.id;
        this.setProfileTotalRate(profile, phase);
    }

    private onCollectionChange(halfDayCollection: Array<ICalendarHalfDay>, profile: IPlanificationPhaseProfile) {
        const workDays: IPlanificationPhaseProfileWorkDay[] = Array.from(
            profile.planificationPhaseProfileWorkDays ?? []
        );
        profile.planificationPhaseProfileWorkDays = [];
        if (halfDayCollection && halfDayCollection.length > 0) {
            halfDayCollection.forEach((element) => {
                const oldVal = workDays.filter((x) => {
                    const d = new Date(x.date);
                    return (
                        x.dayPartTypeId === element.dayPartId &&
                        d.getDate() === element.day &&
                        d.getMonth() === element.month &&
                        d.getFullYear() === element.year
                    );
                });
                if (oldVal === undefined || (oldVal && oldVal.length === 0)) {
                    const newObject: IPlanificationPhaseProfileWorkDay = {
                        planificationPhaseProfileId: profile.id,
                        date: new Date(element.year, element.month, element.day),
                        dayPartTypeId: element.dayPartId,
                        id: 0
                    };
                    profile.planificationPhaseProfileWorkDays.push(newObject);
                } else {
                    profile.planificationPhaseProfileWorkDays.push(oldVal[0]);
                }
            });
        }
        this.setDateforProfile(profile);
        this.setProfileTotalRate(profile, this.selectedPlanificationPhaseToUpdate);
        this.setOpeningAndClosingDateForPhase(this.selectedPlanificationPhaseToUpdate);
    }

    private setProfileTotalRate(profile: IPlanificationPhaseProfile, phase: IPlanificationPhase): void {
        let rate = 0;
        const now = new Date(phase.openingDate as string);
        let eRole = this.employeeRoles?.find((x) => x.id === profile.employeeRoleId);
        if (profile.employee != null) {
            if (this.employeeRoles != null) {
                if (profile.employee.histos == null && profile.employee.employeeRoleId != null) {
                    eRole = this.employeeRoles.find((x) => x.id === profile.employee.employeeRoleId);
                } else {
                    const r = profile.employee?.histos?.find(
                        (x) =>
                            (isEqual(new Date(x.startDate), now) || isBefore(new Date(x.startDate), now)) &&
                            isAfter(new Date(x.endDate), now)
                    );
                    if (r != null) {
                        eRole = this.employeeRoles.find((x) => x.id === r?.employeeRoleId);
                    }
                }
            }
        }
        const filtered = eRole?.histos.find(
            (x) =>
                (isEqual(new Date(x.startDate), now) || isBefore(new Date(x.startDate), now)) &&
                isAfter(new Date(x.endDate), now)
        );
        if (filtered && filtered.rate) {
            rate = filtered.rate;
        } else {
            rate = eRole?.rate ?? 0;
        }
        profile.totalRate = rate * (profile.planificationPhaseProfileWorkDays?.length ?? 0);
    }

    private dateDisabled(ymd, date: Date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        const weekday = date.getDay();
        // Return `true` if the date should be disabled
        return weekday === 0 || weekday === 6;
    }

    private async mounted(): Promise<void> {
        vxm.app.changeTitleMain("Planification d'affaires");
        this.currentMonth = this.defaultDate.getMonth();
        this.currentYear = this.defaultDate.getFullYear();
        await vxm.referential.fetchEmployeeRoles();
        if (this.selectedProjectId && this.selectedProjectId > 0) {
            await this.getPlanificationPhases(this.selectedProjectId);
            await this.checkIsStudioManger(this.selectedProjectId);
        }
        const studioCallData = await studioApi.getAllBase();

        if (isCallValidAndNotCancelled<IStudio[]>(studioCallData)) {
            this.studios = studioCallData?.datas;
        }
    }

    setPhaseToUpdate(phaseId: number): void {
        if (this.planificationPhases && this.planificationPhases.length > 0 && phaseId != null) {
            const phase = this.planificationPhases.find((x) => x.id === phaseId);
            if (phase) {
                this.selectedPlanificationPhaseToUpdate = deepCopy<IPlanificationPhase>(phase);
                this.setOpeningAndClosingDateForPhase(this.selectedPlanificationPhaseToUpdate);
                (this.$refs['plan-modal'] as any).show();
            }
        }
    }

    async savePhase(item: IPlanificationPhase): Promise<void> {
        let itemIndex: NU<number> = -1;
        itemIndex = this.planificationPhases?.findIndex((x) => x.id === item.id);
        if (itemIndex != null && itemIndex > -1) {
            this.planificationPhases?.splice(itemIndex, 1, item);
        }
        await this.savePlanificationPhases();
        (this.$refs['plan-modal'] as any).hide();
    }

    isDateValid(item: any): boolean {
        return isDate(item) || item == null;
    }

    async getEmployeeName(employeeId: NU<string>): Promise<string> {
        const result = await moduleApiGraph.Client.api('/users/' + employeeId)
            .select('displayName')
            .get()
            .then((value) => {
                return value.displayName;
            });
        return result;
    }
}
