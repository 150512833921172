












import { Component, Prop, Vue } from 'vue-property-decorator';
import { moduleApiGraph } from '@t/module-api-graph';
import { IThirdParty } from '@/entity/shared/third-party';

@Component({
    
})

export default class ContactGroupForList extends Vue {
    @Prop({ required: true }) private thirdParty!: IThirdParty;

    private groupId: string = '';

    private async getAdGroupId(thirdPartyLabel: string): Promise<string> {
        const group = await moduleApiGraph.Client.api("/groups/?$filter=displayName+eq+'Ind." + thirdPartyLabel + "'").get();
        if (group && group.value && group.value.length > 0) {
            return group.value[0].id;
        }
        return '';
    }

    private async mounted(): Promise<void> {
        /// A ENLEVER LES COMMENTAIRES lorsqu'il sera possible et utile d'utiliser Graph d'azure AD 
        // this.groupId = await this.getAdGroupId(this.thirdParty.label as string);                
    }
}
