import { render, staticRenderFns } from "./subcontracting-base.vue?vue&type=template&id=f987813e&"
import script from "./subcontracting-base.vue?vue&type=script&lang=ts&"
export * from "./subcontracting-base.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports