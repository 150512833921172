







































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import frenchLocale from 'date-fns/locale/fr';
import { IPlanificationProfileScheduler } from '@/entity/planification/planification-profile-scheduler';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import getDaysInYear from 'date-fns/fp/getDaysInYear/index.js';
import Affectations from './affectations/affectations.vue';
import { vxm } from '@/store';
import { NU } from '@t/type';
import { format, getDaysInMonth, max, min, differenceInDays } from 'date-fns';
import { planificationApi } from '@/wapi/planification-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { ICalendarHalfDay } from '@/entity/shared/calendar-day-picker';
import { IPlanificationPhaseProfile } from '@/entity/planification/planification-phase-profile';
import CalendarRow from './calendar-row.vue';

@Component({
    components: {
        Affectations,
        CalendarRow,
    }
})
export default class RecapCalendar extends Vue {
    /* eslint-disable */ 
    @Prop({ required: true }) planificationProfileSchedulers!: IPlanificationProfileScheduler[];

    private allocationsShown: boolean = false;

    planificationProfileSchedulersItems: IPlanificationProfileScheduler[] = this.planificationProfileSchedulers;
    
    @Watch('planificationProfileSchedulers')
    private updateEditingComment(newItem: IPlanificationProfileScheduler[]) {
        this.planificationProfileSchedulersItems = newItem;
    }

    showAllocations() {
        this.allocationsShown = true;
    }

    hideAllocations() {
        this.allocationsShown = false;
    }

    filterUserIds: string[] = [];
    currentYear = new Date().getFullYear();
    selectedPlanificationPhases: number[] = [];
    
    monthGroups: Array<any> = [
        { id: 0, label: 'Janvier', days: [] },
        { id: 1, label: 'Février', days: [] },
        { id: 2, label: 'Mars', days: [] },
        { id: 3, label: 'Avril', days: [] },
        { id: 4, label: 'Mai', days: [] },
        { id: 5, label: 'Juin', days: [] },
        { id: 6, label: 'Juillet', days: [] },
        { id: 7, label: 'Août', days: [] },
        { id: 8, label: 'Septembre', days: [] },
        { id: 9, label: 'Octobre', days: [] },
        { id: 10, label: 'Novembre', days: [] },
        { id: 11, label: 'Décembre', days: [] }
    ];

    daysInMonths: any = {};
    currentModifiedPhaseId: number = -1;

    private fields: Array<IBootstrapTableColumn> = [
        {
            label: '',
            key: 'empty',
            thStyle: 'text-align:center;',
            tdClass: 'phase-calendar-class',
            stickyColumn: true
        },
        {
            label: 'Phase',
            key: 'contractLineLabel',
            thStyle: 'width:40%; text-align:center; background-color:red',
            tdClass: 'phase-calendar-class',
            stickyColumn: true
        },
        {
            label: 'Début',
            key: 'startDate',
            thStyle: 'width:10%; text-align:center;',
            stickyColumn: true
        },
        {
            label: 'Fin',
            key: 'endDate',
            thStyle: 'width:10%; text-align:center;',
            stickyColumn: true
        },
        {
            label: 'Prévisionnel',
            key: 'getPhaseTotalRate',
            thStyle: 'width:10%; text-align:center;',
            stickyColumn: true
        },
        {
            label: 'Budget',
            key: 'budget',
            thStyle: 'width:10%; text-align:center;',
            stickyColumn: true
        }
    ];

    planificationPhases: NU<IPlanificationPhase[]> = [];

    private daysOfWork: Array<any> = [];

    get planificationPhasesOptions(): ISelectListOption[] {
        const res: ISelectListOption[] = [];
        this.planificationProfileSchedulersItems.forEach((element) => {
            if (res.find((x) => x.id === element.contractLineId) === undefined) {
                res.push({
                    id: element.contractLineId,
                    code: 'CO' + element.contractLineId,
                    label: element.contractLineLabel
                } as ISelectListOption);
            }
        });
        return res;
    }

    get listOfHeaderMonth(): Array<any> {
        // to shift a number of cell in the header as big as the number of mission fields
        const list = [{ id: 12, label: '', days: [{}, {}, {}, {}, {}] }];
        return list.concat(this.monthGroups);
    }

    @Watch('currentYear')
    private fetchDaysOfMonths(year: number) {
        const data: Array<any> = [];
        this.monthGroups.forEach((month) => {
            month.days = [];
            data.push(month);
            for (let j = 0; j < getDaysInMonth(new Date(this.currentYear, month.id, 1)); j++) {
                month.days.push({ id: j + 1, label: j + 1 + '' });
            }
        });
        this.daysInMonths = data;
    }

    get selectedProjectId(): NU<number> {
        return vxm.project.dropdownProject?.id;
    }

    async getPlanificationPhases(projectId: number): Promise<void> {
        const phaseCallData = await planificationApi.getAllPhasesByProjectId(projectId);

        if (isCallValidAndNotCancelled<IPlanificationPhase[]>(phaseCallData)) {
            this.planificationPhases = phaseCallData?.datas;
            this.planificationPhases?.forEach((phase) =>
                phase.planificationPhaseProfiles.forEach((profile) => {
                    // this.getEmployeeName(profile.employeeId).then(value => {
                    //     profile.employeeName = value;
                    // });
                    if (
                        profile.planificationPhaseProfileWorkDays &&
                        profile.planificationPhaseProfileWorkDays.length > 0
                    ) {
                        const defaultSelected = profile.planificationPhaseProfileWorkDays.map((x) => {
                            const d: Date = new Date(x.date);
                            return {
                                year: d.getFullYear(),
                                month: d.getMonth(),
                                day: d.getDate(),
                                dayPartId: x.dayPartTypeId
                            } as ICalendarHalfDay;
                        });
                        const date = new Date(profile.planificationPhaseProfileWorkDays[0].date);
                        profile.calendarPropWorkDays = {
                            defaultSelected: defaultSelected,
                            defaultMonth: date.getMonth(),
                            defaultYear: date.getFullYear()
                        };
                        this.setDateforProfile(profile);
                        profile.selectedDayTypes = ['MOR', 'AFT'];
                    }
                })
            );
        }
    }

    private setDateforProfile(profile: IPlanificationPhaseProfile) {
        const dates = profile.planificationPhaseProfileWorkDays.map((x) => new Date(x.date));
        if (dates && dates.length > 0) {
            profile.startDate = min(dates);
            Vue.set(profile, 'startDate', min(dates));
            profile.endDate = max(dates);
            Vue.set(profile, 'endDate', max(dates));
        }
    }

    private setDateforPlanification(plan: IPlanificationPhase) {
        var finalDates: Date[] = [];
        plan.planificationPhaseProfiles.forEach((p) => {
            var dates: Date[] = p.planificationPhaseProfileWorkDays.map((x) => new Date(x.date));
            if (dates && dates.length > 0) {
                finalDates = finalDates.concat(dates);
            }
        });
        if (finalDates && finalDates.length > 0) {
            plan.openingDate = min(finalDates);
            Vue.set(plan, 'openingDate', min(finalDates));
            plan.closingDate = max(finalDates);
            Vue.set(plan, 'closingDate', max(finalDates));
        }
    }

    getDayFromSTringDate(value: string): number {
        const date = new Date(value);
        return date.getDate();
    }

    get currentFields(): any[] {
        const items = [] as any[];
        const year = this.currentYear;
        // For each day of selected year create a list of unique keys representing the day - ie: 'YYYY-MM-DD'
        // start on january 1st
        const currentProcessingDay = new Date(year, 0, 1);
        let maxIterations = 0;
        while (
            currentProcessingDay.getFullYear() === year &&
            maxIterations <= getDaysInYear(new Date(this.currentYear))
        ) {
            const key = `${new Date(
                currentProcessingDay.getFullYear(),
                currentProcessingDay.getMonth(),
                currentProcessingDay.getDate()
            ).toISOString()}`;
            // add ordered keys to items list
            items.push({
                key,
                label: currentProcessingDay.getDate().toString(),
                thStyle: 'min-width:18px;text-align:center',
                tdClass: 'td-no-border'
            });
            // increment current Day of 1
            currentProcessingDay.setDate(currentProcessingDay.getDate() + 1);
            maxIterations++;
        }
        return items;
    }

    private selectedUserEvent(event: CustomEvent) {
        const idemps: string[] = event.detail.map((x: { id: string }) => x.id);
        if (event && event.detail && event.detail.length > 0) {
            var searchedcontractLineId: number[] = [];
            this.planificationProfileSchedulersItems.forEach(p => {
                if (idemps.includes(p.employeeId)) { 
                    searchedcontractLineId.push(p.contractLineId);
                }   
            });
            this.planificationProfileSchedulersItems.forEach(p => {
                if (searchedcontractLineId.includes(p.contractLineId)) { 
                    if (p.employeeId === '' || idemps.includes(p.employeeId)) { 
                        p.visible = true;
                    } else {
                        p.visible = false;
                    }
                } else {
                    p.visible = false;
                }   
            });
        } else if (event && event.detail && event.detail.length === 0) {
            this.planificationProfileSchedulersItems.forEach(p => {
                if (p.employeeId === '' && p.profileRole === undefined) { 
                    p.visible = true; 
                } else {
                    p.visible = false;
                }   
            }); 
        }
    }

    async mounted() {
        this.fetchDaysOfMonths(this.currentYear);
        if (this.selectedProjectId) {
            await this.getPlanificationPhases(this.selectedProjectId);
        }
        // this.putColorInCell(this.planificationPhases[0]);
    }

    get employeeIds(): string[] {
        return [...new Set(this.planificationProfileSchedulersItems.map(x => x.employeeId.toUpperCase()).filter(x => x !== null && x !== undefined && x.length > 0))];
    }

    get filteredPlanificationProfileSchedulers(): IPlanificationProfileScheduler[] {
        const list = this.planificationProfileSchedulersItems.filter((x) => {
            return x.visible === true || (this.currentModifiedPhaseId >= 0 && x.parentPhaseId === this.currentModifiedPhaseId);
        });

        if (
            this.filterUserIds &&
            this.filterUserIds.length > 0 &&
            this.selectedPlanificationPhases &&
            this.selectedPlanificationPhases.length > 0
        ) {
            return list.filter(
                (x) =>
                    this.filterUserIds.find((y) => y === x.employeeId) !== undefined &&
                    this.selectedPlanificationPhases.find((y) => y === x.contractLineId) !== undefined
            );
        } else if (this.filterUserIds && this.filterUserIds.length > 0) {
            return list.filter((x) => this.filterUserIds.find((y) => y === x.employeeId) !== undefined);
        } else if (this.selectedPlanificationPhases && this.selectedPlanificationPhases.length > 0) {
            return list.filter(
                (x) => this.selectedPlanificationPhases.find((y) => y === x.contractLineId) !== undefined
            );
        }
        return list;
    }

    formatedDate(date: Date): string {
        if (date) {
            return (
                format(new Date(String(date)), 'dd MMMM yyyy', {
                    locale: frenchLocale
                }) ?? ''
            );
        }
        return '';
    }

    isDayOfWeek(month : number, day : number) :boolean {
        var year = this.currentYear;
        var date = new Date(year, month, day);
        var res = date.getDay();
        return (res === 0 || res === 6);
    }

    private formattedCurrency(item: number): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(item);
    }

    collapsePhase(phaseId: number, isCollapsed: boolean): void {
        this.currentModifiedPhaseId = -1;
        this.planificationProfileSchedulersItems.forEach((element) => {
            if (element.phaseId === phaseId) {
                element.collapsedPhase = !isCollapsed;
            }
            if (element.parentPhaseId === phaseId) {
                element.visible = isCollapsed;
            }
        });
    }

    datesEqual(d1: Date, d2: Date): boolean {
        return d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d1.getDate();
    }

    async saveData() {
        var callData = await planificationApi.patchPhases(this.planificationPhases ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Planification',
                variant: 'success',
                solid: true
            });
        }
    }

    markDate(d:Date, item, dayPartTypeMask: number) {
        // on ne marque pas les cases de phase
        if (item.parentPhaseId === 0) {
            return;
        }
        // on ne marque pas les week-ends
        if (d.getDay() === 0 || d.getDay() === 6) {
            return;
        }

        this.currentModifiedPhaseId = item.parentPhaseId;

        var ds = d.toDateString();
        item.planificationPhaseProfileWorkDays = item.planificationPhaseProfileWorkDays
            .filter(i => new Date(i.date).toDateString() !== ds || 
                (new Date(i.date).toDateString() === ds && (i.dayPartTypeId & dayPartTypeMask) != 0 ))

        if ((dayPartTypeMask & 1) === 1){
            item.planificationPhaseProfileWorkDays.push({
                date: d,
                planificationPhaseProfileId: item.profileId,
                dayPartTypeId: 1,
                id: 0
            });
        }
        if ((dayPartTypeMask & 2) === 2){
            item.planificationPhaseProfileWorkDays.push({
                date: d,
                planificationPhaseProfileId: item.profileId,
                dayPartTypeId: 2,
                id: 0
            });
        }

        this.setDateforProfile(item);
        if (this.planificationPhases) {
            var currentPlanification:IPlanificationPhase[] = this.planificationPhases.filter((p) => {
                return p.id === item.parentPhaseId;
            });
            if (currentPlanification !== null && currentPlanification.length > 0) {
                var currentProfile = currentPlanification[0].planificationPhaseProfiles.filter((p) => {
                    return p.id === item.profileId;
                });
                if (currentProfile !== null && currentProfile.length > 0) {
                    currentProfile[0].planificationPhaseProfileWorkDays = currentProfile[0].planificationPhaseProfileWorkDays
                    .filter(i => new Date(i.date).toDateString() !== ds || 
                        (new Date(i.date).toDateString() === ds && (i.dayPartTypeId & dayPartTypeMask) != 0 ))

                    if ((dayPartTypeMask & 1) === 1){
                        currentProfile[0].planificationPhaseProfileWorkDays.push({
                            date: d,
                            planificationPhaseProfileId: item.profileId,
                            dayPartTypeId: 1,
                            id: 0
                        });
                    }
                    if ((dayPartTypeMask & 2) === 2){
                        currentProfile[0].planificationPhaseProfileWorkDays.push({
                            date: d,
                            planificationPhaseProfileId: item.profileId,
                            dayPartTypeId: 2,
                            id: 0
                        });
                    }
                    this.setDateforProfile(currentProfile[0]);
                }
            }
        }

        if (this.planificationPhases && this.planificationPhases.length > 0) {
            var planifToUpdate = this.planificationPhases.find((x) => {
                return x.id === item.parentPhaseId;
            });
            if (planifToUpdate) {
                this.setDateforPlanification(planifToUpdate);
                const parentPhase = this.planificationProfileSchedulersItems.find(x => x.phaseId == item.parentPhaseId);
                if (parentPhase) {
                    parentPhase.startDate = new Date(planifToUpdate.openingDate!).toISOString();
                    Vue.set(parentPhase, 'startDate', parentPhase.startDate);
                    parentPhase.endDate = new Date(planifToUpdate.closingDate!).toISOString();
                    Vue.set(parentPhase, 'endDate', parentPhase.endDate);
                }
            }
        }
    }
    
    selectedPeriod:any = {};
    
    selectPeriod(period) {
        this.selectedPeriod = period;
    }

    removePeriod() {
        if (this.selectedPeriod) {
            const date = new Date(this.selectedPeriod.startDate);
            while(differenceInDays(date, this.selectedPeriod.endDate) <= 0){
                this.markDate(date, this.selectedPeriod.item, 0);
                date.setDate(date.getDate() + 1);
            }
            this.selectedPeriod = undefined;
        }
    }

    emitIdToUpdate(id: number): void {
        this.$emit('phaseToUpdate', id);
    }
  
    handleScroll(event:Event): void{
        const self = this as any;  
        const element = event.target as HTMLElement;
        self.$refs.tabRightTop.scrollLeft = element.scrollLeft;
        self.$refs.tabRightBottom.scrollTop = element.scrollTop;
    }

    get IsLeapYear(): boolean {
        var res = false; 
        if (this.currentYear % 4 !== 0) { 
            res = false; 
        } else if (this.currentYear % 100 !== 0) res = true; 

        else if (this.currentYear % 400 !== 0) res = false; 
        else (res = true);
        return res;
    }
}
