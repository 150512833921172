




















































































import { Component, Vue } from 'vue-property-decorator';
import { ICancellableResult, isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { NU } from '@t/type';
import { vxm } from '@/store';
import { referentialApi } from '@/wapi/referential-api';
import CurrencyInput from '@c/shared/currency-input.vue';
import { IAgency } from '@/entity/project/agency';
import frenchLocale from 'date-fns/locale/fr';
import { format } from 'date-fns';

@Component({
    components: {
        CurrencyInput
    }
})
export default class ReferentialAgency extends Vue {
    agencies: NU<IAgency[]> = [];
    private promiseExport: boolean = false;
    private isBusy: boolean = true;

    get isValidForm(): boolean {
        let res = true;
        this.agencies?.forEach((element) => {
            res = res && element.code !== undefined && element.code.length === 3;
            res = res && element.label !== undefined && element.label !== '';
        });
        return res;
    }

    async getAgencies(): Promise<void> {
        const agenciesCallData = await referentialApi.getAllAgencies();
        if (isCallValidAndNotCancelled<IAgency[]>(agenciesCallData)) {
            if (agenciesCallData?.datas) {
                this.agencies = agenciesCallData.datas;
            }
        }
    }

    private fields: Array<IBootstrapTableColumn | string> = [
        { label: 'Code', key: 'code', thStyle: 'width:200px' },
        { label: 'Description', key: 'label' },
        { label: '', key: 'delete', thStyle: 'width:80px' }
    ];

    async saveAgencies(): Promise<void> {
        const callData = await referentialApi.patchAgencies(this.agencies ?? []);
        if (isCallValidAndNotCancelled(callData)) {
            this.getAgencies();
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: 'Liste des agences',
                variant: 'success',
                solid: true
            });
        }
    }

    addAgency(): void {
        const agency = {} as IAgency;
        this.agencies?.push(agency);
    }

    deleteAgencyLine(item: IAgency): void {
        const index = this.agencies?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.agencies?.splice(index, 1);
        }
    }

    formatterCode(val: string): string {
        if (!val) {
            return val;
        }
        return val.toUpperCase().trim().substring(0, 3);
    }

    async exportAgences(): Promise<void> {
        await this.generateReport(
            referentialApi.exportAgencesData(),
            `Liste_des_agences_${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd', { locale: frenchLocale }) ?? '';
    }

    private async generateReport(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private async mounted() {
        vxm.app.changeTitleMain('Liste des agences');
        this.getAgencies();
        this.isBusy = false;
    }
}
