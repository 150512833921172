

























































































import { IEmployee } from '@/entity/shared/employee';
import { IEmployeeRole } from '@/entity/shared/referential';
import { vxm } from '@/store';
import { employeeApi } from '@/wapi/employee-api';
import { referentialApi } from '@/wapi/referential-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IFilterCache } from '@t/filter-cache-service';
import { NU } from '@t/type';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { IEmployeeFilter, IRoleFilter } from './filter-entities';

@Component
export default class AfffectationFilter extends Vue {
    @Prop({ required: true })
    private employeeIdsOfProject!: string[];

    @Prop({ required: true })
    private rolesOfProject!: IEmployeeRole[];

    @Prop({ required: true })
    private initialProjectFilter: NU<IFilterCache>;

    @Prop({ required: true })
    private isEmployeeTable!: boolean;

    private editedEmployeeFilters: IEmployeeFilter[] = [];
    private editedRoleFilters: IRoleFilter[] = [];
    private loading: boolean = false;

    private forCurrentProjectOnly: boolean = false;

    async created(): Promise<void> {
        await this.fetchData();
        this.employeeFiltersOnChange(this.employeeIdsOfProject);
        this.roleFiltersOnChange(this.rolesOfProject);
        this.initialProjectFilterOnChange(this.initialProjectFilter);
    }

    @Watch('employeeFilters')
    employeeFiltersOnChange(newVal: string[]): void {
        newVal.forEach((x) => {
            const current = this.editedEmployeeFilters.find((y) => y.employeeId === x);
            if (current) {
                current.selected = this.initialProjectFilter == null || this.initialProjectFilter.employeeIds.includes(x);
            }
        });
    }

    @Watch('initialProjectFilter')
    initialProjectFilterOnChange(newVal: NU<IFilterCache>): void {
        this.editedRoleFilters.forEach(x => {
            if (newVal == null || newVal.roles.findIndex(y => y.id === x.role.id) >= 0) {
                x.selected = true;
            }
        });
        this.editedEmployeeFilters.forEach(x => {
            if (newVal == null || newVal.employeeIds.includes(x.employeeId)) {
                x.selected = true;
            }
        });
    }

    @Watch('roleFilters')
    roleFiltersOnChange(newVal: IEmployeeRole[]): void {
        newVal.forEach((x) => {
            const current = this.editedRoleFilters.find((y) => y.role.id === x.id);
            if (current) {
                current.selected = this.initialProjectFilter == null || this.initialProjectFilter.roles.findIndex(y => y.id === x.id) >= 0;
            }
        });
    }

    async fetchData(): Promise<void> {
        this.loading = true;
        await this.fetchEmployees();
        await this.fetchRoles();
        this.loading = false;
    }

    async fetchRoles(): Promise<void> {
        var employeeRoles = vxm.referential.employeeRoles;
        if (!employeeRoles || employeeRoles.length === 0) {
            const employeeRolesCall = await referentialApi.getAllEmployeeRoles();
            if (isCallValidAndNotCancelled(employeeRolesCall)) {
                employeeRoles = employeeRolesCall!.datas!;
            } else {
                employeeRoles = [];
            }
        }
        this.editedRoleFilters = employeeRoles.map((x) => {
            return {
                role: x,
                selected: false
            };
        });
    }

    async fetchEmployees(): Promise<void> {
        const employeeCall = await employeeApi.getAllBase();
        var employees: IEmployee[] = [];
        if (isCallValidAndNotCancelled(employeeCall)) {
            employees = employeeCall!.datas!;
        }
        this.editedEmployeeFilters = employees.map((x) => {
            return {
                employeeId: x.id,
                selected: false
            };
        });
    }

    selectAllEmployees(value: boolean): void {
        this.employeeFiltersToShow.forEach((x) => {
            x.selected = value;
        });
    }

    selectAllRole(value: boolean): void {
        this.employeeRoleFiltersToShow.forEach((x) => {
            x.selected = value;
        });
    }

    isRoleInCurrentProject(roleFilter: IRoleFilter): boolean {
        return this.rolesOfProject.some((x) => x.id === roleFilter.role.id);
    }

    isEmployeeInCurrentProject(employeeFilter: IEmployeeFilter): boolean {
        return this.employeeIdsOfProject.some((x) => x === employeeFilter.employeeId);
    }

    submitFilter(): void {
        this.$emit('filter-update', {
            employeeIds: this.selectedEmployeeFilters,
            roles: this.selectedRoleFilters
        });
    }

    cancel(): void {
        this.$emit('cancel-filter');
    }

    get employeeRoleFiltersToShow(): IRoleFilter[] {
        if (this.forCurrentProjectOnly) {
            return this.editedRoleFilters.filter(x => this.isRoleInCurrentProject(x));
        }
        return this.editedRoleFilters;
    }

    get employeeFiltersToShow(): IEmployeeFilter[] {
        if (this.forCurrentProjectOnly) {
            return this.editedEmployeeFilters.filter(x => this.isEmployeeInCurrentProject(x));
        }
        return this.editedEmployeeFilters;
    }

    get selectedEmployeeFilters(): IEmployeeFilter[] {
        return this.editedEmployeeFilters.filter((x) => x.selected);
    }

    get everyEmployeeSelected(): boolean {
        return this.editedEmployeeFilters.every((x) => x.selected);
    }

    get noEmployeeSelected(): boolean {
        return this.editedEmployeeFilters.every((x) => !x.selected);
    }

    get selectedRoleFilters(): IRoleFilter[] {
        return this.editedRoleFilters.filter((x) => x.selected);
    }

    get everyRoleSelected(): boolean {
        return this.editedRoleFilters.every((x) => x.selected);
    }

    get noRoleSelected(): boolean {
        return this.editedRoleFilters.every((x) => !x.selected);
    }
}
