




import { Component, Prop, Vue } from 'vue-property-decorator';
import Chart, { ChartConfiguration, ChartData, ChartOptions } from 'chart.js';

@Component({})
export default class ChartBase extends Vue {
    @Prop({ required: true }) readonly labels!: Array<string>;
    @Prop({ required: true }) readonly data!: Array<number>;
    @Prop({ required: true }) readonly title!: string;
    @Prop({ default: [] }) readonly colors!: Array<string>;
    @Prop({
        default: () => {
            return Chart.defaults.horizontalBar;
        }
    })
    readonly options!: ChartOptions;

    mounted(): void {
        Chart.defaults.global.defaultFontFamily = "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'";
        this.createChart({
            datasets: [
                {
                    data: this.data,
                    backgroundColor: this.colors,
                    categoryPercentage: 0.5,
                    barPercentage: 1,
                }
            ],
            labels: this.labels
        });
    }

    // return for lint acceptance
    createChart(chartData: ChartData): Chart {
        const canvas = document.getElementById(`canvas_${this.title}`) as HTMLCanvasElement;
        const options: ChartConfiguration = {
            type: 'horizontalBar',
            data: {
                ...chartData
            },
            options: {
                ...this.options,
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(tooltipItem.value! as unknown as number);
                        }
                    }
                },
                scales: {
                    xAxes: [
                        { 
                            ticks: {
                                min: 0,
                                callback: function(value: string | number) {
                                    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value as number);
                                },
                            },
                            gridLines: {
                                display: false
                            }
                        }
                    ],
                    yAxes: [
                        {
                            
                            ticks: {
                                mirror: true,
                            },
                            gridLines: {
                                display: false
                            }
                        }
                    ]
                },
                legend: {
                    display: false
                },
                title: {

                    display: true,
                    text: this.title,
                    fontSize: 24,
                    fontStyle: 'lighter',
                    position: 'bottom',
                    fontFamily: "'-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'"
                }
            },
            
        };
        const chart = new Chart(canvas, options);
        return chart;
    }
}
