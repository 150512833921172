






import { Component } from 'vue-property-decorator';
import RHBaseClass from '../rh-class-base.vue';

@Component
export default class LeaveBase extends RHBaseClass {
    
}
