



































































































import { Component, Watch } from 'vue-property-decorator';
import ImputationList from './imputation-list.vue';
import ImputationCalendarSummary from './imputation-calendar-summary.vue';
import ImputationContractLineItem from './imputation-contract-line-item.vue';
import MonthPicker from '@/components/shared/month-picker.vue';
import { authModule } from '@t/session';
import { moduleApiGraph } from '@t/module-api-graph';
import RHBaseClass from '../rh-class-base.vue';
import { NU } from '@t/type';
import { vxm } from '@/store';
import { contractLineApi, imputationApi } from '@/wapi/imputation-api';
import { IImputationWorkflow, IImputationWorkflowItem } from '@/entity/rh/workflow';
import { IReferential } from '@/entity/shared/referential';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IProject } from '@/entity/project/project';
import { IContractLine } from '@/entity/contract/contract-line';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import ImputationGrid from './imputation-grid.vue';

@Component({
    components: {
        ImputationList,
        ImputationCalendarSummary,
        MonthPicker,
        ImputationContractLineItem,
        ImputationGrid
    }
})
export default class UserImputation extends RHBaseClass {
    private selectedMonth: Date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    private employeeIdentifier!: string;
    private currentUserData: NU<any> = null;
    private daysOfSelectedMonth: string[] = [];
    private currentWorkflow: NU<IImputationWorkflow> = null;
    private loading = true;
    private request: NU<Promise<any>> = null;
    private loadingSave: boolean = false;
    private filterData: string = '';
    
    private projects: IProject[] = [];
    private projectsLastMonth: IProject[] = [];
    private projectsIterration: Array<IProject[]> = [];
    private selectedProjectId: NU<number> = null;
    private selectedContractLineId: NU<number> = null;
    private contractLineInWorkflow: NU<IContractLine[]> = [];
    private projectDesignations: NU<string[]> = [];
    private linesSubmitable: boolean[] = [];
    private daySums: number[] = [];
    private errors: boolean[] = [];
    private showCalendar: boolean = false;

    async changeMonth(newDate: Date): Promise<void> {
        if (this.request !== null) {
            await this.request;
            this.request = null;
        }
        this.selectedMonth = newDate;
    }

    async created(): Promise<void> {
        this.employeeIdentifier = await authModule.getAccount()!.localAccountId!;
        this.currentUserData = await moduleApiGraph.Client.api(`/users/${this.id}`).get();
        vxm.app.changeTitleMain(this.employeeIdentifier === this.id ? 'Vos Imputations' : 'Imputations');

        vxm.app.changeTitleExt(
            this.employeeIdentifier !== this.id ? `${this.currentUserData ? this.currentUserData.displayName : ''}` : ''
        );

        this.daysOfSelectedMonth = this.setDaysOfSelectedMonth();
        await vxm.referential.fetchDayPartTypes();
        await this.fetchInfosCurrentMonth();
    }

    updateCalendarValue(): boolean {
        this.showCalendar = !this.showCalendar;
        return this.showCalendar;
    }

    setDaysOfSelectedMonth(): string[] {
        var date = new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), 1);
        const result: string[] = [];
        while (date.getMonth() === this.selectedMonth.getMonth()) {
            result.push(date.getDate() + '');
            date.setDate(date.getDate() + 1);
        }
        return result;
    }

    @Watch('selectedMonth')
    async updateDaysOfMonth(): Promise<void> {
        this.selectedProjectId = null;
        this.selectedContractLineId = null;
        this.daysOfSelectedMonth = this.setDaysOfSelectedMonth();
        await this.fetchInfosCurrentMonth();
    }

    async fetchInfosCurrentMonth(): Promise<void> {
        this.loading = true;
        await this.fetchContractLines();
        await this.fetchWorkflow();
        this.loading = false;
    }

    @Watch('selectedProjectId')
    resetContractLineId(newVal: number | null): void {
        if (newVal === null) {
            return;
        } 
        const contractLines = this.projects
            .find(x => x.id === newVal)!.contracts!
            .map(x => x.contractLines)
            .flat();
        if (contractLines.length === 1 && contractLines[0] !== undefined) {
            this.selectedContractLineId = contractLines[0].id;
        } 
    }

    get projectOptions(): ISelectListOption[] {
        return this.projects.map(x => {
            return {
                id: x.id,
                code: x.trigram,
                label: x.designation
            };
        });
    }

    addNewContractLine(): void {
        const project = this.projects[this.selectedProjectId! - 1];
        for (let h = 0; h < this.projectsIterration.length; h++) {
            const allProjects = this.projectsIterration[h];
            for (let g = 0; g < allProjects.length; g++) {
                const project = allProjects[g];
                for (let i = 0; i < project.contracts!.length; i++) {
                    const contract = project.contracts![i];
                    for (let j = 0; j < contract.contractLines!.length; j++) {
                        const contractLine = contract.contractLines![j];
                        if (contractLine.id === this.selectedContractLineId) {
                            if (this.contractLineInWorkflow?.find(c => c.id === contractLine.id) === undefined) {
                                this.contractLineInWorkflow?.push(contractLine);
                                this.projectDesignations?.push(project.designation);
                            }
                            this.selectedProjectId = null;
                            this.selectedContractLineId = null;
                        }        
                    }
                }
            }   
        }
    }

    get contractLineOptions(): ISelectListOption[] {
        const result: ISelectListOption[] = [];
        if (this.selectedProjectId === null) {
            return result;
        }
        const project = this.projects.find(x => x.id === this.selectedProjectId);
        project?.contracts?.map(x => {
            for (let i = 0; i < x.contractLines!.length; i++) {
                result.push({
                    id: x.contractLines![i].id,
                    code: x.contractLines![i].designation!,
                    label: x.contractLines![i].designation!
                });
            }
        });
        return result;
    }

    get fieldsDisabled(): boolean {
        return (
            this.loading ||
            this.loadingSave ||
            (this.currentWorkflow !== null && this.currentWorkflow !== undefined && this.currentWorkflow.isSubmitted)
        );
    }

    get filteredContractLine(): IContractLine[] {
        const result: IContractLine[] = [];
        this.projects
            .filter(x => x.designation.includes(this.filterData))
            .map(x => x.contracts!
                .map(y => y.contractLines!
                    .map(z => result.push(z))
                )
            );
        return result;
    }

    async fetchContractLines(): Promise<void> {
        var year = this.selectedMonth.getFullYear();
        var month = this.selectedMonth.getMonth();
        if (month === 0) {
            year--; 
            month = 12;
        }
        this.projects = [];
        this.projectsLastMonth = [];
        const projectsData = await contractLineApi.getContractLineByMonth(
            this.id + '',
            this.selectedMonth.getFullYear(),
            this.selectedMonth.getMonth() + 1
        );
        const projectDataLastMonth = await contractLineApi.getContractLineByMonth(
            this.id + '',
            year,
            month
        );
        if (isCallValidAndNotCancelled(projectsData)) {
            const projects = projectsData.datas!;
            this.getCurrentLines(projects);
            this.projects = projects;
        }
        if (isCallValidAndNotCancelled(projectDataLastMonth)) {
            const projects = projectDataLastMonth.datas!;
            this.getCurrentLines(projects);
            this.projectsLastMonth = projects;
        }
        this.projectsIterration = [];
        this.projectsIterration.push(this.projects);
        this.projectsIterration.push(this.projectsLastMonth);
    }

    getCurrentLines(projects: IProject[]) {
        const morPartDay = this.dayPartTypes.find(x => x.code === 'MOR')!;
        const aftPartDay = this.dayPartTypes.find(x => x.code === 'AFT')!;
        const chaPartDay = this.dayPartTypes.find(x => x.code === 'CHA')!;
        for (let i = 0; i < projects.length; i++) {
            const curProject = projects[i];
            for (let j = 0; j < curProject.contracts!.length; j++) {
                const curContract = curProject.contracts![j];
                for (let k = 0; k < curContract.contractLines!.length; k++) {
                    const curLine = curContract.contractLines![k];
                    curLine.imputations = [];
                    for (let j = 0; j < this.daysOfSelectedMonth.length; j++) {
                        const day = Number(this.daysOfSelectedMonth[j]);
                        curLine.imputations[day - 1] = [
                            {
                                workflowId: 0,
                                isTeleworking: false,
                                dayPartTypeId: morPartDay.id,
                                dayPartType: morPartDay,
                                value: 0,
                                date: new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), day, 3, 0, 0),
                                contractLineId: curLine.id,
                                id: 0
                            },
                            {
                                workflowId: 0,
                                isTeleworking: false,
                                dayPartTypeId: aftPartDay.id,
                                dayPartType: aftPartDay,
                                value: 0,
                                date: new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), day, 3, 0, 0),
                                contractLineId: curLine.id,
                                id: 0
                            },
                            {
                                workflowId: 0,
                                isTeleworking: false,
                                dayPartTypeId: chaPartDay.id,
                                dayPartType: chaPartDay,
                                value: 0,
                                date: new Date(this.selectedMonth.getFullYear(), this.selectedMonth.getMonth(), day, 3, 0, 0),
                                contractLineId: curLine.id,
                                id: 0
                            }
                        ];
                    }
                }
            }
        }
    }

    async fetchWorkflow(): Promise<void> {
        const result = await imputationApi.getImputationsByEmployeeIdentifierAndPeriod(
            this.id + '',
            this.selectedMonth.getFullYear(),
            this.selectedMonth.getMonth() + 1
        );
        this.contractLineInWorkflow = [];
        if (isCallValidAndNotCancelled(result)) {
            this.currentWorkflow = result.datas!;
            // Il existe un workflow pour le mois courant
            if (this.currentWorkflow.elements?.length !== 0) {
                for (let i = 0; i < this.currentWorkflow.elements.length; i++) {
                    const dayPartToAdd: IImputationWorkflowItem = this.currentWorkflow.elements[i];
                    dayPartToAdd.date = new Date(dayPartToAdd.date);
                    let contractLine: IContractLine | null = null;
                    for (let h = 0; h < this.projectsIterration.length; h++) {
                        const arrayProjects = this.projectsIterration[h];
                        for (let j = 0; j < arrayProjects.length; j++) {
                            const curPorject = arrayProjects[j];
                            for (let k = 0; k < curPorject.contracts!.length; k++) {
                                const curContract = curPorject.contracts![k];
                                contractLine = curContract.contractLines!.find(x => x.id === dayPartToAdd.contractLineId) ?? null;
                                if (contractLine !== null) {
                                    break;
                                }
                            }
                            this.projectDesignations?.push(curPorject.designation);
                            if (contractLine !== null) {
                                break;
                            }
                        }
                        if (contractLine !== null) {
                            break;
                        }
                    }
                    if (contractLine !== null) {
                        const dayToUpdate = contractLine!.imputations![dayPartToAdd.date.getDate() - 1];
                        const dayPartIndex = dayToUpdate.findIndex((x) => x.dayPartType.code === dayPartToAdd.dayPartType.code);
                        dayToUpdate[dayPartIndex].value = dayPartToAdd.value;
                        dayToUpdate[dayPartIndex].isTeleworking = dayPartToAdd.isTeleworking;
                        if (this.contractLineInWorkflow.find(x => x === contractLine) === undefined) {
                            this.contractLineInWorkflow.push(contractLine);
                        }
                    }
                }
            } else {
                var year = this.selectedMonth.getFullYear();
                var month = this.selectedMonth.getMonth();
                if (month === 0) {
                    year--; 
                    month = 12;
                }
                const resultLastMonth = await imputationApi.getImputationsByEmployeeIdentifierAndPeriod(
                    this.id + '',
                    year,
                    month
                );
                if (isCallValidAndNotCancelled(resultLastMonth)) {
                    const workflowLastMonth = resultLastMonth.datas!;
                    if (workflowLastMonth.elements?.length !== 0) {
                        for (let l = 0; l < workflowLastMonth.elements.length; l++) {
                            for (let i = 0; i < this.projectsLastMonth.length; i++) {
                                const project = this.projectsLastMonth[i];
                                for (let j = 0; j < project.contracts!.length; j++) {
                                    const contract = project.contracts![j];
                                    for (let k = 0; k < contract.contractLines!.length; k++) {
                                        const line = contract.contractLines![k];
                                        const element = workflowLastMonth.elements[l];
                                        if (element.contractLineId === line.id && this.contractLineInWorkflow.indexOf(line) === -1) {
                                            this.contractLineInWorkflow.push(line);
                                        }
                                    }
                                }
                                this.projectDesignations?.push(project.designation);
                            }
                        }
                    }
                }
            }
        }
    }

    async fetchLastMonthWorkFlow() : Promise<IImputationWorkflow> {    
        var workFlowLastMonth!: IImputationWorkflow;
        var year = this.selectedMonth.getFullYear();
        var month = this.selectedMonth.getMonth();
        if (month === 0) {
            year--; 
            month = 12;
        }
        const result = await imputationApi.getImputationsByEmployeeIdentifierAndPeriod(
            this.id + '',
            year,
            month
        );
        if (isCallValidAndNotCancelled(result)) {
            workFlowLastMonth = result.datas!;
        }
        return workFlowLastMonth;
    }

    get dayPartTypes(): IReferential[] {
        return vxm.referential.dayPartTypes!;
    }

    async sendSaveRequest(submit: boolean = true): Promise<void> {
        this.loadingSave = true;
        const imputations: IImputationWorkflowItem[] = [];
        for (let contractLineIndex = 0; contractLineIndex < this.contractLineInWorkflow!.length; contractLineIndex++) {
            const contractLine = this.contractLineInWorkflow![contractLineIndex];
            for (let dayIndex = 0; dayIndex < this.daysOfSelectedMonth.length; dayIndex++) {
                const imputationDay = contractLine.imputations![dayIndex];
                for (let dayPartIndex = 0; dayPartIndex < imputationDay.length; dayPartIndex++) {
                    const dayPart = imputationDay[dayPartIndex];
                    if (
                        this.dayPartTypes!.map((x) => x.code).findIndex((x) => x === dayPart.dayPartType.code) !== -1 &&
                        dayPart.value > 0 &&
                        dayPart.value <= 3
                    ) {
                        dayPart.value = Number(dayPart.value);
                        dayPart.dayPartTypeId = dayPart.dayPartType.id;
                        imputations.push(dayPart);
                    }
                }
            }
        }
        const workflowId: number | null = await imputationApi
            .insertImputations({
                elements: imputations as IImputationWorkflowItem[],
                id: this.currentWorkflow!.id,
                employee: {
                    id: this.id
                } as any,
                triggeredBy: {
                    id: this.employeeIdentifier
                } as any,
                validators: this.currentWorkflow!.validators,
                isSubmitted: !submit,
            })
            .then((response) => response.datas);
        if (workflowId !== null && workflowId !== 0) {
            this.$bvToast.toast('Enregistrement effectué avec succès', {
                title: `Imputations: Mois de ${this.selectedMonth.toLocaleString('fr-FR', { month: 'long', year: 'numeric' })}`,
                variant: 'success',
                solid: true
            });
            this.currentWorkflow!.isSubmitted = !submit;
            this.currentWorkflow!.id = workflowId;
        } else {
            this.$bvToast.toast('Une erreur s\'est produite.', {
                title: `Imputations: Mois de ${this.selectedMonth.toLocaleString('fr-FR', { month: 'long', year: 'numeric' })}`,
                variant: 'error',
                solid: true
            });
        }
        this.loadingSave = false;
    }

    updateSubmitable(isSubmitable: boolean[]) {
        this.linesSubmitable = isSubmitable;
    }

    updateSums(sums: number[]) {
        this.daySums = sums; 
    }

    updateErrors(error: boolean[]) {
        this.errors = error;
    }

    get isSubmittable(): boolean {
        if (this.currentWorkflow == null || this.currentWorkflow.isSubmitted || this.linesSubmitable.indexOf(true) !== -1 || this.loadingSave || 
        this.contractLineInWorkflow?.length === 0 || this.contractLineInWorkflow === undefined || this.contractLineInWorkflow === null) {
            return false;
        } else {
            return true;
        }
    }

    save(): void {
        this.sendSaveRequest();
    }
}
