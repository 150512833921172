






























import { Component, Prop, Vue } from 'vue-property-decorator';

import BaseLoader from '@/components/shared/base-loader.vue';
import { ISignature } from '@/entity/pdf/pdf-signature';

@Component({
    components: {
        BaseLoader
    }
})
export default class FeePdfCard extends Vue {
    @Prop({ required: false, type: Boolean }) private generatingPdf?: boolean;
    @Prop({ required: false }) private fees?: string;

    private parametersVisible: boolean = true;

    private ComptabilityImg = require('@/assets/sign-comptability.png');
    private RichezImg = require('@/assets/sign-richez.png');

    private selectedSignatureCode: string = '';

    get selectedSignatureCodeInput(): string {
        return this.selectedSignatureCode;
    }

    set selectedSignatureCodeInput(value: string) {
        this.selectedSignatureCode = value;
        const signature = this.signatures.find(s => s.value === this.selectedSignatureCode);
        this.$emit('change:signature', signature ?? null);
    }

    private signatures: ISignature[] = [
        {
            id: 1,
            value: 'compatibility',
            text: 'Comptabilité',
            img: this.ComptabilityImg
        },
        {
            id: 2,
            value: 'richez',
            text: 'Thomas Richez',
            img: this.RichezImg
        }
    ];

    private generatePdf() {
        this.$emit('generate-pdf');
    }

    private goBack() {
        this.$emit('go-back');
    }

    private toggleParameters() {
        this.parametersVisible = !this.parametersVisible;
    }
}
