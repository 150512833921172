











































































































































































































import { IPurchaseOrder } from '@/entity/purchase-order/purchase-order';
import { IAgency } from '@/entity/project/agency';
import { purchaseOrderApi } from '@/wapi/purchase-order-api';
import InputElement from '@c/shared/input-element.vue';
import CurrencyInput from '@c/shared/currency-input.vue';
import PercentageInput from '@c/shared/percentage-input.vue';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IPurchaseOrderLine } from '@/entity/purchase-order/purchase-order-line';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { vxm } from '@/store';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { NU } from '@t/type';
import { IReferential } from '@/entity/shared/referential';
import { format } from 'date-fns';
import frenchLocale from 'date-fns/locale/fr';
import { ISupplier } from '@/entity/shared/supplier';
import { supplierApi } from '@/wapi/supplier-api';

@Component({
    components: {
        InputElement,
        CurrencyInput,
        PercentageInput
    }
})
export default class PurchaseOrderInformation extends Vue {
    @Prop({ required: true }) id!: number;
    @Prop({ required: true }) projectId!: string;

    private suppliers: NU<ISupplier[]> = [];
    savePending: boolean = false;

    get isReadOnly(): boolean {
        return vxm.project.projectData?.isSale ?? false;
    }

    get orderStatusList(): NU<IReferential[]> {
        return vxm.referential.orderStatus;
    }

    get contractLines(): NU<ISelectListOption[]> {
        const selectContractLinesList: NU<ISelectListOption[]> = [];
        vxm.project.contractsList?.forEach((y) => {
            const res: NU<ISelectListOption[]> = y.contractLines?.map((x, index) => {
                const Contractline: ISelectListOption = {
                    id: x.id,
                    code: 'CO' + index,
                    label: x.designation ?? ''
                };
                return Contractline;
            });
            selectContractLinesList?.push(...(res ?? []));
        });
        return selectContractLinesList;
    }

    get agencies(): NU<IAgency[]> {
        return vxm.project.projectData?.agencies;
    }

    get formatedCreationDate(): string {
        return format(new Date(String(this.currentPurchaseOrder?.creationDate)), 'EEEE dd MMMM yyyy à HH:mm:ss', { locale: frenchLocale }) ?? '';
    }

    get formatedModificationDate(): string {
        return format(new Date(String(this.currentPurchaseOrder?.modificationDate)), 'EEEE dd MMMM yyyy à HH:mm:ss', { locale: frenchLocale }) ?? '';
    }

    get isValidForm(): boolean {
        let res = true;
        this.currentPurchaseOrder?.purchaseOrderLines?.forEach(element => {
            res = res && element.contractLineId !== undefined && element.contractLineId !== null && element.contractLineId > 0;
            res = res && element.designation !== undefined && element.designation !== '';
        });
        return res;
    }

    private currentPurchaseOrder: NU<IPurchaseOrder> = {} as IPurchaseOrder;

    private purchaseOrderFields: IBootstrapTableColumn[] = [
        { key: 's', sortable: false, thStyle: 'max-width: 100px;', tdClass: 'td-s-width' },
        { label: 'Mission', key: 'contractLine', sortable: false, thStyle: 'min-width:230px' },
        { label: 'Désignation', key: 'designation', sortable: false, thStyle: 'min-width:230px' },
        { label: 'Quantité', key: 'quantity', sortable: false, thStyle: 'width:80px' },
        { label: 'Prix HT(€)', key: 'priceExcTax', sortable: false },
        { label: 'Montant HT(€)', key: 'total', sortable: false },
        { key: 'delete', sortable: false, thStyle: 'max-width: 50px;', tdClass: 'td-delete-width' }
    ];

    private async mounted(): Promise<void> {
        await vxm.referential.fetchOrderStatus();

        const supplierCallData = await supplierApi.getAllByCode('SPL');

        if (isCallValidAndNotCancelled<ISupplier[]>(supplierCallData)) {
            this.suppliers = supplierCallData?.datas;
        }
        this.getPurchaseOrder(this.id);
    }

    private async getPurchaseOrder(id: number): Promise<void> {
        if (id && id !== 0) {
            const datas = await purchaseOrderApi.getBase(id + '');
            if (isCallValidAndNotCancelled<IPurchaseOrder | null>(datas)) {
                this.currentPurchaseOrder = datas?.datas;
            }
        }
    }

    @Watch('id')
    switchPurchaseOrderById(newVal: number, _oldVal: number): void {
        if (newVal) {
            this.getPurchaseOrder(newVal);
        }
    }

    @Watch('currentPurchaseOrder.purchaseOrderLines')
    private assignPurchaseOrderLines(_newVal: IPurchaseOrderLine[], _oldVal: IPurchaseOrderLine[]) {
        this.calculatetotalExcTax();
    }

    private calculatetotalExcTax(): void {
        if (this.currentPurchaseOrder && this.currentPurchaseOrder.purchaseOrderLines) {
            const totales = this.currentPurchaseOrder.purchaseOrderLines.map((x) => x.total);
            if (totales && totales.length === 0) {
                this.currentPurchaseOrder.totalExcTax = 0;
            } else if (totales && totales.length === 1) { 
                this.currentPurchaseOrder.totalExcTax = totales[0];
            } else {
                this.currentPurchaseOrder.totalExcTax = Number(totales.reduce((a: number, b: number) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b)).toFixed(2));
            }
            this.currentPurchaseOrder.total = Number((this.currentPurchaseOrder.totalExcTax + (this.currentPurchaseOrder.totalExcTax * this.currentPurchaseOrder.tax) / 100).toFixed(2));
        }
    }

    get totalExcTaxFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(this.currentPurchaseOrder?.totalExcTax ?? 0);
    }

    get taxFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(
            Number(this.currentPurchaseOrder?.totalExcTax) * ((Number(this.currentPurchaseOrder?.tax) / 100)) ?? 0
        );
    }

    get totalFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { minimumFractionDigits: 2 }).format(this.currentPurchaseOrder?.total ?? 0);
    }

    onRecalculateLineTotal(item: IPurchaseOrderLine): void {
        if (item && item.quantity !== null && item.quantity !== undefined && item.priceExcTax !== null && item.priceExcTax !== undefined) {
            item.total = Number((item.quantity * item.priceExcTax).toFixed(2));
            this.calculatetotalExcTax();
        }
    }

    @Watch('currentPurchaseOrder.tax')
    private assignTax(newVal: number, _oldVal: number) {
        if (this.currentPurchaseOrder) {
            this.currentPurchaseOrder.total = Number((this.currentPurchaseOrder.totalExcTax + (this.currentPurchaseOrder.totalExcTax * newVal) / 100).toFixed(2));
        }
    }

    async savePurchaseOrder(): Promise<void> {
        if (this.currentPurchaseOrder) {
            this.savePending = true;
            this.updateLinePositionNumber();
            const callData = await purchaseOrderApi.patchBase(this.currentPurchaseOrder.id + '', this.currentPurchaseOrder);
            if (isCallValidAndNotCancelled(callData)) {
                this.getPurchaseOrder(this.id);
                await vxm.project.updatePurchaseOrders(this.projectId);
                this.$bvToast.toast('Enregistrement effectué avec succès', {
                    title: `Bon de commande: ${this.currentPurchaseOrder.reference}`,
                    variant: 'success',
                    solid: true
                });
            }
            this.savePending = false;
        }
    }

    addPurchaseOrderLine(): void {
        const lineToAdd: IPurchaseOrderLine = {
            id: 0,
            purchaseOrderId: this.currentPurchaseOrder?.id ?? 0,
            positionNumber: (this.currentPurchaseOrder?.purchaseOrderLines?.length ?? 0) + 1,
            priceExcTax: 0,
            quantity: 1,
            contractLineId: null,
            total: 0
        };
        this.currentPurchaseOrder?.purchaseOrderLines?.push(lineToAdd);
    }

    deletePurchaseOrderLine(item: IPurchaseOrderLine): void {
        const index = this.currentPurchaseOrder?.purchaseOrderLines?.indexOf(item);
        if (index !== undefined && index > -1) {
            this.currentPurchaseOrder?.purchaseOrderLines?.splice(index, 1);
        }
    }

    sortDown(item: IPurchaseOrderLine): void {
        const index = this.currentPurchaseOrder?.purchaseOrderLines?.indexOf(item);
        if (index !== undefined && index > -1 && this.currentPurchaseOrder && this.currentPurchaseOrder.purchaseOrderLines) {
            this.currentPurchaseOrder?.purchaseOrderLines?.splice(index + 1, 0, this.currentPurchaseOrder?.purchaseOrderLines.splice(index, 1)[0]);
        }
    }

    sortUp(item: IPurchaseOrderLine): void {
        const index = this.currentPurchaseOrder?.purchaseOrderLines?.indexOf(item);
        if (index !== undefined && index > -1 && this.currentPurchaseOrder && this.currentPurchaseOrder.purchaseOrderLines) {
            this.currentPurchaseOrder?.purchaseOrderLines?.splice(index - 1, 0, this.currentPurchaseOrder?.purchaseOrderLines.splice(index, 1)[0]);
        }
    }

    updateLinePositionNumber(): void {
        if (this.currentPurchaseOrder && this.currentPurchaseOrder.purchaseOrderLines && this.currentPurchaseOrder.purchaseOrderLines !== undefined) {
            this.currentPurchaseOrder.purchaseOrderLines?.map((x: IPurchaseOrderLine, index: number) => {
                x.positionNumber = index + 1;
            });
        }
    }
}
