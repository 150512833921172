
































































































































import { IInvoice } from '@/entity/invoice/invoice';
import { ICreateInvoice } from '@/entity/invoice/create-invoice';
import { IBootstrapTableColumn } from '@/entity/shared/bootstrap';
import { invoiceApi } from '@/wapi/invoice-api';
import InputElement from '@c/shared/input-element.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BTable } from 'bootstrap-vue';
import { vxm } from '@/store';
import { ISelectListOption } from '@/entity/shared/select-list-option';
import { NU } from '@t/type';
import { appTokenMgr } from '@t/employee-app-role';
import frenchLocale from 'date-fns/locale/fr';
import { ICancellableResult } from '@t/ajax-wrapper';
import { format } from 'date-fns';
import id from 'date-fns/esm/locale/id/index.js';

@Component({
    components: {
        InputElement
    }
})
export default class InvoiceList extends Vue {
    @Prop({ required: true }) projectId!: string;
    @Prop({ required: true }) invoiceType!: string;

    private isContractInvoice: boolean = this.invoiceType === 'contract';
    private isPurchaseOrderInvoice: boolean = this.invoiceType === 'purchase-order';
    private createInvoiceObject: ICreateInvoice = {} as ICreateInvoice;
    private filteredInvoices: NU<IInvoice[]> = [];
    private filterData: string = '';
    private mySortBy: string = '';
    private mySortDesc: boolean = false;
    private invoicesCurrentPage: number = 1;
    private invoicesPerPage: number = 10;
    private promiseExport: boolean = false;
    
    get isReadOnly(): boolean {
        return !appTokenMgr.isAdmin() || (vxm.project.projectData?.isSale ?? false);
    }

    get invoices(): IInvoice[] | undefined | null {
        const list = this.isContractInvoice ? vxm.project.invoicesList?.filter((x) => x.contractId !== null) : vxm.project.invoicesList?.filter((x) => x.purchaseOrderId !== null);
        this.filteredInvoices = list;
        return list;
    }

    get totalInvoices(): number {
        if (this.filteredInvoices && this.filteredInvoices.length > 0) {
            return this.filteredInvoices.length;
        }
        return 0;
    }

    get sumTotalExcTax(): string {
        const currentInvoices = this.filteredInvoices?.slice((this.invoicesCurrentPage - 1) * 10, this.invoicesCurrentPage * 10);
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(Number((currentInvoices?.map((x: IInvoice) => x.totalExcTax).reduce((x: number, y: number) => x + y, 0) ?? 0).toFixed(2)));
    }

    get sumTotal(): number {
        const currentInvoices = this.filteredInvoices?.slice((this.invoicesCurrentPage - 1) * 10, this.invoicesCurrentPage * 10);
        return Number((currentInvoices?.map((x: IInvoice) => x.total).reduce((x: number, y: number) => x + y, 0) ?? 0).toFixed(2));
    }

    get sumTotalFormatter(): string {
        return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.sumTotal);
    }

    get sumPayrollAmount(): string {
        const currentInvoices = this.filteredInvoices?.slice((this.invoicesCurrentPage - 1) * 10, this.invoicesCurrentPage * 10);
        const amount = Number((currentInvoices?.map((x: IInvoice) => (x.isPayed ? x.total : 0)).reduce((x: number, y: number) => x + y, 0) ?? 0).toFixed(2));
        const percentage = Number((amount > 0 && this.sumTotal > 0 ? (amount / this.sumTotal) * 100 : 0).toFixed(2));
        return `${new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount)} (${new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(percentage / 100)})`;
    }

    get contracts(): ISelectListOption[] | undefined | null {
        var result = vxm.project.contractsList?.map((x) => {
            const contractDesignation: ISelectListOption = {
                id: x.id,
                code: x.reference,
                label: x.reference + (x.description === '' || x.description === null ? '' : ' - ' + x.description)
            };
            return contractDesignation;
        });
        result?.sort((a, b) => a.code?.localeCompare(b.code, 'fr', { ignorePunctuation: true }) || 0);
        return result;
    }

    get purchaseOrders(): ISelectListOption[] | undefined | null {
        const result = vxm.project.purchaseOrderList?.map((x) => {
            const contractDesignation: ISelectListOption = {
                id: x.id,
                code: x.reference,
                label: x.reference + (x.description === '' || x.description === null ? '' : ' - ' + x.description)
            };
            return contractDesignation;
        });

        result?.sort((a, b) => a.code?.localeCompare(b.code, 'fr', { ignorePunctuation: true }) || 0);
        return result;
    }

    private isBusy: boolean = true;

    private editInvoice(item: IInvoice[], _index: number, _event: Event): void {
        this.$emit('selectedInvoice', item[0]);
    }

    private baseFields: IBootstrapTableColumn[] = [
        { label: `Réf ${this.isContractInvoice ? 'contrat' : 'bon de commande'}`, key: `${this.isContractInvoice ? 'contract.reference' : 'purchaseOrder.reference'}`, sortable: true },
        { label: "Date d'émission", key: 'date', sortable: true, formatter: (val: Date, key?: string, item?) => new Date(val).toLocaleDateString('fr-FR') },
        { label: 'Réf facture', key: 'reference', sortable: true },
        { label: 'Facturé HT', key: 'totalExcTax', sortable: false, formatter: (val: number, key?: string, item?) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val), tdClass: 'moneyCell' },
        { label: 'Facturé TTC', key: 'total', sortable: false, formatter: (val: number, key?: string, item?) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(val), tdClass: 'moneyCell' },
        { label: 'Réglé', key: 'isPayed', sortable: true, formatter: (val: boolean, key?: string, item?) => (val ? 'Oui' : 'Non') },
        { label: 'Montant payé', key: 'payrollAmount', sortable: false, formatter: (val: string, key?: string, item?) => (item.isPayed ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(item.total) : new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(0)), tdClass: 'moneyCell' },
        { label: '', key: 'delete', sortable: false }
    ];

    private async mounted(): Promise<void> {
        this.getInvoiceList();
        this.isBusy = false;
    }

    private async getInvoiceList(): Promise<void> {
        await vxm.project.fetchInvoices(this.projectId);
    }

    private async updateInvoiceList(): Promise<void> {
        await vxm.project.updateInvoices(this.projectId);
    }

    public showModal(): void {
        this.createInvoiceObject = {} as ICreateInvoice;
        if (this.invoices && this.invoices.length > 0 && this.createInvoiceObject) {
            const object = this.invoices.find(x => x.invoiceObject && x.invoiceObject !== '')?.invoiceObject;
            this.createInvoiceObject.invoiceObject = object;
        }
        (this.$refs['create-invoice-modal'] as any).show();
    }

    public async createInvoice(): Promise<void> {
        let invoiceNumber = '001';
        if (this.createInvoiceObject && this.isContractInvoice) {
            const contracts = await vxm.project.contractsList;
            const clientTerme = contracts?.find((x) => x.id === this.createInvoiceObject.contractId)?.terme ?? 60;
            const date = new Date();
            date.setDate(date.getDate() + clientTerme);
            this.createInvoiceObject.deadline = date;
            const invoices = this.invoices?.filter((x) => x.contractId === this.createInvoiceObject.contractId) ?? [];
            if (invoices && invoices.length > 0) {
                const lastInvoice = invoices.reduce((prev, current) => {
                    if (+current.id > +prev.id) {
                        return current;
                    } else {
                        return prev;
                    }
                });
                // const lastInvoice = invoices[(invoices?.length ?? 0) - 1];
                if (lastInvoice !== undefined) {
                    invoiceNumber = '00' + (Number(lastInvoice.reference?.substr(lastInvoice.reference.length - 3)) + 1);
                }
            }
        } else if (this.createInvoiceObject && this.isPurchaseOrderInvoice) {
            const invoices = this.invoices?.filter((x) => x.purchaseOrderId === this.createInvoiceObject.purchaseOrderId) ?? [];
            const lastInvoice = invoices[(invoices?.length ?? 0) - 1];
            if (lastInvoice !== undefined) {
                invoiceNumber = '00' + (Number(lastInvoice.reference?.substr(lastInvoice.reference.length - 3)) + 1);
            }
        }
        this.createInvoiceObject.reference = `${invoiceNumber.substr(invoiceNumber.length - 3, invoiceNumber.length)}`;
        const result = await invoiceApi.createInvoice(this.createInvoiceObject);
        if (result.cancelled === false && !result.error && result.datas) {
            (this.$refs['create-invoice-modal'] as any).hide();
            this.updateInvoiceList().then((x) => {
                this.invoicesCurrentPage = 1;
                (this.$refs['invoice-table'] as BTable).selectRow(0);
            });
        }
    }

    async deleteInvoice(item: IInvoice): Promise<void> {
        const result = await invoiceApi.delete(item.id + '');
        if (result.cancelled === false && !result.error && result.datas) {
            this.$bvToast.toast('Suppression effectué avec succès', {
                title: `Facture: ${item.reference}`,
                variant: 'success',
                solid: true
            });
            this.getInvoiceList();
        }
    }

    get fields(): Array<IBootstrapTableColumn | string> {
        return this.baseFields;
    }

    async exportListInvoice(): Promise<void> {
        const sortKeySelected = this.mySortBy;
        const sortKeyDescSelected = this.mySortDesc;
        const listInvoices = this.filteredInvoices!.map((x) => x.id);
        await this.generateListInvoices(
            invoiceApi.exportInvoiceData(listInvoices as any, sortKeySelected as string, sortKeyDescSelected as any),
            this.isContractInvoice ? `Liste_Facture_client${this.formatDate(new Date())}.xlsx` : `Liste_Facture_fournisseur${this.formatDate(new Date())}.xlsx`
        );
    }

    private formatDate(date: Date): string {
        return format(new Date(String(date)), 'yyyy-MM-dd-HH:mm:ss', { locale: frenchLocale }) ?? '';
    }

    private async generateListInvoices(request: Promise<ICancellableResult<string>>, reportName: string): Promise<void> {
        this.promiseExport = true;
        const response = await request;
        if (response && response.datas) {
            const blob = new Blob([response.datas], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = reportName;
            link.click();
            this.promiseExport = false;
        }
        // We wait for the file to download before removing loading state
        setTimeout(() => {
            this.promiseExport = false;
        }, 1000);
    }

    private onFiltered(filteredItems: IInvoice[]) {
        this.filteredInvoices = filteredItems;
        this.invoicesCurrentPage = 1;
    }

    private strcmp(a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
    }

    private myCompare(itemA: any, itemB: any, key: string) {
        var a, b;
        if (key === 'reference') {
            a = itemA[key];
            b = itemB[key];
            if (a === null) a = '';
            if (b === null) b = '';
            return this.strcmp(a.replaceAll('_', '-').toLowerCase(), b.replaceAll('_', '-').toLowerCase());
        }
    }
}
