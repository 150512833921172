






































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IProject } from '@/entity/project/project';
import CommentList from '@c/comment/comment-list.vue';
import ChartBase from '@/components/shared/charts/chart-base.vue';
import { IContractLine } from '@/entity/contract/contract-line';
import { contractApi } from '@/wapi/contract-api';
import { isCallValidAndNotCancelled } from '@t/ajax-wrapper';
import { IManagementPhase } from '@/entity/management/management-phase';
import { managementApi } from '@/wapi/management-api';
import { purchaseOrderApi } from '@/wapi/purchase-order-api';
import { IPurchaseOrder } from '@/entity/purchase-order/purchase-order';
import { IPlanificationPhase } from '@/entity/planification/planification-phase';
import { planificationApi } from '@/wapi/planification-api';
import { IFee } from '@/entity/fee/fee';
import { feeApi } from '@/wapi/fee-api';
import { authModule } from '@t/session';
import { NU } from '@t/type';
import { projectApi } from '@/wapi/project-api';
import { isBefore } from 'date-fns';
import ColorStyles from '@/style/color.scss';

@Component({
    components: {
        CommentList,
        ChartBase
    }
})
export default class Dashboard extends Vue {
    @Prop({ required: true })
    private project!: IProject;

    private userId!: string;

    private contractLines: IContractLine[] = [];
    private managementPhases: IManagementPhase[] = [];
    private purchaseOrderList: IPurchaseOrder[] = [];
    private planificationPhases: IPlanificationPhase[] = [];
    private fees: IFee[] = [];
    private advancement: number = 0;
    private phaseAdvancement: IManagementPhase[] = [];

    private loading: boolean = false;
    // valeur total de taille de l'élément en width.
    private psize: number = -1;

    private mounted() {
        this.userId = authModule.getAccount()!.localAccountId!;
        this.updateProject(this.project);
        this.psize = (this.$refs.pgrid as HTMLElement).clientWidth;
    }

    get progressSize(): number {
        // calcul la taille du progress bar en retirant le padding,
        // en rajoutant un block de link car il n'y aura pas 10 link mais 9
        // en divisant par le nombre max d'élement et en enlevant
        // la taille du link entre 2 élement
        return (this.psize - 20) / 10 - 20 + 1;
    }

    get linkPositionTop(): string {
        // reprend la taille d'un élément calculé (width === height)
        // divise par 2 pour avoir la position centrale
        // retire la moitié de la taille du link (20 px / 2)
        return this.progressSize / 2 - 10 + 'px';
    }

    get phaseBlockSize(): string {
        return this.phaseAdvancement.length * this.progressSize + 20 * (this.phaseAdvancement.length - 1) + 'px';
    }

    get columnsCut(): string {
        if (this.psize === -1) return '100%';
        let val: string = '';
        for (let i: number = 0; i < this.phaseAdvancement.length; ++i) {
            if (i > 0) {
                val += ' ';
            }
            val += this.progressSize + 'px';
            if (i < this.phaseAdvancement.length - 1) {
                val += ' ' + '20px';
            }
        }
        return val;
    }

    @Watch('project')
    private async updateProject(newVal: IProject) {
        this.loading = true;
        await this.fetchContractLines(newVal.id);
        await this.fetchManagementPhases(newVal.id);
        await this.fetchPruchaseOrderList(newVal.id);
        await this.fetchPlanificationPhases(newVal.id);
        await this.fetchProjectFees(newVal.id);
        await this.fetchAdvancement(newVal.id);
        await this.fetchAdvancements(newVal.id);
        this.calculateTotalProgressAmount();
        this.calculateTotalBudget();
        this.calculateTotalContractLinesPrice();
        this.loading = false;
    }

    private redirect(path: string) {
        this.$router.push({
            path
        });
    }

    private changeColor(mp: IManagementPhase) {
        const av: IContractLine = mp.contractLine;
        switch (av.advancement) {
            case 100:
                return 'green';
            default: {
                if (mp.planificationPhase.closingDate) {
                    if (isBefore(new Date(), new Date(mp.planificationPhase.closingDate))) {
                        if (av.advancement === 0) {
                            return 'grey';
                        }
                        return ColorStyles.main;
                    } else {
                        return 'red';
                    }
                } else {
                    if (av.advancement === 0) {
                        return 'grey';
                    }
                    return ColorStyles.main;
                }
            }
        }
    }

    private async fetchAdvancements(projectId: number) {
        const advancementRequest = await managementApi.getAllPhasesByProjectId(projectId);
        if (isCallValidAndNotCancelled(advancementRequest)) {
            // const allPhase: IManagementPhase[] = [];
            // advancementRequest.datas!.forEach((x) => allPhase.push(...(x.contractLines as IContractLine[])));
            this.phaseAdvancement = (advancementRequest.datas as IManagementPhase[]).sort(
                (x: IManagementPhase, y: IManagementPhase) => {
                    if (x.planificationPhase.openingDate != null && y.planificationPhase.openingDate) {
                        return isBefore(new Date(x.planificationPhase.openingDate), new Date(y.planificationPhase.openingDate)) ? -1 : 1;
                    } else if (y.planificationPhase.openingDate) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
            );
        }
    }

    private async fetchAdvancement(projectId: number) {
        this.advancement = 0;
        const advancementRequest = await projectApi.getAdvancement(projectId + '');
        if (isCallValidAndNotCancelled(advancementRequest)) {
            this.advancement = advancementRequest.datas!;
        }
    }

    private async fetchPlanificationPhases(projectId: number) {
        this.planificationPhases = [];
        const result = await planificationApi.getAllPhasesByProjectId(projectId);
        if (isCallValidAndNotCancelled(result)) {
            this.planificationPhases = result.datas!;
        }
    }

    private async fetchContractLines(projectId: number) {
        this.contractLines = [];
        const result = await contractApi.getAllContractLinesByProjectId(projectId + '');
        if (isCallValidAndNotCancelled(result)) {
            this.contractLines = result.datas!;
        }
    }

    private async fetchProjectFees(projectId: number) {
        this.fees = [];
        const result = await feeApi.getAllByProjectId(projectId + '', this.userId);
        if (isCallValidAndNotCancelled(result)) {
            this.fees = result.datas!;
        }
    }

    private async fetchManagementPhases(projectId: number) {
        this.managementPhases = [];
        const result = await managementApi.getAllPhasesByProjectId(projectId);
        if (isCallValidAndNotCancelled(result)) {
            this.managementPhases = result.datas!;
        }
    }

    private async fetchPruchaseOrderList(projectId: number) {
        this.purchaseOrderList = [];
        const result = await purchaseOrderApi.getAllByProjectId(projectId + '');
        if (isCallValidAndNotCancelled(result)) {
            this.purchaseOrderList = result.datas!;
        }
    }

    private progressAmount(management: IManagementPhase): number {
        if (management && management.contractLine) {
            if (management.contractLine.advancement === 0) return 0;
            if (management.contractLine.advancement === 100) {
                return this.getBudget(management) - management.consumedRatesValue;
            }
            return (
                this.getBudget(management) * (management.contractLine.advancement / 100) - management.consumedRatesValue
            );
        }
        return 0;
    }

    private getBudget(management: IManagementPhase): number {
        if (management && management.contractLine) {
            return (
                (management.regroupingTotal - management.provisionalFeesValue - management.subContractsValue) *
                ((100 - management.contractLine.marginPercentage) / 100)
            );
        }
        return 0;
    }

    get getRowCount(): number {
        return 3;
    }

    get getBackgroundColor(): string {
        return ColorStyles['background-grey-alpha-light'];
    }

    get colorStyles(): unknown {
        return ColorStyles;
    }

    get totalFees(): number {
        if (this.fees.length > 0) {
            return this.fees.map((x) => x.fees).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get totalPlanifiedResult(): number {
        if (this.planificationPhases.length > 0) {
            return this.planificationPhases
                .map((phase) => {
                    if (phase.planificationPhaseProfiles.length > 0) {
                        return phase.planificationPhaseProfiles
                            .map((profile) => profile.totalRate)
                            .reduce((x, y) => x + y);
                    } else {
                        return 0;
                    }
                })
                .reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get totalImputationValue(): number {
        if (this.managementPhases.length > 0) {
            return this.managementPhases.map((x) => x.consumedRatesValue).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get totalProvisionnalFee(): number {
        if (this.managementPhases.length > 0) {
            return this.managementPhases.map((x) => x.provisionalFeesValue).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get totalPurchaseOrders(): number {
        if (this.purchaseOrderList.length > 0) {
            return this.purchaseOrderList.map((x) => x.totalExcTax).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get totalSubContract(): number {
        if (this.managementPhases.length > 0) {
            return this.managementPhases.map((x) => x.subContractsValue).reduce((x, y) => x + y);
        } else {
            return 0;
        }
    }

    get advancementFormatter(): string {
        if (this.advancement) {
            return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(
                this.advancement / 100
            );
        }
        return new Intl.NumberFormat('fr-FR', { style: 'percent', minimumFractionDigits: 2 }).format(0 / 100);
    }

    updateTotalProgressAmount(value: NU<number> = null): void {
        this.$emit('total-progress-amount-changed', value);
    }

    updateTotalBudget(value: NU<number> = null): void {
        this.$emit('total-budget-changed', value);
    }

    updateTotalContractLinesPrice(value: NU<number> = null): void {
        this.$emit('total-contract-lines-price-changed', value);
    }

    calculateTotalProgressAmount(): void {
        if (this.managementPhases.length > 0) {
            this.updateTotalProgressAmount(
                this.managementPhases.map((x) => this.progressAmount(x)).reduce((x, y) => x + y)
            );
        } else {
            this.updateTotalProgressAmount(0);
        }
    }

    calculateTotalBudget(): void {
        if (this.managementPhases.length > 0) {
            this.updateTotalBudget(this.managementPhases.map((x) => this.getBudget(x)).reduce((x, y) => x + y));
        } else {
            this.updateTotalBudget(0);
        }
    }

    calculateTotalContractLinesPrice(): void {
        if (this.contractLines.length > 0) {
            this.updateTotalContractLinesPrice(
                this.contractLines.map((x) => x.priceExcTax).reduce((prev, next) => prev + next)
            );
        } else {
            this.updateTotalContractLinesPrice(0);
        }
    }
}
