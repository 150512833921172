







import { Component, Prop, Vue } from 'vue-property-decorator';
import PurchaseOrderList from '@c/purchase-order/purchase-order-list.vue';
import PurchaseOrderInformation from '@c/purchase-order/purchase-order-information.vue';
import { IPurchaseOrder } from '@/entity/purchase-order/purchase-order';

@Component({
    components: {
        PurchaseOrderList,
        PurchaseOrderInformation
    }
})
export default class PurchaseOrderBase extends Vue {
    @Prop({ required: true }) projectId!: number;

    selectedPurchaseOrderId: number = 0;

    public setPurchaseOrder(item: IPurchaseOrder): void {
        if (item) this.selectedPurchaseOrderId = item.id;
        else this.selectedPurchaseOrderId = 0;
    }
}
