import { ajaxCall, ICancellableResult } from '@t/ajax-wrapper';
import { NU } from '@t/type';

export interface IParametersList {
    projectsIds: NU<number[]>,
    agenciesIds: NU<number[]>
}

class ReportingApi {
    url: string = 'api/reports';

    public async downloadCutoff(
        openingDate: string,
        closingDate: string,
        agencyId: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['Cutoff'],
            queryParameter: { startDate: openingDate, endDate: closingDate, agencyId },
            responseType: 'blob'
        });
    }

    public async downloadDataSurferAccounting(
        openingDate: string,
        closingDate: string,
        parametersLists: IParametersList,
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, IParametersList>('post', this.url, {
            urlParameter: ['DataSurferAccounting'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob',
            payload: parametersLists

        });
    }

    public async downloadDataSurferAccountingPdf(
        openingDate: string,
        closingDate: string,
        parametersLists: IParametersList,
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, IParametersList>('post', this.url, {
            urlParameter: ['DataSurferAccountingPdf'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob',
            payload: parametersLists
        });
    }

    public async downloadDataSurferAccountingForInvoices(
        openingDate: string,
        closingDate: string,
        agencyId: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['DataSurferAccountingForInvoices'],
            queryParameter: { startDate: openingDate, endDate: closingDate, agencyId },
            responseType: 'blob'
        });
    }

    public async downloadDataSurferAccountingForInvoicesPdf(
        openingDate: string,
        closingDate: string,
        agencyId: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['DataSurferAccountingForInvoicesPdf'],
            queryParameter: { startDate: openingDate, endDate: closingDate, agencyId },
            responseType: 'blob'
        });
    }

    public async downloadDataSurferAdvancement(
        openingDate: string,
        closingDate: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['DataSurferAdvancement'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob'
        });
    }

    public async downloadDataSurferAdvancementPdf(
        openingDate: string,
        closingDate: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['DataSurferAdvancementPdf'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob'
        });
    }

    public async downloadReport(
        openingDate: string,
        closingDate: string,
        agencyId: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['InvoicingDelta'],
            queryParameter: { startDate: openingDate, endDate: closingDate, agencyId },
            responseType: 'blob'
        });
    }

    public async downloadDSMouvementsReport(
        openingDate: string,
        closingDate: string,
        agencyId: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['DataSurferMouvement'],
            queryParameter: { startDate: openingDate, endDate: closingDate, agencyId },
            responseType: 'blob'
        });
    }

    public async downloadDSMouvementsReportPdf(
        openingDate: string,
        closingDate: string,
        agencyId: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['DataSurferMouvementPdf'],
            queryParameter: { startDate: openingDate, endDate: closingDate, agencyId },
            responseType: 'blob'
        });
    }

    public async downloadDSPurchaseOrderPdf(
        openingDate: string,
        closingDate: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['POlistPdf'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob'
        });
    }

    public async downloadDSPurchaseOrder(
        openingDate: string,
        closingDate: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['POlist'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob'
        });
    }

    public async downloadDSPurchaseOrderDetails(
        openingDate: string,
        closingDate: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['POlistDetail'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob'
        });
    }

    public async downloadDSPurchaseOrderDetailsPdf(
        openingDate: string,
        closingDate: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['POlistDetailPdf'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob'
        });
    }

    public async downloadDeviationReport(
        openingDate: string,
        closingDate: string,
        agencyId: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['Deviations'],
            queryParameter: { startDate: openingDate, endDate: closingDate, agencyId },
            responseType: 'blob'
        });
    }

    public async downloadInsuranceReport(
        openingDate: string,
        closingDate: string,
        agencyId: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['Insurance'],
            queryParameter: { startDate: openingDate, endDate: closingDate, agencyId },
            responseType: 'blob'
        });
    }

    public async downloadValuedLoadPerProfileReport(
        openingDate: string,
        closingDate: string,
        studioIds: number[]
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, number[]>('post', this.url, {
            urlParameter: ['ValuedLoadPerProfile'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob',
            payload: studioIds
        });
    }

    public async downloadValuedLoadPerProfileReportPdf(
        openingDate: string,
        closingDate: string,
        studioIds: number[]
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string, number[]>('post', this.url, {
            urlParameter: ['ValuedLoadPerProfilePdf'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob',
            payload: studioIds
        });
    }

    public async downloadValidatedLeavesReport(
        openingDate: string,
        closingDate: string
    ): Promise<ICancellableResult<string>> {
        return await ajaxCall.baseAjaxCall<string>('get', this.url, {
            urlParameter: ['ValidatedLeaves'],
            queryParameter: { startDate: openingDate, endDate: closingDate },
            responseType: 'blob'
        });
    }
}

export const reportingApi = new ReportingApi();
